/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

/* @import '~ngx-bar-rating/themes/br-default-theme'; */

/* @import '~../node_modules/ngx-bar-rating/themes/br-default-theme'; */

/*-------------globals----------*/

/*generic globals*/
html,
body {
  min-height: 100vh;
}

/* * {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #15a3ac;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
} */

body {
  background: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}

/* .ngbTooltip .tooltip-inner {
	background-color: #F39237 !important;
	color: #fff !important;
}

.ngbTooltip.bs-tooltip-top .tooltip-arrow::before {
	background-color: #F39237 !important;
	border-top-color: #F39237 !important;
	color: red !important;
	border-bottom-color: #F39237 !important;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", sans-serif;
  color: #031029;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  font-weight: 700;
}

h3 {
  font-size: 30px;
  font-weight: 600;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
  font-weight: 700;
}

h6 {
  font-size: 18px;
  font-weight: 600;
}

p {
  color: #002642cc;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  font-weight: 400;
}

p.lgP {
  font-size: 16px;
  line-height: 24px;
}

p.xlP {
  font-size: 18px;
  line-height: 25.2px;
}

.cursor-pointer {
  cursor: pointer;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}

.flex {
  display: flex;
}

.gap-5 {
  gap: 20px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: end !important;
}

.row-reverse {
  flex-direction: row-reverse;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 12px;
}

.gap-4 {
  gap: 16px;
}

.gap-5 {
  gap: 20px;
}

.justify-center {
  justify-content: center;
}

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

a.button {
  display: inline-block;
}

a.button:hover {
  color: #fff;
  opacity: 0.8;
}

button:hover,
.btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

input:focus {
  outline: none;
}

.wrapper {
  /*margin:0 113px;*/
  padding: 0 15px;
  max-width: 1140px;
  margin: 0 auto;
}

.large-wrapper {
  margin: 0 35px;
  padding: 0 15px;
}

p.suggestP {
  color: #03102999;
  margin-bottom: 0rem;
  line-height: 18px;
}

p.suggestP span {
  color: #15a3ac;
}

.errorInputField:not([type="checkbox"]) {
  border-color: red;
}

p.errorP {
  color: red !important;
  line-height: 26px;
  margin-bottom: 0;
}

.transTable {
  width: 100%;
  overflow-x: auto;
}

.badge {
  font-size: 10px !important;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.done-badge {
  background-color: rgba(0, 144, 0, 0.2);
  color: rgba(0, 144, 0, 0.6);
  border-color: rgb(0, 144, 0, 0.6);
}

.pending-badge {
  background-color: rgba(0, 150, 255, 0.2);
  color: rgba(0, 150, 255, 0.6);
  border-color: rgba(0, 150, 255, 0.6);
}

.cancelled-badge {
  background-color: rgba(151, 28, 1, 0.2);
  color: rgba(151, 28, 1, 0.6);
  border-color: rgba(151, 28, 1, 0.6);
}

.form-control {
  font-weight: normal;
  padding: 19px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #031029;
  font-size: 14px;
  height: 56px;
}

.form-control.icon-from-control {
  padding-left: 56px;
}

input::placeholder,
.form-control::placeholder {
  color: #03102980;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  white-space: pre;
}

textarea.form-control {
  resize: vertical;
  height: 180px;
}

.mb {
  margin-bottom: 30px;
}

label {
  font-weight: normal;
  font-size: 14px;
  color: #031029;
  margin-bottom: 7px;
}

.uploadContTabs .nav-pills {
  display: flex;
  flex-wrap: wrap !important;
}

label.lgLabel {
  font-weight: normal;
  font-size: 14px;
  /* color: #031029; */

  color: #00264299;
  margin-bottom: 0;
  display: inline-block;
}
.signPageForm label.lgLabel {
  color: #031029;
}
.left .addAdvisorTab .label.lgLabel {
  display: inline-flex;
}

label.xlLabel {
  font-weight: 600;
  font-size: 18px;
  color: #002642;
  margin-bottom: 17px;
  display: block;
}

.read-more-anno {
  background-color: transparent;
  color: #002642;
  border: none;
  font-size: 14px;
  font-weight: 600;
}

.textLabel span {
  opacity: 0.5;
  display: block;
}
.selectCompLabel {
  font-weight: 600;
}
.selectCompLabel span {
  font-weight: 400;
  opacity: 1;
}

.form-control-select {
  font-weight: normal;
  padding: 19px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #031029;
  font-size: 14px;
  height: 56px;
  color: #031029;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control-select.icon-form-control-select {
  padding-left: 56px;
}

.dropdown-toggle::after {
  color: #15a3ac;
}

input[type="time"].form-control {
  padding-right: 11px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(58%) sepia(55%) saturate(2657%) hue-rotate(142deg)
    brightness(85%) contrast(83%);
}

.form-control:focus {
  border-color: #15a3ac;
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.1);
  border-radius: 10px;
  border: none;
}

.dropdown-item {
  font-size: 14px;
  padding: 5px 15px;
}

/*ng select*/
.ngSelect .ng-select-container {
  font-weight: normal;
  padding: 19px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #031029;
  font-size: 14px;
  height: 56px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #ccc;
  box-shadow: none;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  display: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-weight: 400;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #002642;
  padding: 18px 20px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:after {
  content: "";
  transform: translate(-50%, 0);
  position: absolute;
  left: 50%;
  top: 0;
  width: 93%;
  height: 1px;
  background: #cccccc80;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: #8787871a;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  box-shadow: 0px 10px 20px rgb(8 86 77 / 15%);
  border-radius: 10px;
}

.ng-dropdown-panel.ng-select-bottom {
  border-top: 0 !important;
}

/*common classes*/
.halfFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.halfFlex > div {
  width: 48%;
}

p.orP {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  text-align: center;
}

p.orP:before {
  position: absolute;
  left: 0;
  top: 50%;
  width: 193px;
  height: 0.5px;
  background-color: #c1c1c1;
  transform: translate(0, -50%);
  content: "";
}

p.orP:after {
  position: absolute;
  right: 0;
  top: 50%;
  width: 193px;
  height: 0.5px;
  background-color: #c1c1c1;
  transform: translate(0, -50%);
  content: "";
}

.inputWrap {
  position: relative;
  margin-bottom: 20px;
}

.inputWrap .icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 20px;
}
i#togglePassword {
  position: absolute;
  top: 20px;
  right: 20px;
}
.wrapperCard {
  padding: 50px;
  box-shadow: 0px 5px 20px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.pageTitle {
  margin-bottom: 30px;
}
.left .addAdvisorTab .checkboxWrap {
  margin-bottom: 0;
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: center !important;
}
.left .addAdvisorTab .checkboxWrap input {
  display: flex;
  position: relative;
  margin: 0px 12px 0 0 !important;
  width: 14px;
  height: 14px;
}
.checkboxWrap {
  margin-bottom: 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkboxWrap input {
  display: flex;
  position: relative;
  margin: 5px 12px 0 0;
  width: 14px;
  height: 14px;
}

.checkboxWrap input[type="checkbox"]:checked {
  accent-color: #15a3ac;
}

.checkboxWrap input[type="checkbox"]:after {
  content: "";
  background-image: url("./assets/check-empty.svg");
  top: 0;
  left: 0;
  background-size: 14px 14px;
  width: 14px;
  height: 15px;
  display: inline-block;
  background-color: #fff;
  background-repeat: no-repeat;
}

.checkboxWrap input[type="checkbox"]:checked:after {
  background-image: url("./assets/check-filled.svg");
}
/* .pricePkgCard .checkboxWrap input[type="checkbox"]:after {
	content: "";
	background-image: url("./assets/check-emptyNew.svg");
	top: 0;
	left: 0;
	background-size: 14px 14px;
	width: 14px;
	height: 15px;
	display: inline-block;
	background-color: #fff;
	background-repeat: no-repeat;
}

.pricePkgCard .checkboxWrap input[type="checkbox"]:checked:after {
	background-image: url("./assets/check-filledNew.svg");
} */
/* .pricePkgCheckbox */

.pricePkgCheckbox .checkboxWrap input[type="checkbox"]:after {
  content: "";
  background-image: url("./assets/check-empty.svg");
  top: 0;
  left: 0;
  background-size: 14px 14px;
  width: 14px;
  height: 15px;
  display: inline-block;
  background-color: #fff;
  background-repeat: no-repeat;
}

.pricePkgCheckbox .checkboxWrap input[type="checkbox"]:checked:after {
  background-image: url("./assets/check-filled.svg");
}

.breadCrumb {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.breadCrumb button {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  text-decoration: underline;
  color: #15a3ac;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 10px;
}

.breadCrumb button img {
  margin-right: 5px;
}

/* .breadCrumb button img{margin-right:5px;} */
.breadCrumb p {
  font-weight: 600;
  line-height: 23px;
  color: #002642;
  opacity: 0.5;
  margin-bottom: 0;
}

.breadCrumb span {
  color: #002642;
  display: inline-block;
  margin: 0 5px;
}

.breadCrumb a {
  font-size: 14px;
  color: #15a3ac;
  text-decoration: underline;
  font-weight: 600;
}

.breadCrumb h6 {
  margin-bottom: 0;
}

.headerMargin {
  margin-top: 40px;
}

.fullWidth {
  width: 100%;
}

.customRadio {
}

.customRadio label.form-check {
  padding: 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.customRadio input {
  display: flex;
  justify-content: center;
  /* position: relative; */
  /* margin: 3px 12px 0 0; */
  width: 16px;
  height: 16px;
}

.customRadio input[type="radio"]:after {
  content: "";
  background-image: url("./assets/radio-empty.svg");
  top: 0;
  left: 0;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: #fff;
  background-repeat: no-repeat;
}

.customRadio input[type="radio"]:checked:after {
  background-image: url("./assets/radio-checked.svg");
}

.customRadio label.form-check span {
  font-size: 14px;
  color: #002642;
}

/*buttons globals*/
.button {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.heightBtn {
  padding: 18px 30px;
  height: 56px;
}
.advisBackBtn {
  margin-bottom: 50px;
}

.shortBtn {
  max-width: 215px;
  padding: 7px 10px;
  width: 100%;
}

.greenBtn {
  background-color: #15a3ac;
  border: 1px solid #15a3ac;
  color: #fff;
}

.defaultBtn {
  background-color: rgba(21, 163, 172, 0.5);
  border: 1px solid transparent;
  color: #fff;
  cursor: default !important;
}

.whiteBtn {
  border: 1px solid #15a3ac;
  background-color: #fff;
  color: #15a3ac;
}

.transBtn {
  color: #15a3ac;
  background-color: transparent;
  border: none;
  padding: 0;
  position: relative;
  z-index: 9;
}

.button img {
  margin-right: 5px;
}

.deleteBtn {
  background-color: #84003280;
  border: 1px solid #84003280;
  color: #fff;
}

.deleteBtn:hover {
  background-color: #840032;
  border-color: #840032;
}

.favTextBtn {
  font-weight: normal;
  line-height: 23px;
  color: #002642;
  padding-left: 23px;
  position: relative;
}

.favTextBtn:before {
  position: absolute;
  content: "";
  background-image: url("./assets/bookmark-o.svg");
  top: 2px;
  left: 0px;
  background-size: 16px 20px;
  width: 16px;
  height: 20px;
  display: inline-block;
}

.favTextBtn:hover:before,
.favTextBtn:focus:before {
  content: "";
  background-image: url("./assets/bookmark.svg");
}

.transBtn.redBtn {
  color: #840032;
}

.advisorSelcModal .modal-content {
  /* padding: 30px 60px 70px 60px; */
  background: url("./assets/register-modal-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgb(8 86 77 / 10%);
  border-radius: 10px;
  background-color: #fff;
}
.advisorSelcModal .modalWrap h2 {
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}
.advisorSelcModal .content {
  padding: 22px 20px 12px;
  box-shadow: 0px 5px 20px 0px #08564d1a;
  background: #fff;
  height: 89px;
  border-radius: 10px;
}
.advisorSelcModal .content p {
  font-weight: 400 !important;
  font-size: 14px;
  color: #002642;
}
span.quoteAuthor {
  float: right;
  margin-top: 15px;
}
.advisorSelcModal .btn-group {
  display: flex;
  justify-content: center;
}

/*modal globals*/
.modal-content {
  padding: 40px 40px 40px 40px;
  max-height: 575px;
  box-shadow: 0px 20px 40px rgba(3, 91, 88, 0.1);
  border-radius: 10px;
  border: none;
}

.modal-dialog {
  max-width: 580px;
  width: 580px;
  height: auto;
  max-height: initial;
}

.modalWrap {
  position: relative;
}
.modalWrap .content {
  /* overflow: scroll !important; */
}
.signUpModalMain .content {
  overflow-y: scroll;
}
.modalWrap li::marker {
  color: #15a3ac;
  font-size: 23px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: inherit;
}

.modalWrap h2 {
  font-weight: 700;
  margin-bottom: 20px;
}

.modalWrap .btn-group .button {
  margin-left: 20px;
}

.modalWrap .btn-group .button:nth-of-type(1) {
  margin-left: 0;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: unset;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: auto;
}

/*dropdown global*/
.dropdown-menu {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgb(8 86 77 / 15%);
}

/*extra*/
.mat-horizontal-content-container {
  overflow: visible !important;
}

/*-----------loader---------*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffffb8;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader img {
  width: 10%;
}

.loader.hide {
  display: none;
}

/*-------------banners----------*/

/*small banner*/
.smallBanner {
  position: relative;
}

.smallBanner > img {
  width: 100%;
  border-radius: 10px;
}

.smallBanner .content {
  width: 490px;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translate(0, -50%);
}

.smallBanner .content h3 {
  margin-bottom: 20px;
}

.smallBanner .content p {
  margin-bottom: 0;
}

/*-------------auth pages----------*/
.signWrapper {
  background-color: #2c9cb1;
  display: flex;
  min-height: 100vh;
}

.signWrapper .left {
  z-index: 1;
  position: relative;
  padding: 50px 0;
  background-color: #fff;
  /*flex:0 0 763px;*/
  width: 56%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.signWrapper .right {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  background: linear-gradient(34.4deg, #15a3ac 5.4%, #3a97b4 114.34%);
}

.signWrapper .left > img:nth-of-type(1) {
  bottom: 40px;
  position: absolute;
  right: -65px;
}

.signWrapper .left > img:nth-of-type(2) {
  display: none;
}

.signWrapper .right > img {
  width: 147px;
  left: -65px;
  position: absolute;
  top: 10px;
}

.signWrapper .wrapper {
  max-width: 490px;
  margin-left: 128px;
}

.signupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signupHeader .exitBtn {
  display: none;
}

.noAccLog {
  margin-top: 190px;
}

.noAccLog .button {
  font-size: 14px;
  font-weight: 600;
  width: 200px;
  margin-right: 20px;
}

.noAccLog p {
  margin-bottom: 17px;
}

.noAccLog h2 {
  margin-bottom: 20px;
}

.signWrapper .right .exitBtn {
  color: #fff;
  position: absolute;
  top: 40px;
  right: 52px;
  padding: 0;
}

.exitBtn {
  color: #031029;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: transparent;
  border: none;
  opacity: 0.5;
}

.exitBtn:hover {
  opacity: 1;
}

.exitBtn img {
  margin-right: 5px;
}

.signBox .btnWrap button {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
  height: 56px;
  width: 56px;
  border: 1px solid #15a3ac;
  border-radius: 4px;
  position: relative;
}

.signBox .btnWrap button .fa {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signBox .btnWrap button:nth-of-type(1) {
  width: 308px;
  color: #15a3ac;
}

.signBox .btnWrap button:nth-of-type(2) {
  color: #2172b9;
}

.signBox .btnWrap button:nth-of-type(3) {
  color: #1977f3;
  margin-right: 0;
}

.signBox .btnWrap button img {
  margin-right: 5px;
}

.signBox .btnWrap button span {
  display: none;
}

.signBox .btnWrap {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.signWrapper .signBox {
  margin-top: 55px;
  margin-bottom: 140px;
}

.signWrapper .signBox.signBoxOne {
  margin-bottom: 0;
}

.signWrapper .signBox.signBoxTwo {
  margin-top: 0;
}

.signBox h2 {
  margin-bottom: 20px;
}

.signBox {
  max-width: 460px;
}

.forgetP {
  text-align: right;
}

.forgetP a {
  font-weight: 600;
  font-size: 16px;
  color: #031029;
}

.forgetSucImg {
  width: 80px;
  margin-bottom: 20px;
}

.signBox .transBtn {
  margin-left: 32px;
  font-weight: 600;
}

.signSlider {
  margin: 240px 0 0 125px;
  width: 290px;
  position: relative;
}

.signSlider .carousel-item img {
  margin-bottom: 20px;
}

.signSlider .carousel-item h4 {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 600;
}

.signSlider .carousel-item p {
  color: #ffffffb3;
  font-weight: 300;
}

.signSlider .carousel-indicators {
  margin: 0;
  justify-content: flex-start;
  position: relative;
}

.signSlider .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.2;
}

.signSlider .carousel-indicators li.active {
  opacity: 1;
}

.signSlider > img {
  position: absolute;
  top: 94%;
  right: -35%;
}

.signBox p {
}

.signBox p a {
  font-weight: 600;
  font-size: 16px;
  color: #15a3ac;
}

.signupTabs .nav-pills {
  margin-bottom: 30px;
}

.signupTabs .nav-pills li {
  display: inline-flex;
  margin-right: 36px;
  position: relative;
}

.signupTabs .nav-pills li:after {
  content: "\f0da";
  position: absolute;
  top: 50%;
  right: -23px;
  font-family: "fontawesome";
  transform: translate(0, -50%);
  color: #15a3ac;
}

.signupTabs .nav-pills li:nth-last-of-type(1) {
  margin-right: 0;
}

.signupTabs .nav-pills li:nth-last-of-type(1):after {
  display: none;
}

.signupTabs .nav-pills li a {
  font-weight: normal;
  font-size: 12px;
  color: #03102933;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.signupTabs .nav-pills li a.active {
  font-weight: 600;
  font-size: 14px;
  color: #031029;
}

.signupTabs .nav-pills li a .tabNo {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #03102933;
  margin-right: 10px;
  position: relative;
  font-weight: 600;
  font-size: 8px;
  color: #03102933;
}

.signupTabs .nav-pills li a .tabNo span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signupTabs .nav-pills li a.active .tabNo {
  font-size: 12px;
  color: #fff;
  background-color: #15a3ac;
  border: 5px solid #fff;
  box-shadow: 0 0 0px 1px #15a3ac33;
  box-sizing: content-box;
}

.signupTabs {
  margin: 0 0 0 113px;
  padding: 0 160px 0 15px;
}

.signBox input.errorInputField:not([type="checkbox"]) {
  border-color: red;
}

.inputWrap.errorInput .icon {
  top: 37%;
}

.signBox .form-check-input {
  margin-left: 0;
  position: relative;
  margin-right: 10px;
}

.signBox input[type="checkbox"] {
  cursor: pointer;
}

.signBox .checkboxWrap a {
  color: #15a3ac;
  margin-left: 5px;
}

.friendReffer .customRadio label.form-check {
  display: inline-flex;
  margin-right: 30px;
}

.friendReffer .customRadio {
  margin-bottom: 13px;
}

.friendReffer {
  margin-top: 30px;
}

/*.signBox input[type=checkbox]:after{content:" ";background-color:transparent;display:inline-block;visibility:visible;}*/

/*.signBox input[type=checkbox]:checked:after{content:"14a";font-family:'fontawesome';color:#15A3AC;top:-5px;position:absolute;height:15px;width:15px;left:0;}*/
.signBox input[type="checkbox"]:checked:after {
  background-image: url("./assets/check-filled.svg");
}

.signBox input[type="checkbox"]:checked {
  background-color: transparent;
}

.signHeader {
  margin: 40px 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finishWrap {
  text-align: center;
  width: 605px;
  margin: 0 auto;
}

.finishWrap img,
.finishWrap h3,
.finishWrap p {
  margin-bottom: 30px;
}

.finishScreen {
  position: relative;
  min-height: 100vh;
}

.finishScreen .vector {
  display: none;
}

.termsModal .content p {
  margin-bottom: 0px;
}

.termsModal .content {
  padding-right: 35px;
  /* overflow-y: scroll; */
  flex: 1 1 auto;
  margin-bottom: 30px;
  max-height: 295px;
}
.paypalModal.termsModal .content {
  max-height: none;
}

.termsModal {
  display: flex;
  flex-direction: column;
  overflow: unset !important;
}

.termsModal > div:nth-of-type(1),
.termsModal .btn-group {
  flex-shrink: 0;
}

/*signup as advisor*/
.lgSignWrapper {
}

.lgSignWrapper.signWrapper .left {
  flex: 1 1 auto;
  transition: ease 1s all;
}

.lgSignWrapper.signWrapper .right {
  flex: 0 0 160px;
}

.lgSignWrapper.signWrapper .right > img,
.lgSignWrapper .signSlider {
  display: none;
}

/*.lgSignWrapper.signWrapper .signBox .btnWrap{display:none;}*/

/*.lgSignWrapper.signWrapper p.orP.orPone{display:none;}*/
.lgSignWrapper h6 {
  margin-bottom: 27px;
}

.lgSignWrapper label.lgLabel {
  /*font-weight:600;*/
  margin-bottom: 16px;
}

.linkedinBtn {
  padding: 18px 30px;
  color: #fff;
  background: #0077b5;
  border: none;
  width: 100%;
  margin-bottom: 40px;
}

.linkedinBtn img {
  margin-right: 6px;
}

.languageWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.languageWrap .box {
  margin-right: 20px;
}

.languageWrap .box label {
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgb(37 37 92 / 10%);
  border-radius: 10px;
  width: 205px;
  height: 60px;
}

.languageWrap .box input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0;
}

.languageWrap .box label > span {
  padding: 15px 30px;
  border-radius: 10px;
  z-index: 9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #002642;
}

.languageWrap .box > label > img {
  position: absolute;
  top: 10px;
  right: 10px;
}

.languageWrap .box label input + .empty,
.languageWrap .box label input:checked + .empty + .filled {
  display: inline-block;
}

.languageWrap .box label input + .empty + .filled,
.languageWrap .box label input:checked + .empty {
  display: none;
}

.languageWrap .box input:checked + img + img + span {
  border: 1px solid #15a3ac;
}

.languageWrap .box label img.icon {
  width: 23px;
  margin-right: 6px;
  position: relative;
  transform: none;
  top: 0;
  left: 0;
}

#experience .langBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #15a3ac;
  margin-left: 0;
}

#experience .langBtn img {
  width: 10px;
  margin-right: 5px;
}

.langModal {
}

.langModal ul {
  padding: 0;
  margin: 0 0 20px;
  list-style-type: none;
  height: 245px;
  overflow: scroll;
}

.langModal ul li {
  border-bottom: 1px solid #cccccc;
}

.langModal ul li:nth-last-of-type(1) {
  border-bottom: none;
}

.langModal ul li:hover {
  background: #cccccc33;
}

.langModal ul li label {
  padding: 20px;
}

.langModal ul li label img {
  width: 22px;
  margin-right: 10px;
}

.infoBox {
  background: #fafafa;
  border-radius: 4px;
  padding: 30px 22px;
}

.infoBox p {
  margin-bottom: 0;
}

.infoBox p img {
  margin-right: 10px;
}

.smCatDrop {
  display: none;
  margin-bottom: 20px;
}

.categoryDropdown .dropdown-menu {
  width: 80%;
  padding: 20px;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 370px;
  overflow-y: scroll;
}

.categoryDropdown ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.categoryDropdown ul li {
  border-bottom: 1px solid #cccccc;
}

.categoryDropdown ul li:nth-last-of-type(1) {
  border-bottom: none;
}

.categoryDropdown ul li:hover {
  background: #cccccc33;
}

.categoryDropdown ul li label {
  padding: 20px;
}

.tagsFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tagsFlex p {
  background: #ffffff;
  position: relative;
  box-shadow: 0px 3px 5px rgba(8, 86, 77, 0.1);
  border-radius: 65px;
  padding: 10px 40px 10px 20px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tagsFlex p > img {
  width: 22px;
  margin-right: 10px;
}

.tagsFlex p .transBtn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

.inputWrap .addBtn {
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

/*.pricePackageWrap{width:920px;}*/
.stepFourHead h6 {
  margin: 0 0px 5px;
}
.stepFourHead h6::before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #15a3ac;
  display: inline-block;
  margin: 0 5px 0 5px;
  vertical-align: middle;
}
.pWToolTip {
  display: flex;
  column-gap: 5px;
  margin-bottom: 15px;
}
.pWToolTip p {
  margin-bottom: 0;
  line-height: 26px;
}
.priceInnerPkg {
  max-width: 540px;
}

.pricePkgCard {
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 20px;
  margin: 5px 0px 30px;
  /*margin-left:-40px;*/
}

.standardCard.pricePkgCard {
  position: relative;
  padding: 20px;
  background: #2e9cb20d;
  box-shadow: none;
}

.pricePackageWrap h5 {
  margin-bottom: 30px;
  font-weight: 600;
}

.standardCard .xlLabel {
  margin-bottom: 13px;
}

.pricePkgCard .lgLablel {
  font-weight: 500;
}

.priceFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.priceFlex .dropdown {
  width: 245px;
  margin-right: 40px;
}

.priceFlex app-due-time-filter {
  flex: 1 1 auto;
}

.priceFlex .customSlider {
  margin-bottom: 0;
}

/* .pricePkgCheckbox{margin-left:20px;} */
.pricePkgCheckbox p {
  margin-bottom: 10px;
  color: #002642;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pricePkgCheckbox p img {
  margin-right: 8px;
}

.pricePkgCheckbox p span {
  font-size: 12px;
  color: #002642;
  opacity: 0.5;
  margin-left: 5px;
}

.pricePkgCard .infoTooltip {
  margin-left: 5px;
}

/* .pricePkgCard .accordBtn {
	padding: 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
} */
.pricePkgCard .accordBtn {
  padding: 20px;

  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.addPakgBtn {
  position: absolute;
  top: 29px;
  right: 20px;
  width: 150px;
  height: 35px;
  background: #15a3ac;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  justify-content: space-between;
  align-items: center;
  cursor: default !important;
}

.addPakgBtn:hover {
  color: #fff;
}

.pricePkgCard .cardBody {
  padding: 0 20px 20px;
}

.pricePkgCard .accordBtn label {
  display: flex;
  margin-bottom: 0;
  cursor: pointer;
}

.pricePkgCard .accordBtn a p:nth-of-type(2),
.pricePkgCard .accordBtn a.collapsed p:nth-of-type(1) {
  display: inline-block;
}

.pricePkgCard .accordBtn a p:nth-of-type(1),
.pricePkgCard .accordBtn a.collapsed p:nth-of-type(2) {
  display: none;
}

.pricePkgCard .accordBtn p {
  margin-bottom: 0;
  color: #002642;
}

.pricePkgCard .accordBtn p img {
  margin-left: 6px;
}

.blurCard.priceInnerPkg {
  opacity: 0.5;
}

.pricePkgBtnGroup {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.pricePkgBtnGroup .button {
  margin-right: 15px;
}

.doProCard.pricePkgCard {
  padding: 20px;
  position: relative;
}

.standardCard.pricePkgCard > label,
.doProCard.pricePkgCard > label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
  padding: 9px 20px;
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(8, 86, 77, 0.1);
  border-radius: 65px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.doProCard.pricePkgCard .inputWrap,
.doProCard.pricePkgCard .pricePkgCheckbox p {
  margin-bottom: 0;
}

.doProWrap h5 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 16px;
}

/*signup stepper*/
.signupStepper .mat-step-icon,
.signupStepper .mat-stepper-horizontal-line {
  display: none;
}

.signupStepper .mat-step-text-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.signupStepper .tabNo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #03102933;
  /* margin-right: 10px; */
  position: relative;
  font-size: 8px;
  color: #03102933;
  min-width: 20px;
  /* max-width: 20px; */
  max-height: 20px;
}

.signupStepper .tabNo span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signupStepper .mat-step-label-active .tabNo {
  font-size: 12px;
  color: #fff;
  background-color: #15a3ac;
  border: 5px solid #fff;
  box-shadow: 0 0 0px 1px #15a3ac33;
  box-sizing: content-box;
}

.signupStepper .mat-stepper-horizontal-line {
}

.signupStepper .mat-step-header:after {
  content: "\f0da";
  position: absolute;
  top: 50%;
  right: 4px;
  font-family: "fontawesome";
  transform: translate(0, -50%);
  color: #15a3ac;
}

.signupStepper .mat-step-text-label {
  font-size: 12px;
  color: #03102933;
  padding: 10px 5px;
  white-space: break-spaces;
}

.signupStepper .mat-step-label-active .mat-step-text-label {
  font-size: 14px;
  color: #031029;
  font-weight: 600;
}

.signupStepper .mat-step-header:nth-last-of-type(1):after {
  display: none;
}

.signupStepper .mat-horizontal-stepper-header {
  padding: 0 15px 0 0px;
  pointer-events: none;
}

/* .signupStepper label.xlLabel{font-weight:500;} */

/* .signupStepper  label.lgLabel{font-weight:500;} */
.signupStepper label.xlLabel span {
  font-weight: 400;
  font-size: 15px;
}

.signupStepper .mat-horizontal-content-container {
  padding: 0;
}

/*.signupStepper .tabNo{font-size:12px;color:#fff;background-color:#15A3AC;border:5px solid #fff;box-shadow:0 0 0px 1px #15a3ac33;box-sizing:content-box;}
*/
.signupStepper .signBox .transBtn.langBtn {
  margin-left: 0;
  font-size: 14px;
}

.signupStepper .signBox .transBtn {
  margin-left: 32px;
  font-size: 18px;
  font-weight: 500;
}

.signupStepper .button {
  font-weight: 500;
}

.addCatBtn,
.signupStepper .signBox .transBtn.addCatBtn {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translate(0px, -50%);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.addCatBtn:disabled,
.signupStepper .signBox .transBtn.addCatBtn:disabled {
  opacity: 0.5;
}

/* dp upload */
.dpUpload {
  margin-bottom: 30px;
}

.dpUpload .dpWrap {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 10px;
}

.dpUpload .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.dpUpload .dpWrap .dpUploadBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  width: 22px;
  height: 22px;
}

.dpUploadBtn input[type="file"] {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.dpUpload .redBtn,
.signupStepper .signBox .dpUpload .redBtn {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #840032;
  margin-left: 0;
}

/* precious time modal */
.preciousModal {
  text-align: center;
}

.preciousModalWrap .modal-content {
  padding: 100px 90px 100px 60px;
  background: url("./assets/precious-time-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgb(3 91 88 / 10%);
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
}

.preciousModalWrap .modal-dialog {
  max-width: 802px;
  width: 802px;
}

.preciousModal h3 {
  color: #15a3ac;
  margin-bottom: 40px;
}

.preciousModal p:nth-of-type(2) {
  max-width: 415px;
  margin: 0 auto;
}

/*signup confirmation modal */
.preciousModalWrap.signupModalWrap .modal-content {
  padding: 30px 60px 70px 60px;
  background: url("./assets/congrats-signin-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgb(8 86 77 / 10%);
  border-radius: 10px;
  background-color: #fff;
}

.preciousModalWrap.signupModalWrap p {
  font-weight: normal;
  font-size: 24px;
  opacity: 0.8;
  max-width: 518px;
  margin: 0 auto 30px;
  line-height: 30px;
}

.signupModalWrap .preciousModal h3 {
  margin-bottom: 20px;
}

.signupModalWrap .preciousModal > img {
  margin-bottom: 15px;
}

/*signup registration modal */
.preciousModalWrap.signupModalWrap.registerModalWrap .modal-content {
  padding: 30px 60px 70px 60px;
  background: url("./assets/register-modal-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgb(8 86 77 / 10%);
  border-radius: 10px;
  background-color: #fff;
}

/* forgot password page */
.forgetWrapper {
  padding-bottom: 75px;
  min-height: 100vh;
  background: linear-gradient(34.4deg, #15a3ac 5.4%, #3a97b4 114.34%);
}

.forgetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 40px;
}

.forgetHeader .exitBtn {
  color: #fff;
}

.forgetCard {
  /*position: absolute;left: 50%; top:50%; transform: translate(-50%, -50%);*/
  position: relative;
  max-width: 730px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 20px;
  padding: 60px 95px;
  min-height: 490px;
}

.forgetCard .transBtn {
  display: block;
  margin: 20px 0 0 0;
}

.forgetCard .leftVector {
  position: absolute;
  top: 60px;
  left: -114px;
}

.forgetCard .rightVector {
  position: absolute;
  bottom: -40px;
  right: -240px;
}

.forgetWrapper .signupHeader {
  display: none;
}

/*-------------discover advisor page----------*/

/*filterWrap*/
.filterWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterWrap .filtersBtn {
  display: none;
}

.searchWrap {
  min-width: 355px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.searchWrap .form-control {
  font-size: 16px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.searchWrap .button {
  position: relative;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 56px;
}

.searchWrap .button img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filterDrops {
  margin-left: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filterDropBox {
  margin-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filterDropBox > label {
  margin-bottom: 0;
}

.filterDropBox:nth-of-type(1) {
  margin-left: 0;
}

.filterDropBox .dropdown {
  margin-left: 20px;
}

.filterDropBox .dropdown > .button {
  padding: 17px 20px 17px 20px;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-weight: normal;
  font-size: 16px;
}

.filterDropBox .dropdown-toggle::after {
  color: #15a3ac;
}

.filterWrap > div {
  display: flex;
}

/*discover advisor main */
.discoverAdFlexWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;
}

.sidebar {
  width: 255px;
  flex-shrink: 0;
  margin-right: 20px;
}

.discoverAdFlexWrap .dicoverAdFlex,
.disAdWrap {
  flex: 1 1 auto;
}

.disAdWrap > .greenBtn {
  margin-top: 40px;
}

.disAdWrap > h6 {
  margin-bottom: 30px;
}

/* .discoverAdFlexWrap .dicoverAdFlex .discoverAdBox{cursor:pointer;/*padding: 20px 10px 20px;} */
.discoverAdFlexWrap .discoverAdBox .dpWrap {
  width: 50px;
  height: 50px;
  position: relative;
}

.discoverAdFlexWrap .discoverAdBox .dpWrap .badgeIcon {
  width: 20px;
  height: 20px;
}

.discoverAdFlexWrap .discoverAdBoxTop .rating {
  margin-left: 10px;
}

.discoverAdFlexWrap .advisorFigures > div {
  padding-right: 30px;
}

.discoverAdvisorPage {
  margin-bottom: 100px;
}

.discoverAdBox .dpWrap span.online {
  width: 12px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 12px;
  background: #15a3ac;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.discoverAdBox .dpWrap span.online.offline {
  background: #f39237;
}

.discoverAdBox .dpWrap span.online.request {
  background: #840032;
}

input[type="checkbox"].favCheck {
  flex-shrink: 0;
  appearance: none;
  background-image: url("./assets/star-o.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-size: 20px 22px;
}

input[type="checkbox"].favCheck2 {
  background-size: 10px 11px;
  margin-left: 4px;
  width: 10px;
  height: 10px;
}
.br-default .br-unit:after {
  content: "★";
  color: #d2d2d2;
  font-size: 18px;
  line-height: 20px;
}

input[type="checkbox"].favCheck:checked {
  background-image: url("./assets/FillStar.svg");
  background-repeat: no-repeat;
  /* fill: #15a3ac; */
}

/* .discoverAdBoxTop .btn:before{content:"";background-image:url('./assets/bookmark-o.svg');top:0;left:0;background-size:16px 20px;width:16px;height:20px;display:inline-block;}
.discoverAdBoxTop .btn:hover:before{content:"";background-image:url('./assets/bookmark.svg');} */

/*discover advisor boxes*/
.dicoverAdFlex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  /* margin: 0 10px; */
}

.discoverAdBox {
  /* margin: 7px; */
  /* width: 31.6%; */
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
  /* padding: 25px 20px 30px; */
  /* flex: 1 1 0%; */
  padding: 25px 20px 45px;
  position: relative;
}

.uploadWorkSec .discoverAdBox {
  width: 23.7%;
}

.discoverAdBoxTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.discoverAdBoxTop p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #03102980;
  padding: 4px 10px 4px 10px;
  border-radius: 18px;
}

.expLabel {
  background: #f5f6fa;
  border-radius: 4px;
  padding: 4px 10px;
  border: none;
  margin-bottom: 10px;
  display: inline-block;
  margin-left: 13px;
}
.expLabel2 {
  padding: 6px 10px !important;
}

.expLabel img {
  margin-right: 10px;
}

.discoverAdBox .shortBtn {
  margin: 0 auto;
  display: block;
  border-radius: 8px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
}

.discoverAdBoxTop .btn {
  width: 16px;
  height: 20px;
  padding: 0;
  position: relative;
  line-height: 1;
  font-size: inherit;
}

.discoverAdBoxTop .btn:before {
  content: "";
  background-image: url("./assets/bookmark-o.svg");
  top: 0;
  left: 0;
  background-size: 16px 20px;
  width: 16px;
  height: 20px;
  display: inline-block;
}

.discoverAdBoxTop .btn:hover:before {
  content: "";
  background-image: url("./assets/bookmark.svg");
}

.discoverAdBox .dpWrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  /* padding-left: 4px; */
}

.discoverAdBox .dpWrap img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50% !important;
}

.discoverAdBox .dpWrap .badgeIcon {
  position: absolute;
  right: -5px;
  bottom: 0px;
  width: 24px;
  height: 24px;
}

.discoverAdBox h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
}

.discoverAdBox h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 5px;
}

.discoverAdBox h4 img {
  margin-right: 10px;
}

.advisor-selected-image img#advisor-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.rating {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 8px; */
}

.rating span {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  margin-right: 5px;
}

.rating img {
  margin-right: 3px;
}

.discoverAdBox .whiteBtn:hover {
  background-color: #15a3ac;
  color: #fff;
}

.discoverAdBox:hover {
  box-shadow: 0px 20px 50px rgba(8, 86, 77, 0.2);
}

.discoverAdBoxTop > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.discoverAdBoxTop .rating {
  margin-left: 15px;
  margin-bottom: 0;
}

.discoverAdBoxTop .rating span {
  font-size: 12.9px;
  font-weight: 600;
}

.advisorFigures {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.advisorFigures > div {
  position: relative;
  padding-right: 35px;
}

.advisorFigures h6 {
  color: #00274280;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 2px;
}

.advisorFigures h6 span {
  font-weight: 600;
  font-size: 14px;
  color: #002642;
}

.advisorFigures > div:after {
  content: "|";
  color: #949494;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  right: 17px;
}

.advisorFigures > div:nth-last-of-type(1):after {
  display: none;
}

.advisorFigures > div:nth-last-of-type(1) {
  padding-right: 0;
}

.favouriteAdvisor > h6 {
  margin-bottom: 20px;
}

/* .favouriteAdvisor .discoverAdBox {
  width: 23.7%;
} */

.notLoggedModal .btn-group {
  margin-bottom: 30px;
}

.notLoggedModal .transBtn {
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
}

.adOffWrap {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.adOffWrap2 > p {
  padding: 8px 8px !important;
}

.adOffWrap > p {
  border: 1.5px solid #15a3ac;
  margin-bottom: 0;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 10px;
  border-radius: 4px;
  color: #002642;
  line-height: 12px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adOffWrap > p img {
  margin-left: 3px;
}

/* .adviceTooltip{display: flex; justify-content: flex-start; align-items: center;} */
.adviceTooltip h6 {
  font-size: 14px;
  margin-left: -20px;
}

.discoverAdBox .adOffWrap {
  margin: 5px 0 0 15px;
}

/****discover advisor sidebar****/
.sidebarHead {
  display: none;
}

.sidebarBox {
  margin-bottom: 40px;
}

.sidebarBox h6 {
  margin-bottom: 20px;
}

.sidebarBox .checkboxWrap {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebarBox .checkboxWrap:nth-last-of-type(1) {
  margin-bottom: 0;
}

.sidebarBox .checkboxWrap span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-right: 5px;
}

.sidebarBox .checkboxWrap input {
  position: relative;
  margin: 0 12px 0 0;
  width: 14px;
  height: 14px;
}

/*.sidebarBox .checkboxWrap input[type=checkbox]:after{content:"";background-image:url('./assets/check-empty.svg');top:0;left:0;background-size:14px 14px;width:14px;height:15px;display:inline-block;background-color:#fff;background-repeat:no-repeat;}
.sidebarBox .checkboxWrap input[type=checkbox]:checked:after{background-image:url('./assets/check-o.svg');}*/
.sidebarBox .checkboxWrap input[type="checkbox"]:checked + span {
  font-weight: 600;
}

.sidebarBox .pricePkgCheckbox {
  margin-left: 0;
}

/*price slider */
.customSlider {
  margin-bottom: 80px;
}

.priceSlider .ngx-slider-limit {
  top: 10px;
  width: 54px;
  height: 54px;
  border: 1px solid rgb(204, 204, 204);
  padding: 18px 0px !important;
  text-align: center;
  line-height: 1;
  border-radius: 3px;
  opacity: 1 !important;
}

.customSlider .ngx-slider-limit b {
  display: none;
}

/*.customSlider .ngx-slider .ngx-slider-bar{background-color:#d8e0f3;}*/
.customSlider .ngx-slider .ngx-slider-bar {
  height: 6px;
  background: #f4f2f6;
}

.customSlider .ngx-slider .ngx-slider-selection,
.customSlider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: #15a3ac;
}

.customSlider .ngx-slider .ngx-slider-bubble {
  font-size: 14px;
  color: #002642;
}

.customSlider .ngx-slider .ngx-slider-bubble b {
  font-weight: 500;
}

.customSlider .ngx-slider .ngx-slider-bubble b p {
  display: inline-block;
  margin-bottom: 0;
  background: #f5f6fa;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  color: #002642;
  padding: 5px 10px;
}

.customSlider .ngx-slider .ngx-slider-model-value {
  padding-bottom: 5px;
}

.customSlider .ngx-slider .ngx-slider-pointer {
  background: transparent;
  border-top: 10px solid #15a3ac;
  width: 20px;
  height: 8px;
  border-left: 9px solid transparent;
  border-right: 11px solid transparent;
  border-radius: 0;
  top: -15px;
}

.customSlider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.customSlider .ngx-slider .ngx-slider-pointer:focus {
  outline: none;
}

.timeSlider .ngx-slider-limit {
  top: 10px;
  text-align: center;
  opacity: 1 !important;
}

/*.timeSlider .ngx-slider .ngx-slider-selection,.timeSlider .ngx-slider .ngx-slider-tick.ngx-slider-selected{background-color:#d8e0f3;}
.timeSlider .ngx-slider .ngx-slider-bar{background-color:#15A3AC;}*/
.timeSlider .ngx-slider .ngx-slider-bar {
  background: #15a3ac;
}

.timeSlider .ngx-slider .ngx-slider-selection {
  background: #f4f2f6;
}

.timeFilter .infoTooltip {
  display: inline-block;
  margin-left: 5px;
}

.infoTooltip {
  display: inline-block;
}

/*-------------submit resume page----------*/
.uploadFileWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadFileWrap > div:nth-of-type(1) {
  padding: 19px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 56px;
  flex: 1 1 auto;
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.uploadFileWrap > div.errorInputField:nth-of-type(1) {
  border-color: red;
}

.inputFileBtn {
  margin-bottom: 0;
  width: 113px;
  height: 56px;
  flex-shrink: 0;
  position: relative;
}

.inputFileBtn input[type="file"] {
  opacity: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.inputFileBtn .button {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 10;
}

.uploadFileName {
  margin-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.uploadFileName button {
  padding: 0;
  border: none;
  background-color: transparent;
  margin: 0 10px;
}

.inputFileBtn .button:hover {
  cursor: pointer;
}

.uploadFileName p {
  margin-bottom: 0;
  color: #00274280;
}

.submitResumeBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.undoResume {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.undoResume p {
  margin-bottom: 0;
}

.undoResume .exitBtn {
  position: absolute;
  top: 15px;
  right: 15px;
}

.uploadFileGroup {
}

.uploadFileGroup .uploadFileName {
  display: inline-flex;
}

.readyCard {
  margin-top: 40px;
  margin-bottom: 100px;
  box-shadow: 0px 20px 40px rgba(8, 86, 77, 0.0705882);
}

.dropdown-toggle::after {
  margin-left: 6px !important;
}

/*-------------header----------*/

/*header logged out*/
.logo {
  display: inline-block;
}

.logo img {
  width: 100px;
}

.headFlex {
  padding: 11px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headAuth {
  display: inline-flex;
}

.headAuth .transBtn {
  margin-right: 30px;
  align-self: center;
}

ul.mainMenu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ul.mainMenu li {
  display: inline-block;
  margin: 0 25px;
}

ul.mainMenu li a {
  position: relative;
  font-weight: normal;
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  color: #002642cc;
}

ul.mainMenu li a:hover {
  color: #002642;
}

ul.mainMenu li a.active {
  color: #002642;
  font-weight: 600;
}

ul.mainMenu li a.active:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1px;
  transform: translate(-50%, 0);
  width: 85%;
  height: 2px;
  background-color: #15a3ac;
  border-radius: 5px;
}

.subMenu {
  background: #fafafa;
  border-radius: 4px;
  padding: 15px;
}

.subMenu ul {
  padding-left: 0;
}

.headFlex .heightBtn {
  height: 46px;
  padding: 10px 30px;
  line-height: 22px;
}

.header {
  background: #fff;
  /*margin-bottom:40px;*/
  box-shadow: 0px 5px 10px rgb(0 19 20 / 5%);
  /*border-bottom-left-radius:15px;border-bottom-right-radius:15px;*/
}

.mobileMenu {
  display: none;
}

.headFlex a.transBtn:hover {
  color: #15a3ac;
}

.mobileMenu button {
  background: transparent;
  padding: 0;
  border: none;
}

.mobileMenu button:focus {
  outline: none;
  box-shadow: none;
}

.mobileMenu button:hover {
  background: transparent !important;
}

.mobileMenu button:focus {
  outline: none !important;
}

.mobileMenu {
  margin-right: 15px;
}

/*header logged in*/
.headBottom {
  background: #fafafa;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 5px 10px rgba(0, 19, 20, 0.05);
  position: relative;
}

.headLogged .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headLogged .right > .button:nth-of-type(1) {
  margin-right: 30px;
}

.headLogged .right .button:nth-of-type(2) {
  margin-right: 33px;
}

.profileDropdown {
  margin-right: 20px;
}

.profileDropdown .button img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 5px;
}

.profileDropdown .button {
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}

.headCoinsCount {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #002642;
  margin-bottom: 0;
}

.headCoinsCount sup {
  font-size: 10px;
  color: #00264280;
}

.headLogged .notifyCount {
  width: 16px;
  height: 16px;
  background: #15a3ac;
  font-size: 11px;
  top: -5px;
  right: -9px;
}

.headBottom .transBtn img {
  margin-right: 7px;
}

.headBottom .transBtn {
  font-weight: normal;
  font-size: 16px;
}

.profileDropdown .dropdown-menu {
  padding: 20px;
  z-index: 999999;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  top: 100% !important;
  width: 285px;
}

.profileDropdown a.dropdown-item {
  display: inline-block;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  border-bottom: 1px solid #cccccc80;
  color: #002642;
}

.profileDropdown a.dropdown-item img {
  margin-right: 12px;
}

.profileDropdown a.dropdown-item img:nth-of-type(2),
.profileDropdown a.dropdown-item:hover img:nth-of-type(1) {
  display: none;
}

.profileDropdown a.dropdown-item:nth-last-of-type(1) {
  border-bottom: 0;
}

.profileDropdown a.dropdown-item:hover img:nth-of-type(2) {
  display: inline-block;
}

.profileDropdown a.dropdown-item:hover {
  background: #15a3ac;
  color: #fff;
  border-radius: 10px;
}

.profileUserInfo {
  margin-bottom: 30px;
}

.profileDropdown .profileUserInfo p {
  font-size: 16px;
  margin-bottom: 10px;
}

.profileDropdown .profileUserInfo p span {
  color: #15a3ac;
}

.profileUserInfo h6 {
  font-size: 16px;
  line-height: 20px;
  color: #15a3ac;
}

.profileDropdown .profileUserInfo p:nth-of-type(2) span {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.profileDropdown .profileUserInfo p:nth-of-type(3) span {
  font-size: 10px;
  line-height: 12px;
  margin-left: 5px;
  margin-bottom: 0;
}

.profileDropdown .profileUserInfo p .expLabel img {
  width: 13px;
  margin-right: 5px;
}

.langToggleWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.langToggleWrap .box {
  width: 49%;
}

.langToggleWrap .box > p {
  margin-bottom: 0;
  font-size: 12px;
  color: #002642;
}

.langToggleWrap .box label {
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(37, 37, 92, 0.1);
  border-radius: 10px;
  width: 120px;
  height: 45px;
}

.langToggleWrap .box input {
  width: 120px;
  height: 45px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.langToggleWrap .box label > span {
  opacity: 0.3;
  border-radius: 10px;
  z-index: 9;
  width: 120px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #002642;
}

.langToggleWrap .box label img.icon {
  display: inline-flex;
  width: 18px;
  margin-right: 10px;
}

.langToggleWrap .box input:checked + span {
  opacity: 1;
  box-shadow: 0px 20px 46px rgba(8, 86, 77, 0.2);
}

.notifyDropScroll {
  max-height: 300px;
  overflow-y: auto;
}

.notifyDropdown .button img {
  width: 14px;
  height: auto;
  margin-right: 0;
}

.notifyDropdown {
  margin-right: 30px;
}

.notifyDropdown .dropdown-menu {
  width: 315px;
  width: 315px;
  top: 30px !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
}

.notifyDropdown .dropdown-item {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 19, 20, 0.05);
  margin-bottom: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
}

.notifyDropdown .dropdown-item img {
  flex-shrink: 0;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px;
}

.notifyDropdown .dropdown-item h6 {
  margin-bottom: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifyDropdown .dropdown-item h6 span {
  font-size: 10px;
  opacity: 0.5;
}

.notifyDropdown .dropdown-item p {
  font-size: 12px;
  opacity: 0.5;
  line-height: 17px;
}

label.new {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #f39237;
  padding: 1px 6px;
  border: 1px solid #f39237;
  border-radius: 7px;
}

.notifyDropdown label.new {
  margin-left: 10px;
  flex-shrink: 0;
}

.notifyDropdown .dropdown-item > div {
  flex: 1 1 auto;
}

.notifyDropdown .dropdown-item p {
  white-space: break-spaces;
}

.notifyDropdown .dropdown-menu {
  background: #fafafa;
  padding: 20px 0;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
  border-radius: 10px;
}

/* .notifyDropdown .dropdown-item.new label.new {
	color: #fff;
	border: 1px solid #fff;
}

.notifyDropdown .dropdown-item.new {
	background: #F39237;
}

.notifyDropdown .dropdown-item.new h6 {
	color: #fff;
} */

/* .notifyDropdown .dropdown-item.read {
	background: transparent;
}

.notifyDropdown .dropdown-item h6 span {
	color: #002642;
	opacity: 0.5;
}

.notifyDropdown .dropdown-item.new p,
.notifyDropdown .dropdown-item.new h6 span {
	color: #fff;
	opacity: 0.5;
} */

.notifyDropdown .whiteBtn {
  border-radius: 20px;
  width: 200px;
  margin: 0 auto;
  display: block;
  padding: 9px 10px;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}

.notifyDropdown .whiteBtn img {
  margin-right: 7px;
}

.notifyDropdown .whiteBtn:hover {
  background: #15a3ac;
  color: #fff;
}

.notifyDropdown .dropdown-item:nth-last-of-type(1) {
  box-shadow: none;
  position: relative;
}

.notifyDropdown .dropdown-item:nth-last-of-type(1):after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 80%;
  height: 1px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #e0e0e0;
}

.notifyDropdown .dropdown-item:hover {
}

.dropdown-item-wrap {
  max-height: 235px;
  overflow-y: auto;
}

.notifyCount {
  background: #f39237;
  width: 27px;
  height: 27px;
  position: absolute;
  border-radius: 50%;
  top: -16px;
  right: -18px;
  color: #fff;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* header quick response */

/* .quickStatusWrap{margin-right:30px;}
.quickStatusWrap label{position:relative;margin:0;}
.quickStatusWrap label span{padding:7px 15px;font-weight:600;letter-spacing:0.4px;font-size:12px;color:#15A3AC;border:1px solid #EEEEEE;border-radius:0;display:block;}
.quickStatusWrap label input[type="radio"]{position:absolute;width:100%;height:100%;top:0;left:0;opacity:0;}
.quickStatusWrap label span img{margin-left:10px;}
.quickStatusWrap label span img:nth-of-type(2){display:none;}
.quickStatusWrap label:nth-of-type(2) span{border-left:0;border-right:0;}
.quickStatusWrap label:nth-of-type(1) span{border-radius:4px 0px 0px 4px;}
.quickStatusWrap label:nth-of-type(3) span{border-radius:0px 4px 4px 0px;}
.quickStatusWrap label input[type="radio"]:checked+span{color:#FFFFFF;background:#15A3AC;}
.quickStatusWrap label input[type="radio"]:checked+span img:nth-of-type(2){display:inline-block;}
.quickStatusWrap label input[type="radio"]:checked+span img:nth-of-type(1){display:none;} */
.quickFeed {
  margin-right: 30px;
  background: #fafbfb;
  padding: 9px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
}
.quickFeedMob {
  display: none !important;
}
.quickFeed p {
  margin-bottom: 0;
  color: #002642;
}

.quickFeed p span {
  font-weight: 600;
  position: relative;
}

.quickFeed p span img {
  position: absolute;
}

.quickFeed a {
  margin-left: 20px;
}

.quickFeed a img {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(0 19 20 / 5%);
  top: 0;
  right: -7px;
}

/*-------------footer----------*/
.footer {
  background-color: #002642;
}

.footer p,
.footer a {
  color: #fff;
}

.footerFlex {
  padding: 54px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* .footerFlex .middle>div {display:flex;justify-content:space-between;align-items:flex-start;} */
.footerFlex .left {
  width: 230px;
  flex-shrink: 0;
}

.footerFlex .right {
  width: 160px;
  flex-shrink: 0;
}

.footerFlex .middle {
  flex: 1 1 auto;
  padding: 0 25px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.footerFlex h5 {
  color: #15a3ac;
  font-weight: 500;
  margin-bottom: 30px;
}

.footerFlex ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footerFlex ul li a {
  display: inline-block;
}

.footerFlex .logo {
  margin-bottom: 30px;
}

.footerFlex .left .logo + p {
  font-weight: 300;
  margin-bottom: 50px;
  color: #fffc;
}

ul.socialIcons li {
  display: inline-block;
  margin-right: 25px;
}

ul.socialIcons li:nth-last-of-type(1) {
  margin-right: 0;
}

ul.socialIcons li a {
  color: #b2bec6;
  font-size: 22px;
}

ul.socialIcons li a:hover {
  color: #15a3ac;
}

.footerFlex .middle ul li {
  margin-bottom: 20px;
  padding-left: 10px;
  position: relative;
}

.footerFlex .middle ul li:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  content: "";
}

.footerFlex .middle ul li:nth-last-of-type(1) {
  margin-bottom: 0;
}

.footerFlex .middle ul li a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
}

.footerFlex .middle ul li a:hover {
  text-decoration: underline;
}

.footerFlex .middle ul li:before {
  width: 5px;
  height: 2px;
  background-color: #15a3ac;
}

.footerFlex .middle > div:nth-of-type(1) ul li a {
  font-weight: 500;
}

.footerFlex .middle > div:nth-of-type(1) ul li:before {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #15a3ac;
}

.footerFlex .middle > div:nth-of-type(3) {
  margin-top: 58px;
}

.footerFlex .left .fooContact {
  display: none;
}

.fooContact p {
}

.fooContact p a {
  display: inline-block;
}

.fooContact p a:hover {
  text-decoration: underline;
}

.fooContact p a img {
  margin-right: 14px;
}

.footerBottom {
  background-color: #335168;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.footerBottom ul li {
  margin-left: 60px;
  display: inline-block;
}

.footerBottom ul li:nth-of-type(1) {
  margin-left: 0;
}

.footerBottom ul li a {
  font-size: 14px;
  font-weight: 300;
}

.footerBottom p {
  margin-bottom: 0;
  font-weight: 300;
}

.footerBottom .footerFlex {
  padding: 18px 0;
}

.fooUploadWrap {
  background: #ffffff33;
  padding: 20px;
  border: 1px dashed #ffffff33;
  border-radius: 10px;
  width: 160px;
}

.fooUploadWrap > img {
  display: block;
  margin-bottom: 5px;
}

.fooUploadWrap .btn {
  color: #15a3ac;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 5px;
}

.fooUploadWrap .btn img {
  margin-left: 5px;
}

.fooUploadWrap p {
  font-size: 16px;
  margin-bottom: 0;
}

/*-------------dashboard----------*/

/*dashboard left*/
.dashboardWrap {
  height: calc(100vh - 120px);
  background-color: #f8f8f8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.dashboardWrap .sideNav {
  overflow-y: auto;
  flex-shrink: 0;
  width: 266px;
  padding: 20px;
  background-color: #fff;
  align-self: stretch;
}

.dashboardWrap .sideNav li {
  display: block;
  width: 100%;
}

.dashboardWrap .sideNav li a {
  position: relative;
  padding: 16px 20px;
  border-radius: 10px;
  display: block;
  color: #002642;
  font-size: 16px;
  font-weight: 400;
}

.dashboardWrap .sideNav li a img {
  margin-right: 10px;
}

.dashboardWrap .sideNav li a.active {
  background: #15a3ac;
  color: #fff;
}

.dashboardWrap .sideNav li a.active:hover {
  opacity: 1;
}

.dashboardWrap .sideNav li a:hover {
  opacity: 0.6;
}

.dashboardWrap .sideNav .dashboardNav {
  margin: 0 0 160px;
  padding: 0;
  overflow-y: hidden;
}

.dashboardWrap .sideNav .dashboardNav li a .whiteIcon {
  display: none;
}

.dashboardWrap .sideNav .dashboardNav li a.active .whiteIcon {
  display: inline-block;
}

.dashboardWrap .sideNav .dashboardNav li a.active .greenIcon {
  display: none;
}

.dashboardWrap .tab-content {
  height: 100%;
  /* overflow-y: scroll; */
  flex: 1 1 auto;
}

.dashboardWrap .subNav {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.dashboardWrap .subNav li:nth-of-type(2) {
}

.dashboardWrap .subNav li {
  margin: 16px 20px;
}

.dashboardWrap .subNav li a {
  padding: 0;
  display: inline-block;
}

.dashboardWrap .subNav li:nth-of-type(2) a {
  opacity: 0.6;
}

.dashboardWrap .subNav li:nth-of-type(2) a:hover {
  opacity: 1;
}

.dashboardWrap > .tab-content {
  align-self: stretch;
  max-width: calc(100% - 266px);
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
}

/*-------------dashboard account settings tab----------*/

/*account settings tabs*/
.accountSettings {
  padding: 20px 39px;
}

.accountSettings .outerTabs.nav-pills {
  display: flex;
  justify-items: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.accountSettings .outerTabs.nav-pills > li {
  min-height: 100%;
}

.accountSettings .outerTabs.nav-pills > li a {
  font-weight: 400;
  font-size: 14px;
  height: 100%;
  line-height: 18px;
  color: #002642;
  padding: 21px 30px;
  background: #e5e5e533;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .accountSettings .outerTabs.nav-pills>li a:after{position:absolute;top:50%;right:0;height:45%;width:1px;background-color:#e4e3e3;content:"";transform:translate(0,-50%);} */
.accountSettings .outerTabs.nav-pills > li a.active {
  background-color: #fff;
  color: #15a3ac;
  font-weight: 600;
}

.accountSettings .outerTabs.nav-pills > li a.active:after,
.accountSettings > .outerTabs.nav-pills > li:nth-last-of-type(1) a:after {
  display: none;
}

.accountSettings .outerTabs.nav-pills > li a .notifyCount {
  top: 0px;
  right: 0px;
}

.tabContentWrap {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 10px 20px #08564d0d;
  border-radius: 4px;
  margin-bottom: 20px;
}

.dashboardTabContent {
  overflow-y: scroll;
  height: 100%;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 10px 20px #08564d0d;
  align-self: stretch;
}

.myWorksWrap h3 {
  margin: 30px 0 0 30px;
}

.myWorksWrap .myWorksInfo {
  padding: 30px;
}

.tabContentFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tabContentBox {
  width: 49%;
  padding-right: 17px;
}

.tabContentWrap > h6 {
  margin-bottom: 15px;
}

.editDpWrap {
  width: fit-content;
  position: relative;
  margin-bottom: 10px;
}

.editDpWrap > img {
  width: 92px;
  height: 92px;
  border-radius: 50%;
}

.editDpWrap .btn {
  margin-bottom: 20px;
  background-color: transparent;
  padding: 0;
  border: 0;
  list-style-type: none;
  position: absolute;
  top: -2px;
  right: -2px;
}

.delPhotoBtn {
  display: block;
  width: fit-content;
  color: #840032;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
}

.daysCheckbox {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.daysCheckbox input {
  opacity: 0;
  width: 40px;
  height: 40px;
  z-index: 10;
}

.daysCheckbox input + span {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 40px;
  height: 40px;
  background: #87878733;
  font-size: 12px;
  color: #002642;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.daysCheckbox input:checked + span {
  color: #fff;
  background-color: #15a3ac;
}

.daysCheckWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.daysCheckWrap .daysCheckbox:nth-last-of-type(1) {
  margin-right: 0;
}

.formFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.formFlex > div {
  width: 48%;
}

/*.infoTooltipBtn{padding:0;background-color:transparent;border:none;margin-left:6px;}*/
.tabContentBox .inputWrap p {
  color: #00264280;
}

/*package info tabs*/

/* .accountPricePackageWrap .pricePkgCard{margin-left:0;box-shadow:none;} */
.accountPricePackageWrap .pricePkgBtnGroup {
  margin-top: 40px;
}

/*profile information tab*/
.accountSettings.profileInfo > .nav-pills li a:after {
  display: none;
}

.accountSettings.profileInfo > .nav-pills li a {
  font-size: 16px;
  padding: 21px 40px;
}

.accountSettings.profileInfo > .nav-pills li a .greenIcon {
  display: none;
}

.accountSettings.profileInfo > .nav-pills li a.active .greenIcon {
  display: inline-block;
}

.accountSettings.profileInfo > .nav-pills li a.active .greyIcon {
  display: none;
}

.accountSettings.profileInfo > .nav-pills li a img {
  margin-left: 10px;
}

.profileInfo {
  padding-top: 10px;
  background-color: #fff;
}

.categoryStrip {
  margin-bottom: 50px;
  padding: 5px;
  background-color: #87878733;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryStrip p {
  margin: 0 20px 0 0;
}

.categoryStrip input {
  height: 34px;
  width: 230px;
}

/* .categoryStrip .dropdown:after{content:'0d7';font-family:'fontawesome';position:absolute;right:10px;top:50%;transform:translate(0,-50%);color:#15A3AC;font-size:14px;}
.categoryStrip .dropdown-menu{width:100%;padding:10px 20px;max-height:290px;overflow:scroll;}
.categoryStrip .dropdown-menu a{padding:19px 20px;border-bottom:1px solid #ccc;font-size:14px;line-height:18px;color:#002642;font-weight:normal;}
.categoryStrip .dropdown-menu a:nth-last-of-type(1){border-bottom:none;}
.categoryStrip .dropdown-menu a:hover{background-color:#cccccc33} */
.categoryStrip .ngSelect {
  min-width: 232px;
}

.categoryStrip .ngSelect .ng-select-container {
  height: 34px !important;
}
.addCatModal .categoryStrip .ngSelect .ng-select-container {
  height: 56px !important;
}
/* .catModMain .modal-dialog-centered.modal-dialog-scrollable .modal-content{
    min-height: 446px !important;
} */
.catModMain .modal-dialog-scrollable .modal-content {
  overflow: unset !important;
}
.addCatModal .ng-dropdown-panel .scroll-host {
  -webkit-overflow-scrolling: touch;
  display: block;
  /* overflow: hidden; */
  /* overflow-y: auto; */
  position: absolute;
  width: 100%;
  left: 0px;
  top: 1px;
  background: #fff;
  height: auto;
  /* position: relative; */
}
.categoryStrip .ng-dropdown-panel {
  padding: 0 20px;
}

.catModMain .modal-dialog .modal-content .component-host-scrollable {
  overflow: unset !important;
}

.categoryStrip .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 17px 20px;
  border-bottom: 1px solid #cccccc;
}

.categoryStrip
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected,
.categoryStrip
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background: #cccccc33;
}

.priceContentBox {
  width: 80%;
}

.priceContentBox .inputWrap > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.priceContentBox .inputWrap input {
  width: 453px;
}

.priceContentBox .inputWrap input + p {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 25px;
  color: #002642;
  margin-left: 20px;
}

.priceContentBox .inputWrap input + p span {
  margin-right: 15px;
}

.priceContentBox .greenBtn {
  margin-bottom: 40px;
}

.planBox {
  border-bottom: 1px solid #c9c9c980;
  margin-bottom: 20px;
}

.addPlanBtn {
  display: block;
  width: fit-content;
  margin: 0 0 5px auto;
}

.addPlanBtn img {
  margin-left: 5px;
  width: 12px;
}

.tabContentWrap.accSettingExp .tabContentBox {
  width: 58%;
}

.accSettingExp label.lgLabel {
  margin-bottom: 16px;
  font-weight: 600;
}

.accInfoTooltip {
  margin-top: 25px;
}

.accInfoTooltip .infoTooltip {
  display: inline-block;
  margin-left: 10px;
}

.accInfoTooltip .infoTooltip .transBtn img {
  width: 12px;
}

/*coins management tabs*/
.coinsTabs {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-bottom: 20px;
  /* border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px; */
}

.coinsTabs li {
  display: inline-block;
  margin-right: 30px;
}

.coinsTabs li:nth-last-of-type(1) {
  margin-right: 0;
}

.coinsTabs li a {
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
  color: #002642;
  position: relative;
}

.coinsTabs li a.active {
  color: #15a3ac;
  font-weight: 600;
}

.coinsTabs li a.active:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 2px;
  width: 80%;
  background-color: #15a3ac;
}

p.withdrawAmount {
  color: #15a3ac;
  display: inline-block;
  margin-right: 30px;
}

.withdrawBox {
  width: max-content;
  margin-left: auto;
  margin-bottom: 70px;
}

.withdrawBox h6 {
  font-weight: 500;
}

.withdrawBox h6 span {
  color: #15a3ac;
}

.paymentBtnGroup .button {
  margin-left: 20px;
}

.paymentBtnGroup .button:nth-of-type(2) {
  color: #840032;
}

.paymentBtnGroup .button img {
  margin-left: 5px;
}

.addpaymentFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addpaymentFlex > div:nth-of-type(1) {
  width: 70%;
}

.addpaymentFlex > div:nth-of-type(2) {
  width: 28%;
}

.addpaymentFlex .inputWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commisionWrap {
  margin-bottom: 40px;
}

.commisionWrap .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commisionWrap p {
  margin-bottom: 0;
  color: #002642;
}

.commisionWrap .flex p:nth-of-type(2) {
  font-weight: 700;
  color: #15a3ac;
}

.commisionWrap .flex.top p:nth-of-type(2) {
  color: #06053b;
}

.commisionWrap .flex.top {
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.commisionWrap .flex.top > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.commisionWrap .infoTooltip {
  margin-left: 5px;
}

.commisionWrap .flex:nth-of-type(2) {
  margin-bottom: 20px;
}

.commisionWrap .checkboxWrap span img {
  margin-right: 5px;
}

/*.addpaymentFlex input{width:130px;}*/
.addpaymentFlex .inputWrap input:nth-of-type(2) {
  margin-left: 20px;
}

.balanceWrap {
  margin-left: 20px;
}

.summaryBox {
  margin-left: 15px;
  margin-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.1);
  border-radius: 10px;
  padding: 20px 40px;
}

.summaryBoxFlex {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summaryBoxFlex p {
  margin-bottom: 0;
}

.summaryBoxTop {
  margin-bottom: 20px;
}

.summaryBoxTop p:nth-last-of-type(1) {
  font-weight: 700;
  color: #002642;
}

.summaryBoxBottom p:nth-last-of-type(1) {
  font-weight: 700;
  color: #15a3ac;
}

.summaryBox .form-control {
  background: #f7f7f7;
  opacity: 0.5;
  border: 1px solid #cccccc;
}

.summaryBoxBottom {
  border-top: 1px solid #f7f7f7;
  padding-top: 20px;
}

.paymentCardWrap {
  display: flex;
  margin-left: 20px;
}

.paymentCardWrap img {
  margin-right: 15px;
  max-width: 25%;
}

.uploadBtnGroup {
  display: flex;
  gap: 20px;
}

.paymentCardWrap img:nth-last-of-type(1) {
  margin-right: 0;
}

.transactionTable {
  max-width: 455px;
  margin: 20px 10px 30px;
}

.transactionTable .table thead th {
  border-bottom: 1px solid #dee2e6;
  font-size: 12px;
  font-weight: 400;
}

.transactionTable .table td,
.transactionTable .table th {
  border-top: none;
  padding: 5px;
}

.transactionTable .button {
  margin: 0 auto;
  display: block;
}

.coinsAmountBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.coinsAmountBox input {
  margin-right: 20px;
  width: 280px;
}

.coinsAmountBox h5 {
  font-weight: 400;
}

.coinsAmountBox h5 span {
  color: #15a3ac;
  display: inline-block;
  margin-right: 20px;
  font-size: 20px;
}

p.orBuyP {
  color: #878787;
  border: 1px solid #878787;
  display: block;
  padding: 1px 20px;
  border-radius: 20px;
  margin: 0 auto;
  width: max-content;
  position: relative;
}

p.orBuyP:before,
p.orBuyP:after {
  content: "";
  width: 100px;
  background-color: #e5e5e5;
  height: 1px;
  position: absolute;
  top: 50%;
}

p.orBuyP:before {
  left: -100px;
}

p.orBuyP:after {
  right: -100px;
}

.buyCoinsWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.buyCoinsWrap .box {
  width: 49%;
}

.buyCoinsWrap .box > p {
  margin-bottom: 0;
  font-size: 12px;
  color: #002642;
}

.buyCoinsWrap .box label {
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(37, 37, 92, 0.1);
  border-radius: 10px;
  max-width: 100%;
  height: 113px;
  margin: 8px;
}

.buyCoinsWrap .box input {
  width: 100%;
  height: 113px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.buyCoinsWrap .box label > span {
  border-radius: 10px;
  z-index: 9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #002642;
  flex-direction: column;
}

.buyCoinsWrap .box label > span span:nth-of-type(2) {
  font-weight: normal;
  color: #15a3ac;
}

.buyCoinsWrap .box input:checked + span {
  border: 1px solid #15a3ac;
}

.buyCoinsWrap .box label img {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.buyCoinsWrap .box label input:checked + span + img {
  display: inline-block;
}

.promoCodeWrap {
  position: relative;
}

.promoCodeWrap .button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.promoCodeWrap .form-control {
  padding-right: 60px;
}

.shareUl {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.shareUl li {
  display: inline-block;
  margin: 0 12px;
}

.referralBox {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referralBox p:nth-of-type(1) {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
  margin-bottom: 0;
}

.referralBox p:nth-of-type(2) {
  border: 1px solid #002642;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 10px;
  padding: 2px 10px;
  width: 65px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 0;
}

/* availability tab */
.availabilityTab p span {
  display: block;
}

.availabilityTab .tabContentBox {
  max-width: 460px;
}

.availabilityTab p {
  color: #00264280;
}

.availabilityTab label {
  margin-bottom: 30px;
}

.availabilityTab .customSlider {
  margin-bottom: 50px;
}

.availabilityTab .infoTooltip p {
  color: #002642;
}

/*-------------dashboard user my works detail  tab----------*/
.myWorksTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 24px;
}

.myWorksTop .breadCrumb {
  margin-bottom: 0;
}

.myWorksWrap .myWorkFlex {
  padding: 0 24px;
}

.myWorksTop .btnGroup .btn {
  margin-left: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.myWorksTop .btnGroup .transBtn:hover {
  color: #15a3ac;
}

.myWorksTop .btnGroup .btn img {
  margin-right: 5px;
}

.myWorksTop .btnGroup .btn.terminate {
  color: #840032;
}

.myWorksTop .shortBtn {
  width: auto;
}

.myWorksInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.myWorksInfo > div:nth-of-type(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.myWorksInfo p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #002642;
}

.myWorksInfo p:nth-of-type(1),
.myWorksInfo p:nth-of-type(2) {
  margin-right: 50px;
}

.myWorksInfo p span {
  font-size: 14px;
  color: #00264280;
  text-transform: none;
  margin-left: 5px;
}

.myWorksDesc {
  margin-bottom: 60px;
  background: #fafafa;
  border-radius: 3px;
  padding: 20px 30px;
  max-height: 350px;
  overflow-y: auto;
}

.myWorksDesc h6:nth-of-type(2) {
  font-size: 14px;
}
p.goalsContent {
  max-width: 330px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: inherit;
}
.myWorksInfoROw {
  /* justify-content: space-between !important; */
  width: 100%;
  align-items: baseline !important;
}

.myWorksDesc h6 {
  margin-bottom: 10px;
}

.myWorksDesc p a {
  color: #15a3ac;
  text-decoration: underline;
  font-weight: 600;
}

.myWorkSlider .carousel {
  z-index: 99;
}

.myWorkSlider .carousel-cells .carousel-cell {
  height: auto !important;
}

.myWorkSlider .carousel-cell img {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  margin-right: 5px;
}

.myWorkSlider .carousel-cell p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
}

.myWorkSlider .carousel-cell {
  background: #e5e5e533;
  padding: 20px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.myWorkSlider .carousel-container,
.myWorkSlider .carousel {
  height: 61px !important;
}

.myWorkSlider .carousel-container .carousel-cells {
  height: 61px !important;
}

.myWorkSlider .carousel-arrow {
  top: 32px !important;
  background: #ffffffcc;
  box-shadow: 0px 4px 4px #48484840;
  width: 38px !important;
  height: 38px !important;
}

.myWorkSlider .carousel-arrow-prev {
  left: -19px !important;
}

.myWorkSlider .carousel-arrow-disabled {
  opacity: 0 !important;
}

.myWorkSlider .carousel-cell.active {
  background: #fff;
  box-shadow: inset 0px 0px 5px 5px #d2d2d20d;
}

.userSlideTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cacaca;
  padding: 20px 30px;
}

.userSlideTop .left .dpWrap {
  width: 35px;
  height: 35px;
  position: relative;
  margin-right: 10px;
}

.userSlideTop .left .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.userSlideTop .left .dpWrap:before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #15a3ac;
  border: 2px solid #ffffff;
  position: absolute;
  top: -1px;
  right: -3px;
}

.userSlideTop .left,
.userSlideTop .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.userSlideTop .left div:nth-of-type(2) {
  /* margin-right: 10px; */
}

.userSlideTop .left div:nth-of-type(2) p {
  color: #002642;
  margin-bottom: 0;
}

.userSlideTop .left div:nth-of-type(2) p.lgP {
  font-weight: 600;
}

.userSlideTop .left p.rating {
  margin-bottom: 0;
  font-size: 12px;
  color: #002642;
  margin-right: 15px;
}

.userSlideTop .left p.rating img {
  margin-left: 5px;
}

.userSlideTop .left > p:nth-of-type(2) {
  font-size: 12px;
  line-height: 15px;
  color: #484848;
  margin-bottom: 0;
  margin-right: 25px;
}

.bookmarkBtn {
  width: 16px;
  height: 20px;
  padding: 0;
  position: relative;
  line-height: 1;
  font-size: inherit;
  border: none;
  background-color: transparent;
}

.bookmarkBtn:before {
  content: "";
  background-image: url("./assets/bookmark-o.svg");
  top: 0;
  left: 0;
  background-size: 16px 20px;
  width: 16px;
  height: 20px;
  display: inline-block;
}

.bookmarkBtn:hover:before {
  content: "";
  background-image: url("./assets/bookmark.svg");
}

.userSlideTop .right .shortBtn {
  width: 142px;
  margin-right: 25px;
}

.userSlideTop .right .transBtn {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #840032;
  margin-right: 25px;
}

.userSlideTop .right .transBtn:hover {
  opacity: 0.8;
}

.userSlideTop .right .transBtn img {
  margin-right: 10px;
}

.userSlideTop .right .whiteBtn {
  border-radius: 8px;
}

.userSlideTop .right .dropdown > .btn {
  border: none;
  padding: 0;
  background-color: transparent;
}

.userSlideBottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #cacaca;
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
}

.userSlideBottom p {
  color: #002642;
  margin-bottom: 0;
}

.userSlideBottom p span:nth-of-type(1) {
  font-weight: 600;
  font-size: 12px;
  color: #002642;
  opacity: 0.5;
  margin-left: 10px;
}

.userSlideBottom p span:nth-of-type(2) {
  margin-left: 10px;
  font-weight: 600;
  font-size: 12px;
  color: #002642;
}

.userSlideBottom .btn {
  font-size: 14px;
}

/*img annotation*/
.annotationFlex {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;
  border: 10px solid #e5ece7;
  border-radius: 6px;
  border-top: none;
}

.annotationImg img {
  width: 100%;
}

.a9s-annotationlayer .a9s-handle {
  overflow: visible;
}

.annotationFlex pagination-template {
  position: relative;
}

.annotationFlex .paginationWrap {
  width: 50%;
}

.annotationFlex .paginationWrap .ngx-pagination {
  margin-bottom: 0;
}

.annotationFlex .paginationWrap .btnGroup {
  padding: 20px;
}

.annotationFlex .paginationWrap .btnGroup .button {
  margin-right: 20px;
}

.annotationImg {
  max-height: 100%;
  position: relative;
}

.anoBtnGroup {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}

.anoBtnGroup .button {
  margin-right: 10px;
  width: auto;
  display: inline-flex;
  align-items: center;
  background: #15a3acb3;
}

.anoBtnGroup .button img {
  width: 15px;
  margin-right: 0;
}

.anoBtnGroup .button:hover {
  background: #15a3ac;
}

.anoBtnGroup .alignRadio {
  position: relative;
  margin-right: 10px;
  width: 37px;
  height: 31px;
  display: inline-flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
}

.anoBtnGroup .alignRadio span {
  background: #15a3ac;
  border: 1px solid #15a3ac;
  opacity: 0.5;
  padding: 3px 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.anoBtnGroup .alignRadio input[type="radio"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.anoBtnGroup .alignRadio input[type="radio"]:checked + span {
  opacity: 1;
}

/* .anoBtnGroup .alignRadio input[type="radio"]:checked+img{opacity: 0.2;} */
.annotateComWrap {
  width: 47%;
  background: #fafafa;
  align-self: stretch;
}

.annotateComWrap .commentHead {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #cacaca;
  display: flex;
  justify-content: space-between;
}
.commentHead .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #15a3ac;
  background-color: #15a3ac;
}
.commentHead label {
  font-size: 16px;
}

.annotateComWrap > div {
  padding: 20px;
}

.annotateComWrap > div > p {
  margin-bottom: 0;
  color: #002642;
}

.annotateComWrap > div > img {
  margin-bottom: 30px;
}

.annotateComWrap .btnGroup button {
  margin-right: 20px;
  font-weight: 400;
}

.annotateComWrap .btnGroup .whiteBtn {
  border-color: #840032;
  color: #840032;
  background: transparent;
}

.annotateComWrap .count-down {
  margin-left: 0;
  margin-bottom: 30px;
}

.annotateComWrap .commentBox .title {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.annotateComWrap .commentBox .title .lgP {
  font-weight: 600;
  color: #002642;
}

.annotateComWrap .commentBox .title p {
  margin-bottom: 0;
}

.annotateComWrap .commentBox {
  margin: 0 -20px 10px;
  padding: 5px 20px;
  transition: 0.2s;
}

.annotateComWrap .commentBox > p {
  margin-bottom: 0;
}

.annotateComWrap .btnGroup .heightBtn {
  font-size: 18px;
}

.annotateComWrap .btnGroup .transBtn {
  font-size: 14px;
  margin-right: 40px;
}

.annotationFlex.noReply .comment + .comment.editable {
  display: none;
}

.annotationImg > div {
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: center;
}

.annotateComWrap .commentBox.commentHover {
  background: #15a3ac;
}

.annotateComWrap .commentBox.commentHover .title .lgP,
.annotateComWrap .commentBox.commentHover .title p,
.annotateComWrap .commentBox.commentHover > p {
  color: #fff;
}

/*.a9s-annotation.editable .a9s-inner, .a9s-annotation .a9s-outer, .a9s-selection .a9s-outer,.a9s-handle .a9s-handle-outer,.a9s-handle .a9s-handle-inner,.a9s-annotation.editable .a9s-inner:hover{stroke: #15A3AC;}
.a9s-handle .a9s-handle-inner, .a9s-handle:hover .a9s-handle-inner{ fill:#15A3AC; }
.a9s-annotation.editable .a9s-inner:hover {
fill: #17a2b829;
}*/
svg.a9s-annotationlayer .a9s-selection .a9s-outer,
svg.a9s-annotationlayer .a9s-annotation .a9s-outer {
  display: none;
}

svg.a9s-annotationlayer .a9s-handle .a9s-handle-outer {
  display: none;
}

svg.a9s-annotationlayer .a9s-annotation .a9s-inner {
  stroke-width: 2;
  stroke-dasharray: 0;
}

.a9s-annotation.selected .a9s-inner,
.a9s-selection .a9s-inner {
  stroke: #15a3ac !important;
}

.a9s-annotation.editable .a9s-inner {
  stroke: #15a3ac !important;
}

.a9s-annotation .a9s-inner:hover,
.a9s-selection .a9s-inner:hover {
  stroke: #15a3ac !important;
}

svg.a9s-annotationlayer .a9s-annotation.editable:hover .a9s-inner {
  fill: transparent;
}

svg.a9s-annotationlayer .a9s-handle .a9s-handle-inner {
  fill: #15a3ac;
  stroke: #15a3ac;
}

.a9s-annotationlayer
  .a9s-formatter-el
  foreignObject
  .a9s-shape-label-wrapper
  .a9s-shape-label {
  display: flex !important;
}

.a9s-annotation .a9s-inner,
.a9s-selection .a9s-inner {
  stroke: #15a3ac !important;
}
svg.a9s-annotationlayer .a9s-annotation.custom-class .a9s-inner {
  stroke: #f39237 !important;
}

.a9s-annotationlayer
  .custom-class
  .a9s-formatter-el
  foreignObject
  .a9s-shape-label-wrapper
  .a9s-shape-label {
  background-color: #f39237 !important;
}

svg.a9s-annotationlayer .a9s-selection-mask {
  fill: rgba(0, 0, 0, 0.6);
}

.r6o-btn.outline,
.r6o-btn.outline:hover {
  border: 1px solid #15a3ac;
  background-color: #fff;
  color: #15a3ac;
  padding: 2px 20px;
}

.r6o-btn,
.r6o-btn:hover {
  border: 1px solid #15a3ac;
  background-color: #15a3ac;
  color: #fff;
  padding: 2px 20px;
}

.r6o-editable-text {
  height: 80px !important;
}

.r6o-widget.comment .r6o-editable-text,
.r6o-widget.comment .r6o-readonly-comment {
  padding: 7px 35px 7px 7px;
}

.r6o-lastmodified-at {
  display: none !important;
}

.dpWrapTwo {
  /* width: 80px !important; */
  /* height: 50px !important; */
}

.r6o-widget.comment .r6o-arrow-down {
  line-height: 16px;
  border-radius: 3px;
  height: 25px;
  width: 30px;
  padding-top: 2px;
  background: #15a3ac !important;
  color: #fff;
}
.r6o-icon.r6o-arrow-down svg {
  width: 20px;
}

.r6o-lastmodified-at {
  display: none !important;
}

.userWorkShare {
  margin-right: 10px;
}

/*advisor rating modal*/
.rateAdDp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.rateAdDp img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.rateAdDp p {
  margin: 0 0 0 10px;
}

.ratingInput .br {
  margin: 0px 0 20px !important;
}

.ratingInput .br-default .br-unit {
  width: 25px !important;
  height: 25px !important;
}

.ratingInput .br-default .br-selected:after,
.ratingInput .br-default .br-active:after {
  color: #15a3ac !important;
}

.ratingInput.viewRatingMain .br-default .br-unit {
  width: 15px !important;
  height: 15px !important;
  font-size: 14px;
  margin: 0;
}

.viewRatingMain .br {
  margin: 0px 0 6px !important;
}

.viewRatingMain .br-default .br-selected:after,
.ratingInput .br-default .br-active:after {
  color: #f39237 !important;
}

/*advisor user review modal*/
.userReview p:nth-of-type(odd) {
  margin-bottom: 0;
}

/* user share work modal */
.shareWorkModalWrap {
  text-align: center;
}

.shareWorkModalWrap .modal-content {
  padding: 40px 20px;
  background: url("./assets/share-modal-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgb(3 91 88 / 10%);
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
}

.shareWorkModalWrap .modal-dialog {
  max-width: 580px;
}

.shareWorkModalWrap h2 {
  margin: 0 auto 50px;
  max-width: 370px;
}

.shareWorkModalWrap .button {
  max-width: 122px;
  margin: 0 auto 80px;
}

/*-------------homepage----------*/

/*banner*/
.homeBanner {
  position: relative;
}

.homeBanner .bannerContWrap {
  position: absolute;
  left: 128px;
  top: 50px;
}

.bannerCont {
  max-width: 730px;
  min-width: 730px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 20px;
  padding: 50px;
}

.bannerCont .greenBtn {
  margin-right: 20px;
}

.bannerCont .whiteBtn {
  background-color: transparent;
}

.bannerCont .whiteBtn img {
  margin-left: 5px;
}

.bannerCont h1 img {
  margin-left: 9px;
}

.bannerCont p {
  max-width: 525px;
  text-align: start !important;
}

.homeBanner .bannerCont p span {
  display: block;
}

.homeBanner .bannerCont + p {
  color: #fff;
  margin: 10px 0 0 30px;
  max-width: 370px;
  font-weight: 600;
}

.homeBanner > img {
  width: 100%;
}

/*popular service section*/
.popularService {
  background: #f5f6fa;
  padding: 80px 0 170px;
  position: relative;
}

/* .popularService .wrapper{margin-right:0;} */
.popularService > img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.popularService .wrapper > h2 {
  text-align: center;
  margin-bottom: 60px;
}

.popularService .wrapper > p {
  margin-bottom: 60px;
  text-align: center;
  width: 810px;
  margin-left: auto;
  margin-right: auto;
  color: #00264280;
}

.popularService .accordWrap {
  flex: 1 1 auto;
  margin-right: 20px;
}

.popularService .accordWrap .card .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  color: #002642;
  font-size: 24px;
  font-weight: 600;
}

.signSlider .slick-dots {
  display: flex !important;
  bottom: -35px !important;
}

.signSlider .slick-dots li {
  margin: 0 !important;
}

.signSlider .slick-dots li.slick-active button:before {
  color: #ffffff !important;
  opacity: 1 !important;
}

.signSlider .slick-dots li button:before {
  color: #ffffff !important;
  opacity: 0.2 !important;
  width: 0 !important;
}

.signSlider .slick-dots li button::before {
  font-size: 9px;
}

.popularService .accordWrap .card .btn img {
  margin-right: 23px;
}

/*.popularService .accordWrap .card .btn.collapsed .fa-caret-up,.popularService .accordWrap .card .btn .fa-caret-down{display:none;}
.popularService .accordWrap .card .btn .fa-caret-up,.popularService .accordWrap .card .btn.collapsed .fa-caret-down{display:inline-block;}*/
.popularService .accordWrap .card {
  border: none;
  border-radius: 10px;
  margin-bottom: 30px;
}

.popularService .accordWrap .card:nth-last-of-type(1) {
  margin-bottom: 0;
}

/*.popularService .accordWrap .card .btn .fa{color:#15A3AC;}*/
.popularService .accordWrap .cardBody p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  color: #00264280;
}

.popularService .accordWrap .cardBody {
  padding: 0 30px 30px;
}

.popularService .wrapper {
  position: relative;
}

/*.popularService .wrapper>img{position:absolute;right:0;}*/
.popServiceFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.popServiceSlider {
  width: 600px;
  flex-shrink: 0;
}

.popServiceSlider .slick-dots {
  bottom: 85px;
  left: -8%;
}

.popServiceSlider .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 5px;
}

.popServiceSlider .slick-dots li button {
  padding: 0;
  width: 10px;
  height: 10px;
  font-size: 10px;
}

.popServiceSlider .slick-dots li button:before {
  color: #fff;
  width: 10px;
  height: 10px;
  font-size: 10px;
  line-height: 10px;
}

.popServiceSlider .slick-dots li.slick-active button:before {
  color: #32abb6;
}

/*how it work section*/
.howItWorkSec {
  padding: 80px 0;
  position: relative;
}

.howItWorkSec > img {
  position: absolute;
  right: 50px;
  top: -96px;
}

.howItWorkSec h2 {
  text-align: center;
  margin-bottom: 50px;
}

.workBox {
  position: relative;
  width: 478px;
  margin: 0 auto 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.howItWorkSec .workBox:nth-last-of-type(1) {
  margin-bottom: 0;
}

.howItWorkSec .workBox:nth-of-type(2) {
  padding-left: 80px;
}

.workBox h5 {
  margin-bottom: 15px;
  font-weight: 600;
}

.workBox h5 span {
  color: #15a3ac;
}

.workBox p {
  padding-left: 20px;
}

.workBox img.icon {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  box-shadow: 0px 30px 30px rgba(8, 86, 77, 0.06);
  border-radius: 50%;
  margin: 0 25px;
}

.workBox img.icon:hover {
  box-shadow: 0px 50px 60px rgba(8, 86, 77, 0.1);
}

.workBox > div {
}

.workBox .vector {
  position: absolute;
}

.workBox .vector1 {
  right: -110px;
  top: 44%;
}

.workBox img.mob {
  display: none;
}

.workBox .vector2 {
  left: -110px;
  top: 44%;
}

.becomeAdvisorPage .howItWorkSec .workBox:nth-of-type(2) {
  padding-left: 0px;
}

/*example section*/
.exampleSec {
  padding: 80px 0;
  background: #002642;
}

.exampleSec h2 {
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}

.exampleSec .innerWrapper > p {
  color: #fffc;
  text-align: center;
  font-weight: 300;
  margin-bottom: 40px;
}

.exampleSec .innerWrapper {
  max-width: 625px;
  margin: 0 auto;
}

.exampleSec .seeAllBtn {
}
.saAdvMobBtn {
  display: none !important;
}
.exampleFilters {
  text-align: center;
  margin: 0 -8px 25px;
}

.exampleFilters .button {
  background-color: #fff;
  color: #15a3ac;
  margin: 0 8px 15px;
  box-shadow: 0px 3px 8px rgb(37 37 92 / 10%);
  border-radius: 10px;
  border: none;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 600;
  height: 46px;
}

.exampleFilters .button:hover,
.exampleFilters .button.active {
  background-color: #15a3ac;
  color: #fff;
}

.exampleSec .exampleFlex {
  margin-bottom: 15px;
}

/*.exampleFilters .button:nth-of-type(1){margin-left: 0;}
.exampleFilters .button:nth-last-of-type(1){margin-right: 0;}*/
.exampleFilters .carousel-cells .carousel-cell {
  width: auto !important;
}

.exampleFilters .carousel {
  height: 56px !important;
}

.exampleFlex {
  margin: 0 -8px;
  display: flex;
  justify-content: flex-start;
  /* align-items: flex-start; */
  align-items: stretch;
  flex-wrap: wrap;
}

.exampleSlider {
  margin: 0 -15px 15px;
}

.exampleSlider .exampleBox {
  width: 90%;
  margin: 15px auto;
}

.exampleCarousal .exampleBox {
  width: 92%;
  margin: 15px auto;
}

.exampleBox {
  margin: 8px;
  box-shadow: 0px 5px 20px rgba(2, 16, 14, 0.1);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  width: 23.2%;
  padding-bottom: 30px;
}

.exampleBox .imgWrap {
  position: relative;
  height: 124px;
  margin-bottom: 8px;
}

.exampleBox .imgWrap > img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 10px 10px 0 0;
}

.exampleBox .imgWrap label {
  background: #f39237;
  border-radius: 18px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  top: 20px;
  left: 20px;
  color: #fff;
  padding: 3px 10px;
  position: absolute;
}

/* .exampleBox .imgWrap .btn{padding: 5px; width:24px;height:26px;background-color:#fff;border-radius:10px;position:absolute;top:20px;right:20px;}
.exampleBox .imgWrap .btn:before{content:"";background-image:url('./assets/example-share.svg');position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background-size:16px 20px;width:16px;height:20px;display:inline-block;}
.exampleBox .imgWrap .btn:hover{opacity:0.8;} */
.exampleBox .content {
  padding: 0 20px 25px;
}

.exampleBox .content > p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #00264280;
  margin-bottom: 10px;
}

.exampleBox .content h5 {
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  color: #002642;
  margin-bottom: 2px;
}

.exampleBoxFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.exampleBoxFlex .exampleBoxFlex {
  margin-bottom: 0;
}

.exampleBoxFlex .dpWrap {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.exampleBoxFlex .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.exampleBoxFlex .dpWrap + div {
  flex: 1 1 auto;
  padding-left: 10px;
}

.exampleBoxFlex h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
}

.exampleBoxFlex p {
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 0 10px;
  color: #00264280;
}

.exampleBox .shortBtn {
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: 15px;
  left: 10px;
  width: 92%;
}

.exampleBox .dropdown.exampleShareDrop {
  position: absolute;
  top: 16px;
  right: 15px;
}

.exampleFlex .button:hover {
  background-color: #15a3ac;
  color: #fff;
}

.homePage .seeAllBtn {
  margin-left: auto;
  display: block;
}

.homePage .seeAllBtn .fa {
  font-size: 15px;
  margin-left: 5px;
}

.exampleSec .seeAllBtn {
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
}

.exampleBoxTotal {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  padding: 6px 20px;
}

.exampleBoxTotal h3 {
  font-weight: 600;
  margin-bottom: 0;
  color: #002642;
  font-size: 10px;
}

.exampleBoxTotal p {
  font-size: 10px;
  margin: 0 0 0 10px;
  color: #002642;
  font-weight: 600;
}

/*advisor section  */
.advisorSec {
  padding: 100px 0;
  position: relative;
}

.advisorSec h2 {
  margin-bottom: 35px;
  text-align: center;
}

.advisorSec > img {
  position: absolute;
  right: 25px;
  top: -60px;
  z-index: -1;
}

.homeAdvisorWrap {
  max-width: 830px;
  margin: 0 auto;
}

.homeAdvisorWrap .seeAllBtn {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.homeAdvisorWrap .dicoverAdFlex {
  justify-content: center;
}

/*love oprovi advisor section*/
.oproviAdvisorSec {
  padding: 80px 0 100px;
  background: #f5f6fa;
}

.oproviAdvisorSec h2 {
  margin-bottom: 40px;
  text-align: center;
}

.oproviAdvisorSec .wrapper {
  position: relative;
}

.oproviAdvisorFlex {
  margin: 0 auto;
  max-width: 950px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.oproviAdvisorBox {
  z-index: 99;
  margin: 0 15px;
  width: 47%;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.oproviAdvisorBox:hover {
  box-shadow: 0px 20px 46px rgba(8, 86, 77, 0.2);
}

.oproviAdvisorTop {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.oproviAdvisorTop .dpWrap {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.oproviAdvisorTop .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.oproviAdvisorTop .dpWrap + div {
  padding-left: 15px;
}

.oproviAdvisorTop h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 5px;
}

.oproviAdvisorTop p {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #002642;
  margin-bottom: 0;
}

.oproviAdvisorBox > p {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #00264280;
  margin-bottom: 15px;
  /*display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:6;overflow:hidden;text-overflow:ellipsis;*/
}

.oproviAdvisorBox .rating span {
  font-size: 24px;
  margin-right: 20px;
  line-height: 1;
}

.oproviAdvisorBox .rating img {
  width: 18px;
  margin-right: 6px;
}

.oproviAdvisorBox .rating {
  margin-bottom: 0;
  justify-content: flex-end;
}

.oproviAdvisorSec .wrapper > img {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 48px;
}

/*coin system section*/
.coinSystemSec {
  padding: 100px 0;
}

.coinSystemSec h2 {
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coinSystemSec h2 img {
  margin-right: 5px;
}

.coinSystemSec .innerWrapper {
  max-width: 825px;
  margin: 0 auto;
}

.coinSystemSec .innerWrapper > p:nth-of-type(1) {
  text-align: center;
  margin-bottom: 50px;
}

.coinSystemSec .innerWrapper > p:nth-of-type(2) {
  font-weight: 600;
  color: #002642;
  margin-bottom: 5px;
}

.getCoinsWrapper {
  margin: 0 auto;
  max-width: 540px;
}

.getCoinsWrap > p {
  font-weight: 600;
  color: #002642;
  margin-bottom: 5px;
}

.getCoinsFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
}

.coinBox {
  width: 255px;
  padding: 30px 20px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
}

.coinBoxWrap {
  margin-right: 30px;
}

.getCoinsFlex .coinBoxWrap:nth-last-of-type(1) {
  margin-right: 0;
}

.coinBox p {
  font-size: 24px;
  color: #15a3ac;
}

.coinBoxWrap > p:nth-of-type(1) {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #002642;
  margin-bottom: 0;
}

.coinBoxWrap > p {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
}

.coinBox:hover {
  box-shadow: 0px 20px 46px rgba(8, 86, 77, 0.2);
}

.getCoinsWrap {
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 3px 20px rgb(8 86 77 / 10%);
  background: url("./assets/get-coins.svg") no-repeat;
  background-position: bottom;
  background-size: contain;
  background-color: #fff;
  min-height: 275px;
}

/* .getCoinsWrap>img{width:100%;} */
.getCoinsWrap .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.getCoinsWrap .content h2 {
  margin-bottom: 15px;
}

.getCoinsWrap .content p {
  margin-bottom: 35px;
}

/*feedback section*/
.feedbackSection {
  position: relative;
}

.feedbackSection > img {
  width: 100%;
}

.feedbackSection > div {
  padding-top: 10px;
  display: flex;
  width: 730px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 10px;
}

.feedbackSection > div > .button {
  z-index: 99;
  flex-shrink: 0;
  margin-left: 30px;
}

.feedbackSection .btnGroup a {
  display: inline-block;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #00264280;
  padding: 10px 17px;
  border-radius: 30px;
  margin: 0 10px 10px 0;
}

.feedbackSection > div > img {
  position: absolute;
  right: -22%;
  top: 34px;
}

.feedbackSection .btnGroup a:hover {
  color: #15a3ac;
}

/*--------------example view------------*/
.exampleUserSlider .userSlideBottom {
  display: none;
}

.exampleLiked {
  padding: 2px 65px;
  background: #002642;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exampleLiked p {
  font-size: 12px;
  margin-bottom: 0;
  display: inline-block;
  color: #fff;
}

.exampleLiked p span {
  font-weight: 600;
  margin-left: 10px;
}

.exampleLiked div:nth-of-type(1) p {
  margin-right: 35px;
}

.likeBtn {
  width: 67px;
  height: 27px;
  margin-bottom: 0;
  margin-right: 5px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
}

.likeBtn:hover {
  cursor: pointer;
}

.likeBtn input {
  width: 67px;
  height: 27px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.likeBtn > span {
  border-radius: 25px;
  z-index: 9;
  width: 67px;
  height: 27px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #fff;
}

.likeBtn span img {
  margin-right: 5px;
}

.likeBtn input:checked + span {
  background: #f39237;
  border-color: #f39237;
}

.likeBtn > span span {
  display: none;
}

.likeBtn input:checked + span span {
  display: inline;
}

.annotationFlex.exampleAnnotation {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.similarExText {
  color: #00264280;
  text-align: center;
}

.similarExText a {
  color: inherit;
  text-decoration: underline;
}

.similarExText a:hover {
  color: #002642;
}

.exampleViewPage .exampleAnnotation {
  margin-bottom: 40px;
}

/* similar example modal */
.similarExampleModal ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.similarExampleModal ul li {
  margin-bottom: 20px;
}

.similarExampleModal p {
  color: #002642;
}

.similarExampleModal .inputWrap {
  margin-bottom: 30px;
}

.similarExampleWrap .modal-content {
  padding: 60px 90px 50px 60px;
  background: url("./assets/similar-example-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgb(3 91 88 / 10%);
}

.similarExampleWrap .vector {
  position: absolute;
  right: 85px;
  top: 42%;
}

/*-------------homepage logged----------*/

/*example sec*/

/* .loggedExampleSec h2{text-align:left;margin-bottom:0;font-weight:600;}
.loggedExampleSec .carousel-arrow{width:70px!important;height:70px!important;background-size:35px 26px!important;}
.loggedExampleSec .carousel-arrow-disabled{opacity:1!important;}
.loggedExampleSec .carousel-arrow-prev{background-image:url('./assets/carousal-left.svg')!important;left:-18px!important;}
.loggedExampleSec .carousel-arrow-next{background-image:url('./assets/carousal-right.svg')!important;right:-32px!important;}
.loggedExampleSec .carousel-arrow-prev.carousel-arrow-disabled{background-image:url('./assets/carousal-left-disabled.svg')!important;}
.loggedExampleSec .carousel-arrow-next.carousel-arrow-disabled{background-image:url('./assets/carousal-right-disabled.svg')!important;}
.loggedExampleSec .carousel{height:405px!important;z-index:9;} */
.homePageLogged .exampleSec h2 {
  margin-bottom: 35px;
}

/*advisor sec*/

/* .loggedAdvisorSec.advisorSec>img{top:-109px;}
.loggedAdvisorSec.advisorSec h2{text-align:left;margin-bottom:15px;}
.loggedAdvisorSec .innerWrapper{max-width:810px;margin:0 auto;}
.loggedAdvisorSec .exampleFilters{margin-bottom:60px;}
.exampleFilters .button:hover{color:#15A3AC;background-color:#fff;box-shadow:0px 20px 46px rgba(8,86,77,0.2);}
.loggedAdvisorSec .exampleFilters .slick-track{padding:35px 0 60px;}
.loggedAdvisorSec.advisorSec .seeAllBtn{margin-bottom:10px;} */

/*----------------homepage advisor------------*/
.homeAdvisorBanner.homeBanner .bannerContWrap {
  top: 70px;
}

/*-------------advisor profile----------*/
.pageCard {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
  border-radius: 4px;
  /* padding: 30px; */
}

/*advisor profile top*/
.advisorProfTop {
  margin-bottom: 18px;
  padding: 18px 0;
  /* border-bottom: 1px solid #f3f3f3; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advisorProfTop .left,
.advisorProfTop .left .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.advisorProfTop .left .info .dpWrap {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  position: relative;
}

.advisorProfTop .left .info .dpWrap .badgeIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 0;
  right: -6px;
}

.advisorProfTop .left .info .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.advisorProfTop .left .info .dpWrap + div {
  padding-left: 20px;
}

.advisorProfTop .left .info .dpWrap + div h6,
.advisorProfTop .left .info .dpWrap + div p {
  margin-bottom: 0;
}

.commonAdProfile .advisorProfTop:nth-of-type(2) .left .info {
  margin-left: 30px;
}

.advisorProfTop .left .rating {
  margin-bottom: 0;
  margin-left: -10px;
}

.advisorProfTop .left .rating span {
  font-size: 14px;
  margin-right: 13px;
}

.advisorProfTop .left .rating img {
  width: 15px;
}

.advisorProfTop .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.advisorProfTop .right .shortBtn {
  width: auto;
  height: 45px;
  padding: 9px 30px;
  margin-left: 16px;
}

.advisorProfTop .right .adOffWrap {
  /* margin-right: 30px; */
}

.advisorProfTop .right .status {
  margin-bottom: 0;
  margin-right: 25px;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #002642;
}

.advisorProfTop .right .status img {
  margin-right: 5px;
}

.advisorProfTop .right .profilebtn {
  display: flex;
}

.advisorProfTop .right .info {
  display: none;
}

/* .advisorProfTop .right .reportBtn{margin-right:30px;} */
.adCirP {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(8, 86, 77, 0.1);
  border-radius: 20px;
  color: #15a3ac;
  padding: 10px 16px;
}

.advisorProfilePage .box {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.advisorProfilePage .box label {
  margin-bottom: 0;
  max-width: 50px;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #002642;
  opacity: 1;
}

.advisorProfTop:nth-of-type(1) .box label {
  opacity: 1;
}

/* .advisorProfTop:nth-of-type(2) .box{margin-left:100px;} */
.advisorProfilePage .box p {
  margin-left: 5px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #15a3ac;
  min-width: 40px;
  padding: 6px 1rem !important;
  display: flex;
  justify-content: center;
}

.adCirP img {
  margin-right: 7px;
  display: inline-block;
}

.adCirP span {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #002642;
  opacity: 0.5;
  margin-left: 5px;
}

.advisorProfilePage .pageCard {
  padding-bottom: 5px;
  margin-bottom: 0px;
  box-shadow: none;
}

.advisorProfTop .favTextBtn {
  font-size: 18px;
  padding-left: 30px;
}

.reportBtn {
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  line-height: 18px;
  color: #eb5757;
  opacity: 0.7;
  border: 1px solid #eb5757;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 3px 24px;
}

.reportBtn img {
  margin-right: 5px;
}

.advisorProfDesc {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advisorProfDesc .left {
  display: flex;
  justify-content: flex-start;
  /* align-items: flex-end; */
}

.advisorProfDesc .left div:nth-of-type(1) {
  /* max-width: 200px; */
  margin-right: 30px;
}

.advisorProfDesc .left p {
  font-weight: normal;
  font-size: 14px;
}

.advisorProfDesc .left label {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}

.advisorProfTimer {
  /* display:flex; */
  /* align-items: center; */
}
.advisorProfTimer p {
  /* margin-bottom: 0; */
}

.advisorProfTimer h6,
.advisorProfDesc h6 {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 18px;
}

.advisorProfTimer .count-down {
  margin-left: 5px;
}

.dummyAdTitle {
  font-size: 16px;
  margin-bottom: 30px;
}

.advisorProfTop .expLabel {
  margin: 0 25px 0 0;
  font-weight: 600;
}

/* .advisorProfDesc .count-down .item{background:#002642;} */
.advisorProfDesc .right .btnGroup .button:nth-of-type(1) {
  margin-right: 20px;
}

.advisorProfDesc .right .btnGroup .button {
  font-size: 18px;
  height: 45px;
  line-height: 23px;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
}

.advisorProfDesc .right .btnGroup .button img {
  margin-right: 5px;
}

.advisorProfDesc .right {
  flex: 1 1 auto;
  text-align: right;
}

.advisorProfDescription {
  background: #fafafa;
  padding: 20px 0;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
  border-radius: 4px;
  margin-bottom: 40px;
}

.advisorProfDescription h6 {
  margin: 20px 20px 0px;
}

.advisorProfDescription h5 {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 10px;
}

.advisorProfDescription p {
  opacity: 0.5;
  margin-bottom: 5px;
}

.advisorProfDescription .boxWrap {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px;
}

.advisorProfDescription .boxWrap:nth-last-of-type(1) {
  border-bottom: none;
}

.advisorProfDescription h5.list:before {
  content: "";
  position: absolute;
  left: -15px;
  top: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c4c4c4;
}

.advisorProfTabs > h6 {
  font-size: 12px;
  margin-bottom: 15px;
}

.advisorProfTabs .nav {
}

.advisorProfTabs .nav li {
}

.advisorProfTabs .nav li a {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #f3f3f3;
  background: #cccccc80;
  border-radius: 20px 20px 0px 0px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #00264280;
}

.advisorProfTabs .nav li a.active {
  color: #15a3ac;
  font-weight: 600;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(8, 86, 77, 0.1);
}

.advisorProfTabs .tab-content {
  margin-bottom: 40px;
  padding: 30px;
  background: #fafafa;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
  border-radius: 4px;
}

.advisorProfTabs > .whiteBtn {
  border: 1px solid #e5e5e5;
}

.tabTop {
  width: 485px;
}

.tabTop > label.yearLabel {
  margin-bottom: 20px;
  margin-right: 20px;
}

.tabTop label {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
}

.tabTop p a {
  font-weight: 600;
  color: #15a3ac;
}

.tabTop p a:hover {
  text-decoration: underline;
}

.tabTop .experenceFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tabTop .experenceFlex div:nth-of-type(1) {
  margin-right: 30px;
}

.tabTop .experenceFlex label {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
}

.tabTop .experenceFlex p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #002642;
}

label.yearLabel {
  /* margin-bottom: 30px; */
  background-color: #fff;
  box-shadow: 0px 5px 20px rgba(8, 86, 77, 0.1);
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #15a3ac;
  padding: 10px 15px;
}

.crossed-label{ 
  position: relative;
  margin-right: 10px;
}

.crossed-label::after{
  content: "";
  width: 75%;
  height: 2px;
  background: #15a3ac;
  transform: rotate(-16deg);
  position: absolute;
  bottom: 47%;
  left: 7px;
} 

.tabTop .pricePlanWrapper label.yearLabel {
  display: block;
  width: max-content;
}

.tabTop h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 30px;
}

.pricingPlan {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
  border-radius: 8px;
}

/*.pricePlanWrapper .form-check input[type="radio"]:checked+.pricingPlan{background:linear-gradient(34.4deg,#15A3AC 5.4%,#3A97B4 114.34%);box-shadow:0px 3px 8px rgba(8,86,77,0.1);}*/
.pricingPlan .top {
  padding: 20px;
}

.pricingPlan input[type="checkbox"]:checked {
  accent-color: #15a3ac;
}

.pricingPlan .top .flex:nth-of-type(1) {
  justify-content: space-between;
}

.pricingPlan .top span.pkgLabel {
  margin-left: 5px;
  font-size: 10px;
  padding: 2px 10px;
  border-radius: 4px;
  line-height: 14px;
  color: #002642;
  border: 1px solid #002642;
  display: inline-block;
}

.pricingPlan .flex {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.pricingPlan .flex .left {
  /* width: 120px; */
  flex-shrink: 0;
}

.pricingPlan .flex {
  gap: 20px;
}

.pricingPlan .bottom {
  padding: 20px;
  border: 1px solid #f3f3f3;
}

.pricingPlan .bottom .flex {
  align-items: center;
}

.pricingPlan h6 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 23px;
}

.pricingPlan .left label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #002642;
}

.pricingPlan .flex:nth-last-of-type(1) {
  margin-bottom: 0;
}

.pricingPlan ul {
  padding: 0 0 0 20px;
  margin: 0;
}

.pricingPlan ul li {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #002642;
  opacity: 0.5;
}

.pricingPlan label.yearLabel {
  /* margin: 0 30px 0 0; */
}

.pricingPlan:hover {
  border: 1px solid #15a3ac;
}

/*.pricingPlan:hover h6,.pricingPlan:hover p,.pricingPlan:hover label,.pricingPlan:hover ul li{color:#fff;}
.pricingPlan:hover label.yearLabel{color:#15A3AC;}*/
.pricePlanWrapper .form-check {
  padding: 0;
  position: relative;
  margin-bottom: 20px;
}

.pricePlanWrapper .form-check-label {
  width: 100%;
  z-index: 9;
}

.pricePlanWrapper .form-check input[type="radio"] {
  position: absolute;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  margin: 0;
  cursor: pointer;
}

/* Ahsan */
.pricePlanWrapper .form-check input[type="radio"]:checked {
  border: 2px solid #15a3ac;
  border-radius: 8px;
  box-sizing: border-box;
}

/* .pricePlanWrapper .form-check input[type="radio"]:checked+.pricingPlan h6,.pricePlanWrapper .form-check input[type="radio"]:checked+.pricingPlan p,.pricePlanWrapper .form-check input[type="radio"]:checked+.pricingPlan label,.pricePlanWrapper .form-check input[type="radio"]:checked+.pricingPlan ul li{border:1px;border-color:#000;}
.pricePlanWrapper .form-check input[type="radio"]:checked+.pricingPlan label.yearLabel{color:#15A3AC;} */
.advisorProfTabs .greenBtn {
  margin: 30px 0 0 30px;
}

/* report modal */
.reportModal p {
  color: #002642;
  margin-bottom: 0;
}

.reportModal .content > span {
  font-size: 14px;
  line-height: 24px;
  color: #002642;
  opacity: 0.5;
  margin-bottom: 12px;
  display: block;
}

.reportModal .customRadio label.form-check span {
  font-size: 16px;
}

/*-------------about us page----------*/
.homeBanner.aboutBanner .bannerContWrap {
  left: auto;
  right: 223px;
  top: 70px;
}

.homeBanner.aboutBanner .bannerCont {
  padding: 50px 85px 50px 50px;
  min-width: 635px;
  max-width: 635px;
}

.homeBanner.aboutBanner .list p {
  margin-bottom: 0;
}

/*founders*/
.founderSec {
  padding: 80px 0 100px;
  background: #fafafa;
  position: relative;
}

.founderFlex {
  max-width: 930px;
  margin: 0 auto;
}

.founderSec > img {
  position: absolute;
  left: 130px;
  top: -51px;
}

.founderSec h2 {
  text-align: center;
  margin-bottom: 30px;
}

.founderFlex {
  margin-bottom: 174px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.founderBox {
  background: #ffffff;
  z-index: 9;
  box-shadow: 0px 63px 50px rgba(8, 86, 77, 0.0705882);
  border-radius: 20px;
  padding: 50px;
  width: 445px;
}

.founderBox .dpWrap {
  width: 181px;
  height: 181px;
  margin: 0 auto 20px;
}

.founderBox .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.founderBox h5 {
  text-align: center;
  margin-bottom: 20px;
}

.founderBox h6 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
}

.quoteBox {
  position: relative;
  background: #fafafa;
  border-radius: 10px;
  padding: 20px 20px 20px 40px;
  margin-bottom: 30px;
}

.quoteBox p:nth-of-type(1) {
  color: #00264280;
  margin-bottom: 10px;
  padding-right: 55px;
}

.quoteBox p:nth-of-type(2) {
  color: #002642;
  margin-bottom: 0;
  text-align: right;
}

.founderBox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
}

.founderBox ul li {
  display: inline-block;
}

.founderBox ul li:nth-of-type(1) {
  margin-right: 33px;
}

.quoteBox:before {
  position: absolute;
  content: "";
  background: url("./assets/founder-quote-img.svg");
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px;
  height: 20px;
  top: 18px;
  left: 18px;
}

.founderFlex > img {
  position: absolute;
  left: -94px;
  bottom: -197px;
}

.founderSec .wrapper h6 {
  text-align: center;
  margin-bottom: 30px;
}

.founderSec .wrapper .button {
  margin: 0 auto;
  display: block;
}

.founderSec .quoteBox {
  background: #fff;
  max-width: 365px;
  margin: 0 auto 30px;
  border-radius: 10px;
}

/*-------------coins page----------*/
.coinsSec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.coinsBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.coinsSec > img {
  margin-left: -55px;
}

.coinsSec h1 {
  margin-bottom: 20px;
}

.coinsSec .right > p {
  margin-bottom: 70px;
}

.coinsBox img {
  margin-right: 30px;
}

.coinsBox p {
  margin-bottom: 0;
}

.coinsPage .coinSystemSec {
  background: #acacac1a;
}

/*-------------become an advisor page----------*/
.becomeAdvisorPage .ourMissionSec {
  background: #15a3ac;
}

.becomeAdvisorPage .ourMissionSec ul li:before {
  background: url("./assets/mission-check-white.svg");
}

.becomeAdvisorPage .ourMissionSec ul li,
.becomeAdvisorPage .ourMissionSec p,
.becomeAdvisorPage .ourMissionSec h2 {
  color: #fff;
}

.becomeAdvisorPage .ourMissionSec > img {
  position: absolute;
  left: 40px;
  top: -135px;
}

.becomeAdvisorPage .ourMissionSec {
  margin-bottom: 100px;
}

.becomeAdvisorPage .feedbackSection > div > img {
  top: -52px;
}

/*advantage section*/
.ourMissionSec {
  padding: 100px 0 70px;
  position: relative;
}

.ourMissionSec .content {
  padding: 0 215px;
  text-align: center;
}

.ourMissionSec p {
  color: #00264280;
  margin-bottom: 40px;
}

.ourMissionSec ul {
  padding: 0 60px;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ourMissionSec ul li {
  width: 45%;
  text-align: left;
  color: #aab0b7;
  position: relative;
  padding-left: 56px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.ourMissionSec ul li:before {
  content: "";
  background: url("./assets/mission-check.svg");
  position: absolute;
  top: 50%;
  left: 0;
  background-size: 36px 36px;
  width: 36px;
  height: 36px;
  transform: translate(0, -50%);
}

.oproviConcept p:nth-last-of-type(1) {
  margin-bottom: 100px;
}

.oproviConcept p {
  text-align: left;
  color: #002642;
  opacity: 0.9;
  margin-bottom: 20px;
}

.oproviConcept h2 {
  margin-bottom: 30px;
}

.advWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.advWrap .box {
  text-align: left;
  padding-left: 56px;
  position: relative;
  width: 48%;
}

.advWrap .box:before {
  content: "";
  background: url("./assets/mission-check-white.svg");
  position: absolute;
  top: -6px;
  left: 0;
  background-size: 36px 36px;
  width: 36px;
  height: 36px;
}

.advWrap .box h6 {
  font-size: 16px;
  color: #ffffff;
}

.advWrap .box p {
  color: #ffffff;
  opacity: 0.8;
}

.ourMissionSec h2 {
  margin-bottom: 40px;
}

/*-------------privacy policy page----------*/
.privacyPage .breadCrumb {
  justify-content: center;
}

.privacyPage h1 {
  text-align: center;
}

.privacyPage .updateP {
  text-align: center;
  color: #00264299;
  margin-bottom: 40px;
}

.privacyPage h4 {
  margin-bottom: 20px;
  font-weight: 600;
}

.privacyPage .privacyBox {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f3f3f3;
}

.privacyPage .privacyBox:nth-last-of-type(1) {
  border-bottom: none;
}

.privacyPage.termsPage .privacyBox {
  border-bottom: none;
  padding-bottom: 0;
}

.privacyPage h6 {
  font-weight: 400;
}

/*-------------contact page----------*/
.contactFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactFlex .right {
  padding: 40px 100px 100px 20px;
}

.contactFlex .breadCrumb {
  margin-bottom: 40px;
}

.contactFlex p a {
  color: #15a3ac;
  font-weight: 500;
}

.contactFlex h4 {
  font-weight: 600;
  margin-bottom: 20px;
}

/*-------------help page----------*/
.helpCenterWrap {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 20px;
}

.helpPage h2 {
  margin: 0 30px 28px;
}

.helpFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 0 50px;
}

.helpBox {
  width: 31%;
  align-self: stretch;
  margin-bottom: 50px;
}

.helpBox img {
  margin-bottom: 28px;
}

.helpBox h6 {
  margin-bottom: 20px;
}

.helpBox p {
  color: #00264280;
}

.helpBox p:nth-last-of-type(1) {
  margin-bottom: 0;
}

.findAnsWrap {
  background: linear-gradient(34.4deg, #15a3ac 5.4%, #3a97b4 114.34%);
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  padding: 80px 0 90px;
}

.findAnsBox {
  position: relative;
  width: 545px;
  margin: 0 auto;
  text-align: center;
}

.findAnsBox h5 {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 600;
}

.findAnsBox p {
  margin-bottom: 30px;
  color: #fff;
}

.findAnsBox .vector1 {
  position: absolute;
  left: -115px;
  bottom: -50px;
}

.findAnsBox .vector2 {
  position: absolute;
  right: -185px;
  bottom: -80px;
}

.helpModal .lgLabel {
  margin-bottom: 7px;
}

/*get started*/
.getStartWrap {
  padding: 0 30px 30px;
}

.getStartWrap > img {
  margin-bottom: 25px;
}

.getStartWrap > h6 {
  margin-bottom: 20px;
}

.getStartWrap .card {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 10px;
  margin-bottom: 20px;
  border: none;
}

.getStartWrap .card .btn {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  font-weight: 600;
  font-size: 18px;
  color: #002642;
}

.getStartWrap .card .cardBody div {
  padding: 0 40px 30px;
}

.getStartWrap .card .cardBody p {
  color: #002642;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}

.getStartWrap .card .btn .close {
  display: inline-block;
}

.getStartWrap .card .btn .open {
  display: none;
}

.getStartWrap .card .btn.collapsed .close {
  display: none;
}

.getStartWrap .card .btn.collapsed .open {
  display: inline-block;
}

/*-------------pagination----------*/
.paginationWrap .ngx-pagination {
  margin-bottom: 100px;
  padding: 0;
  text-align: center;
}

.paginationWrap .ngx-pagination .current {
  font-size: 16px;
  padding: 3px 10px;
  background-color: transparent;
  color: #15a3ac;
}

.paginationWrap .ngx-pagination li {
  margin: 0 10px;
}

.paginationWrap .ngx-pagination a {
  font-size: 16px;
  color: #002642;
}

.paginationWrap .pagination-next a,
.paginationWrap .pagination-previous a {
  padding: 0;
}

.paginationWrap .ngx-pagination .pagination-next a::after,
.paginationWrap .ngx-pagination .pagination-next.disabled::after {
  margin-left: 0;
}

.paginationWrap .ngx-pagination .pagination-previous a::before,
.paginationWrap .ngx-pagination .pagination-previous.disabled::before {
  margin-right: 0;
}

.paginationWrap .ngx-pagination .pagination-previous a:hover,
.paginationWrap .ngx-pagination .pagination-next a:hover {
  background-color: transparent;
}

.paginationWrap .ngx-pagination .pagination-next a::after,
.paginationWrap .ngx-pagination .pagination-next.disabled::after {
  content: "";
  background: url("./assets/pagination-next.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 11px;
  height: 12px;
  background-position: center;
}

.paginationWrap .ngx-pagination .pagination-previous a::before,
.paginationWrap .ngx-pagination .pagination-previous.disabled::before {
  content: "";
  background: url("./assets/pagination-prev.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 11px;
  height: 12px;
  background-position: center;
}

.paginationWrap .ngx-pagination a:focus {
  outline: none;
}

.paginationWrap .ngx-pagination .pagination-previous.disabled,
.paginationWrap .ngx-pagination .pagination-next.disabled {
  opacity: 0.5;
}

/*-------------examples view page----------*/
.myWorksWrap h3 {
  margin-bottom: 20px;
}

.exampleShareDrop2.dropdown .btn {
  width: 22px;
  height: 24px;
  background: #ffffff;
  box-shadow: 0px 20px 50px rgba(44, 36, 62, 0.2);
  border-radius: 10px;
  position: absolute;
  top: -0.8rem;
  right: -0.5rem;
}

.exampleShareDrop.dropdown .btn img {
  width: 12px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exampleShareDrop.dropdown .dropdown-menu {
  margin-top: 10px;
  left: auto !important;
  right: -15px;
  padding: 5px 8px;
  background: #ffffff;
  box-shadow: 0px 20px 50px rgba(44, 36, 62, 0.2);
  border-radius: 10px;
  border: none;
  width: max-content;
  transform: translate3d(0px, 0px, 0px) !important;
  top: 100% !important;
}

.exampleShareDrop.dropdown .dropdown-menu > div {
  display: flex;
  align-items: center;
}

.exampleShareDrop.dropdown .dropdown-menu p {
  color: #002642;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 0;
  margin-bottom: 0;
}

.exampleShareDrop.dropdown .dropdown-menu ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.exampleShareDrop.dropdown .dropdown-menu ul li {
  display: inline-block;
  margin-left: 10px;
}

.exampleShareDrop.dropdown .dropdown-menu ul li a {
  display: inline-block;
}

.exampleShareDrop.dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 22px;
  border-bottom: 8px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.exampleViewPage .userSlideTop .right .shortBtn {
  margin-right: 10px;
}

.exampleViewPage .userSlideTop .right .bookmarkBtn {
  margin-right: 25px;
}

.userSlideTop .left .infoBox {
  display: inline-flex;
  flex-direction: column;
  margin-right: 20px;
  padding: 0;
  background: transparent;
}

.userSlideTop .left .infoBox label {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #00264280;
  margin-bottom: 5px;
}

.userSlideTop .left .infoBox p {
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #002642;
  margin-bottom: 0;
  background: #f0f0f0;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 10px;
}

.userSlideTop .left .infoBox p img {
  margin-right: 5px;
}

/* .exampleViewPage .userSlideTop .left div:nth-of-type(2) {
	margin-right: 25px;
} */

.exampleViewPage {
  margin-bottom: -105px;
}

.exampleViewPage .dashboardTabContent {
  padding-bottom: 175px;
}

/*-------------upload work----------*/
.uploadWorkWrap h6 {
  margin-bottom: 10px;
}

.uploadWorkWrap .infoWrap {
  margin-bottom: 20px;
  padding: 23px;
  background: #fafafa;
  border-radius: 4px;
}

.uploadWorkWrap .infoWrap img {
  margin-right: 23px;
}

/*.infoTooltipBtn{padding:0;border:none;background:transparent;}
.infoTooltip{padding:22px!important;min-width:250px!important;}*/
.infoTooltip p {
  margin-bottom: 0;
  font-weight: normal;
  text-align: left;
  font-size: 12px;
  flex: 1 1 auto;
  line-height: 15px;
  color: #104a74;
}

.infoTooltip img {
  margin-right: 10px;
  flex-shrink: 0;
}

.infoTooltip div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.uploadWorkWrap .mat-step-icon-content span {
  display: none;
}

.uploadWorkWrap .mat-step-icon {
  margin-top: 28px !important;
  width: 8px !important;
  height: 8px !important;
  background-color: #c4c4c4 !important;
}

.uploadWorkWrap .mat-step-text-label span {
  display: block;
  font-weight: 600;
  font-size: 18px;
}

.uploadWorkWrap .mat-step-text-label {
  font-weight: 500;
  font-size: 14px;
  color: #00264233;
}

.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header
  .mat-step-label-active
  .mat-step-text-label {
  color: #002642 !important;
}

/* .uploadWorkWrap .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon-selected{width:12px!important;height:12px!important;background-color:#15A3AC!important;} */
.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header
  .mat-step-label {
  padding: 5px 0 0 0 !important;
}

.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header
  .mat-step-icon.mat-step-icon-state-edit
  .mat-icon {
  font-size: 0;
}

.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:first-child)::before,
[dir="rtl"]
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::before,
.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::after,
[dir="rtl"]
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:first-child)::after {
  width: calc(50% - 4px) !important;
}

.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header,
.uploadWorkWrap .mat-vertical-stepper-header {
  padding: 5px 10px;
  pointer-events: none;
}

.uploadWorkWrap .mat-step-header.cdk-keyboard-focused,
.uploadWorkWrap .mat-step-header.cdk-program-focused,
.uploadWorkWrap .mat-step-header:hover {
  background-color: #fff;
}

/* .uploadWorkWrap .mat-step-header[ng-reflect-active="true"] .mat-step-icon{width:12px!important;height:12px!important;background-color:#15A3AC!important;}
.uploadWorkWrap .mat-step-header[ng-reflect-active="true"]{position:relative;} */
.uploadWorkWrap .mat-step-header .mat-step-icon.mat-step-icon-state-edit,
.uploadWorkWrap .mat-step-header .mat-step-icon.mat-step-icon-selected {
  width: 12px !important;
  height: 12px !important;
  background-color: #15a3ac !important;
}

.uploadWorkWrap .mat-step-header {
  position: relative;
}

/*.uploadWorkWrap .mat-step-header[ng-reflect-active="true"]:after,.uploadWorkWrap .mat-step-header[ng-reflect-selected="true"]:before,.uploadWorkWrap .mat-step-header[ng-reflect-active="true"]:before{border-top-color:#15A3AC;}
.uploadWorkWrap .mat-step-header[ng-reflect-selected="true"]:after{border-top-color:rgba(0,0,0,.12);}*/

/* .uploadWorkWrap .mat-step-header[ng-reflect-active="true"]:not([ng-reflect-selected="true"]) .mat-stepper-horizontal-line{border-top-color:#15A3AC} */

/*.uploadWorkWrap .mat-step-header[ng-reflect-active="true"]+.mat-stepper-horizontal-line{border-top-color:#15A3AC}
.uploadWorkWrap .mat-step-header[ng-reflect-selected="true"]+.mat-stepper-horizontal-line,.uploadWorkWrap .mat-step-header .mat-stepper-horizontal-line{border-top-color:rgba(0,0,0,.12);}*/
.uploadWorkWrap
  .mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header-container {
  max-width: 560px;
  margin: 0 50px 30px 0;
}

.uploadWorkWrap .mat-horizontal-content-container {
  padding: 0;
}

.uploadWorkFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.uploadWorkFlex .right {
  max-width: 540px;
  /*padding:40px 30px 50px 20px;*/
  /*background: #FAFAFA;*/
  /*border-radius: 10px;*/
  align-self: stretch;
  flex: 1 1 auto;
}

.uploadWorkFlex .left {
  flex: 0 0 560px;
  background-color: #fff;
  align-self: stretch;
}

/*step1 upload work*/
.infoTooltip .tooltip {
  background-color: #fff;
  /* width: 100%; */
}

.infoTooltip .transBtn img {
  margin-right: 0;
  width: 16px;
  height: 16px;
  opacity: 1;
}

.infoTooltip .tooltip-inner {
  background-color: #fff;
  border-radius: 10px;
  min-width: 362px;
  padding: 20px;
  box-shadow: 0px 5px 10px rgb(0 19 20 / 5%);
}

.infoTooltip .arrow::before {
  border-top-color: #fff;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltipInputWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 460px;
}

.tooltipInputWrap .inputWrap {
  min-width: 460px;
  flex-shrink: 0;
  max-width: 460px;
}

.tooltipInputWrap .infoTooltip {
  margin: 0 0 20px 20px;
}

.uploadBtnGroup {
  margin-top: 20px;
}

/* .uploadBtnGroup .button {
  margin-right: 20px;
} */

.uploadExWrap {
  background: #fafafa;
  border-radius: 10px;
  padding: 40px 28px 50px 60px;
}

.uploadExWrap > h6 {
  margin-bottom: 20px;
}

.uploadExWrap > p {
  margin-bottom: 30px;
}

.tabBlock .nav-tabs {
  border-bottom: none;
}

.tabBlock .nav-tabs li {
}

.tabBlock .nav-tabs li a {
  padding: 8px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #00264280;
  border-radius: 10px 10px 0px 0px;
  display: inline-block;
}

.tabBlock .nav-tabs li a.active {
  color: #002642;
  background: #ffffff;
  box-shadow: 0px -2px 13px 1px rgb(8 86 77 / 6%);
}

.tabBlock .tab-pane {
  background: #fff;
  border-radius: 0 10px 10px 10px;
  padding: 20px 30px 40px 20px;
}

.uploadSlider {
  padding-bottom: 30px;
}

.uploadSlider .carousel-indicators {
  margin: 0;
}

.uploadSlider .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c4c4c4;
  border: none;
  opacity: 1;
}

.uploadSlider .carousel-indicators li.active {
  background: #32abb6;
}

.tabBlock {
  margin-bottom: 30px;
  position: relative;
}

.tabBlock .tabNo {
  position: absolute;
  left: -40px;
  color: #15a3ac;
  font-weight: 600;
}

.uploadPageWrap .tagsFlex p {
  padding: 10px 20px 10px 20px;
  font-size: 12px;
  font-weight: 600;
}

.uploadPageWrap .tooltipInputWrap .infoTooltip {
  margin: 0 0 10px 20px;
}

.uploadPageWrap .tagsFlex {
  margin-bottom: 40px;
}

.snapshotFlex {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.snapshotFlex .box {
  position: relative;
  padding-right: 20px;
  margin: 0 20px 20px 0;
}

.snapshotFlex .box > img {
  width: 72px;
  height: 60px;
}

.snapshotFlex .close {
  right: 0;
  top: 0;
}

.uploadWorkFlex .drag-text {
  padding: 70px 0 80px;
}
/* upload work fk css  */

.dragSubSec {
  /* padding:30px 20px 32px 15px; */
  padding: 10px 20px 0px 15px;
  margin-bottom: -15px;
  position: relative;
}

.dragSubSec p {
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 8%;
}
.dragSubSec p::before {
  content: "";
  width: 40.567%;
  height: 1px;
  background: #c1c1c1;
  display: block;
}
.dragSubSec p::after {
  content: "";
  width: 40.567%;
  height: 1px;
  background: #c1c1c1;
  display: block;
}
.dragSubSec .inputWrap {
  max-width: 100% !important;
  min-width: auto !important;
}

/* upload work fk css end  */

.multiUploadsWrap {
  position: relative;
  background: #fafafa;
  border: 0.5px dashed #dbdbdb;
  max-width: 460px;
  border-radius: 4px;
  padding: 20px;
}

.multiUploadsWrap > p {
  font-weight: 400;
  font-size: 10px;
  color: #00264280;
  margin-bottom: 20px;
  text-align: center;
}

.multiUploadFlex {
  margin: 0 -6px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.multiUpload {
  width: 22.2%;
  margin: 0 6px 20px;
}

.multiUpload .imgWrap {
  margin-bottom: 5px;
  background: #e8e8e8;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.multiUpload .imgWrap img {
  max-width: 90%;
  max-height: 90%;
}

.multiUpload .btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.multiUpload .btnGroup .transBtn {
  margin: 0 5px;
  line-height: 1;
}

.multiUpload p {
  font-weight: 400;
  font-size: 10px;
  color: #002642;
  margin-bottom: 0;
  line-height: 13px;
  text-align: center;
}

/* no money modal */

/* .preciousModalWrap.moneyModalWrap .modal-content{padding:30px 60px 70px 60px;background:url('./assets/congrats-signin-bg.svg');background-repeat:no-repeat;background-position:bottom;background-size:contain;border-radius:10px;box-shadow:0px 3px 8px rgb(8 86 77 / 10%);border-radius:10px;background-color:#fff;}
.preciousModalWrap.moneyModalWrap p{font-weight:normal;font-size:24px;opacity:0.8;max-width:518px;margin:0 auto 30px;line-height:30px;}
.moneyModalWrap .preciousModal h3{margin-bottom:20px;}
.preciousModalWrap .modal-dialog {
    max-width: 802px;
    width: 802px;
} */

.moneyModalWrap .modal-content {
  padding: 50px 60px;
  background: url("./assets/no-money-modal-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgb(3 91 88 / 10%);
  box-shadow: 0px 3px 8px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
}

.moneyModalWrap .modal-dialog {
  max-width: 665px;
  width: 665px;
}

.moneyModal {
  max-width: 365px;
  margin: 0 auto;
}

.moneyModal h2 {
  text-align: center;
}

.moneyModal p {
  color: #15a3ac;
  text-align: center;
  max-width: 210px;
  margin: 0 auto 20px;
}

.moneyModal .greenBtn {
  margin: 0 auto;
  display: block;
}

/* .uploadWorkFlex .drag-text .fileUpIcon{width: 14px; } */

/*file upload global*/
.file-upload {
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto 5px;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
  max-width: 20px;
}

.content-upload {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

button.button.transBtn.chatFileBtn {
  max-width: 20px;
  overflow: hidden;
}
input#file-upload-button {
  background: red;
  overflow: hidden;
  max-width: 10px;
}
/* input.file-upload-input {
	background: red;
	overflow: hidden;
	max-width: 10px;
}  */

.image-upload-wrap {
  position: relative;
  background: #fafafa;
  border: 0.5px dashed #dbdbdb;
  border-radius: 3px;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #ccc;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
  padding: 35px 0 30px;
}

.drag-text img.cloudIcon {
  margin-bottom: 5px !important;
  width: 30px !important;
}
.dragSubSec img.cloudIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px !important;
  z-index: 9;
  background: #fff;
}
.dragSubSec .form-control {
  padding-right: 50px !important;
}

.drag-text h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #002642;
}

.drag-text h3 span {
  color: #15a3ac;
  text-decoration: underline;
}

.drag-text .fileUpIcon,
.multiUploadsWrap .fileUpIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload + .suggestP {
  color: #002642;
}

/*step2 add advisor*/
.addAdvisorTab .customSlider {
  max-width: 460px;
}

.addAdvisorTab .pricePkgCheckbox {
  margin-left: 0;
}

.advisorTabs .nav-pills {
  margin-bottom: 40px;
}

.advisorTabs .nav-pills li {
}

.advisorTabs .nav-pills li:nth-of-type(1) {
  margin-right: 50px;
}

.advisorTabs .nav-pills li a {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #00264280;
  position: relative;
}

.advisorTabs .nav-pills li a.active {
  color: #15a3ac;
}

.advisorTabs .nav-pills li a.active:before {
  position: absolute;
  bottom: -10px;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 2px;
  background-color: #15a3ac;
  content: "";
}

.advisorTabs {
  margin-bottom: 120px;
}

.uploadWorkSum {
  max-width: 535px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 10px;
  padding: 40px 20px 20px;
}

.uploadWorkSum .table thead th {
  vertical-align: middle;
  border-bottom: none;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
}

.uploadWorkSum .table thead th span {
  font-size: 8px;
  display: block;
  margin: 0;
  line-height: 11px;
}

.uploadWorkSum .table td,
.uploadWorkSum .table th {
  text-align: left;
  padding: 5px;
  border-top: none;
}

.uploadWorkSum .table td {
  font-size: 14px;
  color: #002642;
  vertical-align: middle;
  font-weight: 700;
}

.uploadWorkSum .table td b {
  font-weight: 400;
}

.uploadWorkSum .table td:nth-of-type(2) {
  width: 70px;
  font-size: 10px;
}

.advisorCell img {
  border-radius: 4px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
}

.uploadWorkSum .table label {
  border: 1px solid #cccccc;
  color: #ccc;
  font-size: 10px;
  border-radius: 3px;
  padding: 2px 10px;
}

.uploadWorkSum .table {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
}

.uploadWorkSum .total {
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadWorkSum .total p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #002642;
}

.uploadWorkSum .total p:nth-last-of-type(1) {
  color: #15a3ac;
  min-width: 60px;
}

.commssionWrap {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 12px;
}

.commssionWrap .total {
  margin-bottom: 12px;
}

.commssionWrap .checkboxWrap input {
  margin: 3px 12px 0 0;
}

.infoTooltip.commisionTooltip p span {
  display: block;
  margin-bottom: 10px;
}

.infoTooltip.commisionTooltip p span:nth-last-of-type(1) {
  margin-bottom: 0;
}

.uploadWorkSum span {
  font-size: 10px;
  margin-left: 5px;
}

.uploadWorkFlex .right .uploadWorkSum {
  margin-left: auto;
}

.noAdvisorBox {
  background: #fafafa;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 20px 30px 30px;
  text-align: center;
}

.noAdvisorBox img {
  margin-bottom: 20px;
}

.noAdvisorBox h6 {
  margin-bottom: 10px;
}

.noAdvisorBox p {
  color: #00264280;
  max-width: 365px;
  margin: 0 auto;
}

.uploadWorkCheckout .dicoverAdFlex {
  margin-bottom: 30px;
}

.discoverAdBox.smDiscoverAdBox {
  width: 18.2%;
  padding: 20px 10px 7px;
  cursor: pointer;
}

.discoverAdBox.smDiscoverAdBox .dpWrap img {
  border-radius: 4px;
}

.discoverAdBox.smDiscoverAdBox .dpWrap {
  width: 40px;
  height: 40px;
}

.discoverAdBox.smDiscoverAdBox .dpWrap .badgeIcon {
  width: 16px;
  height: 16px;
  border-radius: 0;
}

label.SelectedLabel {
  border: 1px solid #002642;
  font-size: 10px;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 1px 12px;
}

.discoverAdBox.smDiscoverAdBox h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0px;
}

.discoverAdBox.smDiscoverAdBox p {
  font-size: 8px;
  line-height: 11px;
  color: #002642;
  margin-bottom: 10px;
  opacity: 0.5;
}

.discoverAdBox.smDiscoverAdBox .advisorFigures {
  background: #f0f0f0;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
}

.discoverAdBox.smDiscoverAdBox .advisorFigures > div:nth-of-type(1) {
  padding-right: 25px;
}

.discoverAdBox.smDiscoverAdBox .advisorFigures div h6:nth-of-type(1) {
  opacity: 0.5;
  margin-bottom: 2px;
}

.discoverAdBox.smDiscoverAdBox .advisorFigures h6:nth-of-type(2) {
  font-size: 12px;
  margin-bottom: 0px;
}

.discoverAdBox.smDiscoverAdBox .button {
  font-size: 12px;
  font-weight: 400;
}

.discoverAdBox.smDiscoverAdBox .advisorFigures > div:after {
  bottom: -3px;
  right: 10px;
}

.discoverAdBox.selected {
  border: 1px solid #15a3ac;
}

.uploadWorkFlex .left .discoverAdBox.smDiscoverAdBox {
  width: 28.1%;
}

.uploadWorkFlex .left .discoverAdBox.smDiscoverAdBox.advisorSelected {
  border: 1px solid #15a3ac;
}

.custom-slider .ngx-slider .ngx-slider-bar {
  background: #15a3ac;
  height: 6px;
}

.custom-slider .ngx-slider .ngx-slider-selection {
  background: #f4f2f6;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 8px;
  height: 16px;
  top: -18px;
  bottom: 0;
  background-color: transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-left: -1px;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.custom-slider .ngx-slider .ngx-slider-limit {
  font-weight: 400;
  color: orange;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: #15a3ac;
  top: -1px;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: #f4f2f6;
}

.mainTabPages .tabContentWrap {
  padding: 20px 15px;
}

.mainTabPages .accountSettings {
  padding: 0;
}

.uploadContTabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.uploadContTabs > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.uploadContTabs > div button {
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.uploadContTabs > div button img {
  margin-left: 5px;
}

/* .uploadWorkFlex .discoverAdBox {
  width: 47.5%;
} */

.uploadWorkFlex .discoverAdBox.selected {
  border: 1.5px solid #15a3ac;
}

.uploadWorkFlex .dicoverAdFlex {
  /* max-height: 1025px; */
  /* overflow-y: auto; */
}

.uploadWorkFlex.step2 .right .uploadBtnGroup {
  display: none;
}

.upAdBtnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

/*upload work success*/
.uploadSuccessWrap {
  max-width: 431px;
  margin: 25px auto 60px;
}

.uploadSuccessWrap > img {
  margin: 0 auto 10px;
  display: block;
}

.uploadSuccessWrap h6 {
  text-align: center;
  margin-bottom: 20px;
}

.uploadSuccessWrap > p {
  margin: 0 auto 20px;
  max-width: 365px;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .uploadSuccessWrap > p {
    max-width: 320px;
  }
}

.uploadSuccessWrap .emailsWrap {
}

.uploadSuccessWrap .emailsWrap p {
  display: inline-block;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 3px 5px rgb(8 86 77 / 10%);
  border-radius: 65px;
  padding: 10px 20px;
  margin-right: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #002642;
}

.uploadSuccessWrap .emailsWrap p button {
  margin-left: 10px;
}

.uploadSuccessWrap .emailsWrap p button:hover {
  opacity: 0.5;
}

/*--------------------------MY works----------------------*/
.myWorksTopFlex {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.myWorksTopFlex .box {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(8, 86, 77, 0.1);
  border-radius: 10px;
  width: 32.5%;
  padding: 10px;
  text-align: center;
  position: relative;
}

.myWorksTopFlex .box:nth-of-type(1) {
  color: #15a3ac;
}

.myWorksTopFlex .box:nth-of-type(2) {
  color: #f39237;
}

.myWorksTopFlex .box:nth-of-type(3) {
  color: #840032;
}

.myWorksTopFlex .box h5,
.myWorksTopFlex .box h6 {
  color: inherit;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 3px;
}

.myWorksTopFlex .box h6 {
  opacity: 1;
}

.myWorksTopFlex .box p {
  color: inherit;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
}

.myWorksTopFlex .infoTooltip {
  position: absolute;
  top: 20px;
  right: 25px;
  z-index: 9;
}
.myWorksTopFlex .infoTooltip .tooltip-inner {
  color: #041029 !important;
  position: absolute;
  right: -40px;
  /* top:30px; */
}
.myWorksTopFlex .arrow {
  left: calc(50% - 5rem) !important;
  display: none;
}

.myworksTabsFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workTabTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*available toggle*/
.availableToggle {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
}

.availableToggle .box {
  width: 50%;
}

.availableToggle .box label {
  height: 47px;
  padding: 0;
  margin-bottom: 0;
}

.availableToggle .box input {
  width: 100%;
  height: 47px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.availableToggle .box label > span {
  z-index: 9;
  width: 100%;
  height: 47px;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #00264233;
  background: #fff;
  border: 1px solid #00264233;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
}

.availableToggle .box:nth-of-type(1) label > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.availableToggle .box:nth-of-type(2) label > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.availableToggle .box input:checked + span {
  border: 1px solid #15a3ac;
  color: #fff;
  background: #15a3ac;
  font-weight: 600;
}

/*new expire toggle*/
.workExpire {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.workExpire p {
  font-weight: normal;
  font-size: 12px;
  color: #002642;
  margin-bottom: 15px;
  margin-right: 15px;
}

.workExpire .availableToggle .box label > span {
  border: 1px solid #15a3ac;
  color: #002642;
}

.workExpire .availableToggle .box input:checked + span {
  color: #fff;
}

/*advisor empty*/
.advisorEmpty {
  background: #fafafa;
  border-radius: 4px;
  padding: 30px 20px 80px;
  text-align: center;
}

.advisorEmptyFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advisorEmptyFlex .box {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 4px;
  padding: 20px;
  width: 32.2%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.advisorEmpty h4 {
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 10px;
}

.advisorEmpty p {
  color: #00264280;
}

.advisorEmptyFlex .box h6 {
  line-height: 23px;
  margin-bottom: 10px;
}

.advisorEmptyFlex .box h6 span {
  color: #15a3ac;
}

.advisorEmptyFlex .box p {
  margin-bottom: 0;
}

/*user empty*/
.userEmpty {
  background: #fafafa;
  border-radius: 4px;
  padding: 50px;
  text-align: center;
}

.userEmpty img {
  margin-bottom: 20px;
}

.userEmpty h4 {
  max-width: 400px;
  margin: 0 auto 20px;
  font-weight: 700;
}

.userEmpty p {
  font-weight: 600;
  margin-bottom: 0;
}

.userEmpty p a {
  color: #15a3ac;
  text-decoration: underline;
}

.userEmpty p a:hover {
  opacity: 0.8;
}

/*advisor new*/
.myWorkBox {
  background: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 4px;
  padding: 0 20px;
  margin-bottom: 5px;
}

.myWorkBox .dropdown button {
  padding: 0;
  line-height: 1;
}

.myWorkBox.new {
  border: 1px solid #f39237;
}

.myWorkFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.myWorkFlex:nth-of-type(1) {
  border-bottom: 1px solid #ccc;
}

.myWorkFlex h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.myWorkFlex > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.myWorkFlex > div .dropdown .btn {
  line-height: 1;
}

.myWorkFlex > div .dropdown {
  margin-left: 15px;
}

.myWorkFlex > div p.date {
  font-size: 10px;
  /* color: #00264280; */
  margin-bottom: 0;
  font-weight: 600;

  font-weight: bold !important;

  background: linear-gradient(to right, #00264280 55%, #000 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.myWorkFlex label.newLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #f39237;
  padding: 0 5px;
  border: 1px solid #f39237;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 20px;
  margin-bottom: 0;
}

.myWorkFlex .applyBtn {
  font-weight: normal;
  font-size: 14px;
  margin: 0 18px;
}

.myWorkFlex .dropdown-menu {
  top: 100% !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
}

.myWorkFlex .workInfo {
  font-weight: 600;
  margin-right: 35px;
  font-size: 12px;
  line-height: 15px;
  color: #002642;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.myWorkFlex .workInfo label {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-right: 5px;
  margin-bottom: 0;
}

.myWorkFlex .workInfo p {
  color: #00264280;
  font-size: inherit;
  font-weight: inherit;
  margin-right: 5px;
  margin-bottom: 0;
}

.myWorkFlex .workInfo span {
  display: inline-block;
  color: #15a3ac;
  font-size: inherit;
  font-weight: inherit;
  margin-right: 5px;
}

.myWorkFlex .workInfo img {
  margin-right: 5px;
}

.myWorkFlex .workInfo img.dp {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.myWorkFlex .workInfo .imgWrap {
  position: relative;
  padding-left: 15px;
}

.myWorkFlex .workInfo .imgWrap button {
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  position: absolute;
  left: 3px;
}

.myWorkFlex .workInfo .imgWrap button span {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.myWorkFlex .workInfo .imgWrap button:hover {
  background: #f39237;
  opacity: 1;
  border-color: #f39237;
}

.myWorkFlex .detailBtn {
  padding: 7px 18px;
  margin-left: 20px;
  font-weight: normal;
  font-size: 14px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myWorkFlex .detailBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.myWorkFlex .detailBtn img {
  margin: 0 10px !important;
}

.myWorkFlex .detailBtn:nth-last-of-type(2) img {
  margin: 0;
}

.myWorkFlex .workInfo:nth-last-of-type(1) {
  margin-right: 0;
}

.archiveTab {
  color: #002642;
}

.archiveTab.active {
  font-weight: 600;
  color: #15a3ac;
}

.myWorkFlex .workInfo .count-down {
  margin-left: 0;
}

/*counter*/
.count-down {
  margin-left: 20px;
  display: inline-block;
}

.count-down span {
  display: inline-block;
}

.count-down .item {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff !important;
  padding: 6px 4px;
  background: #15a3ac;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 4px;
  position: relative;
  margin-right: 10px !important;
}

.count-down .item:after {
  content: ":";
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translate(0, -50%);
  color: #000;
}

.count-down .item:nth-last-of-type(1):after {
  display: none;
}

/* .lessTime .count-down .item{background:#F39237;} */
.orange.count-down .item {
  background: #f39237;
}

.maroon.count-down .item {
  background: #840032;
}

/*saved checkbox*/
.userRightWork {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
}

.savedCheck {
}

.savedCheck {
  width: 121px;
  margin-right: 20px;
}

.savedCheck label {
  width: 121px;
  height: 56px;
  padding: 0;
  margin-bottom: 0;
}

.savedCheck input {
  width: 100%;
  height: 56px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.savedCheck label > span {
  z-index: 9;
  width: 121px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #15a3ac;
}

.savedCheck input:checked + span {
  background: #ffffff;
  border: 1px solid #15a3ac;
  border-radius: 4px;
}

.feedbackBtn {
  font-weight: 600;
  font-size: 12px;
  color: #f39237;
  margin-left: 20px;
}

/*user saved work*/
.myWorkBoxSaved .myWorkFlex:nth-of-type(1) {
  border-bottom: none;
}

.myWorkBoxSaved.myWorkBox {
  background: #c4c4c433;
}

.myWorkBoxSaved.myWorkBox h6 {
  margin-right: 30px;
}

.myWorkBoxSaved.myWorkBox .myWorkFlex .workInfo {
  margin-right: 25px;
}

.myWorkBoxSaved.myWorkBox .myWorkFlex .detailBtn {
  background: transparent;
}

/*--------------------------Messages----------------------*/
.msgSecWrap {
  /*height:calc(100vh - 128px);*/
  height: -webkit-fill-available;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.msgSecWrap .left {
  width: 380px;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.msgSecWrap .left app-chat-list {
  height: 100%;
}

.msgSecWrap .right {
  align-self: stretch;
  flex: 1 1 auto;
}

.msgSecWrap .left .searchWrap {
  position: relative;
  min-width: 100%;
  flex-shrink: 0;
}

.msgSecWrap .left .searchWrap input {
  border: 1px solid #00264233;
  border-radius: 7px;
  height: 51px;
  padding-left: 45px;
}

.msgSecWrap .left .searchWrap img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.noConvoWrap {
  height: calc(100% - 51px);
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f3f3;
  border-radius: 7px;
  padding: 45px;
}

.noConvoWrap p {
  font-weight: 600;
  text-align: center;
  list-style: 20px;
  color: #002642;
}

.noChatWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f3f3;
  border-radius: 7px;
  padding: 45px;
  flex-direction: column;
  height: 100%;
}

.noChatWrap img {
  margin-bottom: 30px;
}

.convoBox {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding: 20px;
  cursor: pointer;
}

/* .msgCounter {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translate(0, -50%);
	background: #f39237;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	color: #fff;
	display: inline-flex;
	justify-content: center;
	align-items: center;
} */
.msgCounter {
  position: absolute;
  top: 35%;
  left: 35px;
  transform: translate(0, -50%);
  background: #15a3ac;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.convoBox .msgCounter {
  left: 20px !important;
}
.dashboardWrap .sideNav li a.active .msgCounter {
  color: #15a3ac;
  background: #fff;
}
.convoBox .dpWrap {
  flex-shrink: 0;
  margin-right: 30px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
}

.convoBox .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.convoBox h6 {
  font-size: 16px;
  margin-bottom: 8px;
}

.convoBox p {
  margin-bottom: 0;
  color: #00264280;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.convoWrap {
  height: calc(100% - 51px);
  overflow-y: scroll;
}

.convoBox.active,
.convoBox.active:hover {
  background: #15a3ac;
}

.convoBox.active h6 {
  color: #fff;
}

.convoBox.active p {
  color: #ffffff80;
}

.convoBox .dpWrap.active:before {
  position: absolute;
  background: #15a3ac;
  border: 2px solid #ffffff;
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 50%;
  right: 1px;
  top: -1px;
}

.convoBox:hover {
  background: #f7f7f7;
}

.convoTop {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.convoTop > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.convoHead {
  height: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  background: #fafafa;
}

.convoTop {
  padding: 20px;
  border-bottom: 1px solid #f3f3f3;
}

.convoTop .convoBox {
  padding: 0;
  border: none;
}

.convoTop .convoBox:hover {
  background: transparent;
}

.convoTop .reportBtn {
  padding: 0;
  border: none;
  color: #840032;
  margin-right: 20px;
}

.convoTop .dropdown #dropdownMenuButton {
  padding: 0;
}

.convoTop .dropdown {
  margin-left: 25px;
}

.convoTop .dropdown .dropdown-menu {
  top: 100% !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
}

.convoBottom {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding: 20px;
  position: relative;
}

.convoBottom input {
  border: none;
  padding: 0;
  height: 45px;
  padding-right: 170px;
}

.convoBottom .transBtn {
  margin-right: 15px;
}

.convoBottom .greenBtn {
  height: 45px;
  line-height: 45px;
  font-weight: 400;
}

.convoBottom > div {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

.chatWrap {
  height: calc(100% - 207.19px);
  overflow-y: scroll;
  background: #fafafa;
}

.chatFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.chatFlex .dpWrap {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 20px 50px;
}

.chatFlex .dpWrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chatFlex .msgWrap {
  padding: 20px 15px;
  border-radius: 10px;
  width: 330px;
  margin: 10px 0;
}

.chatFlex .msgWrap p {
  margin-bottom: 0;
  color: #002642;
  line-height: 20px;
  word-wrap: break-word;
}

.chatFlex.receiver .msgWrap {
  background: #8787871a;
  border-bottom-left-radius: 0;
}

.chatFlex.sender .msgWrap {
  background: #f392371a;
  border-bottom-right-radius: 0;
}

.chatFlex.sender {
  justify-content: flex-end;
}

.chatFlex.sender p.time {
  text-align: right;
}

.fileSentWrap {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fileSentWrap > img,
.fileSentWrap div {
  margin-right: 20px;
}

.fileSentWrap div span {
  font-size: 12px;
  line-height: 16px;
}

.chatCompleted {
  padding: 5px 25px;
  text-align: right;
  border-radius: 6px;
  border-bottom: 1px solid #f3f3f3;
  background: #fff;
}

.chatCompleted .dropdown {
  display: inline-block;
}

.chatCompleted .dropdown .button {
  font-weight: 400;
  font-size: 14px;
}

.chatCompleted .dropdown .button img {
  margin-left: 5px;
}

.chatCompleted .dropdown-item label {
  background: #15a3ac;
  border-radius: 41px;
  color: #fff;
  padding: 2px 10px;
  margin-left: 20px;
  margin-bottom: 0;
}

.chatCompleted .dropdown-menu {
  min-width: 286px;
  left: auto !important;
  padding: 0;
  right: 0;
}

.chatCompleted .dropdown-item {
  margin: 0 20px;
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
  display: block;
  width: auto;
  font-size: 16px;
  font-weight: 400;
}

.chatCompleted .dropdown-item:nth-last-of-type(1) {
  border-bottom: none;
}

/*----------------My Portfolio-------------*/
.archiveExamples {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.archiveExamples img {
  margin-bottom: 30px;
}

/*----------------Notification screens-------------*/
.notifyScreen {
  min-height: 100vh;
  background: #fafafa;
  padding-bottom: 50px;
}

.notifyScreen .breadCrumb {
  margin-top: 40px;
}

.notifyitem > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15%;
  cursor: pointer;
}

.notifyitem img {
  margin-right: 5px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 5px;
}

p.dayTitle {
  padding: 0 15%;
  margin-bottom: 5px;
  opacity: 0.2;
  color: #002642;
  margin-top: 25px;
}

.notifyitem > div > div:nth-of-type(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
}

.notifyitem h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.notifyitem > div p:nth-of-type(1) {
  padding-left: 30px;
  width: 62%;
}

.notifyitem > div p:nth-of-type(2) {
  padding-left: 20px;
}

.notifyitem > div p {
  color: #002642;
  opacity: 0.5;
  margin-bottom: 0;
}

.notifyitem > div label.new {
  margin-left: auto;
  margin-bottom: 0;
}

.notifyitem {
  margin-bottom: 5px;
  background: #fff;
}

.notifyitem.read {
  background: transparent;
}

.notifyPageScroll {
  max-height: 70vh;
  overflow-y: auto;
}

.notifyitem.new {
  background: #f39237;
}

.notifyitem.new h6 {
  color: #fff;
}

.notifyitem.new > div p {
  color: #fff;
  opacity: 0.5;
}

.notifyitem > div label.new {
  color: #fff;
  border-color: #fff;
}

/* recommend friend modal */
.recommendModalWrap .modal-dialog {
  max-width: 530px;
  width: 530px;
}

/* .recommendModalWrap .modal-content{padding: 30px 50px;} */
.recommendModal > img {
  margin: 0 auto 20px;
  display: block;
}

.recommendModal h5 {
  text-align: center;
  margin-bottom: 25px;
}

.recommendModal .content > p {
  text-align: center;
  color: #002642;
}

.recommendModal .content label {
  color: #002642;
}

.recommendModal .tagsFlex p {
  font-weight: 600;
  font-size: 12px;
  color: #002642;
  line-height: 15px;
}

.recommendModal .tagsFlex {
  margin-bottom: 40px;
}

.recommendModal .btn-group {
  display: flex;
  justify-content: center;
}

.recommendModal ul {
  padding: 0;
  margin: 0 0 15px;
  list-style-type: none;
}

.recommendModal ul li {
  color: #002642;
  opacity: 0.5;
}

.recommendModal.modalWrap .btn-group .button {
  font-size: 14px;
}

/*----------------Toasters-------------*/
.toastWrap {
  z-index: 99;
  background: #ffffff;
  border: 1px solid #08564d12;
  box-shadow: 0px 63px 50px rgba(8, 86, 77, 0.0705882);
  border-radius: 20px;
  padding: 30px 50px;
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 790px;
  max-width: 790px;
  /*display:flex;justify-content:space-between;align-items:center;*/
}

.closeToast {
  line-height: 1;
  position: absolute;
  top: 15px;
  right: 20px;
  background: transparent;
  padding: 0;
  border: none;
}

.toastWrap .closeToast img {
  margin-right: 0;
}

.toastWrap .button {
  margin-left: 30px;
}

.toastWrap > div:nth-of-type(1) {
  flex-shrink: 0;
  margin-right: 20px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.toastWrap img {
  margin-right: 20px;
}

.toastWrap p {
  margin-bottom: 0;
}

.toastWrap .whiteBtn:hover {
  background: #15a3ac;
  color: #fff;
}

.toastHead {
  flex-shrink: 0;
}

/*--------------------------My portfolio----------------------*/
.accountSettings.portfolioTabs {
  padding: 20px 10px 40px;
}

.portfolioExamples {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.portfolioExamples .exampleBox {
}

/*--------------------------increase exposure screen----------------------*/
.exposureFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -15px;
}

.expoCard {
  min-height: 125px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 4px;
  text-align: center;
  margin: 0 15px 40px;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 31%;
}

.exposureScreen h6 {
  margin-bottom: 20px;
}

.expoCard p.lgP {
  line-height: 20px;
  color: #15a3ac;
  font-weight: 600;
  margin-bottom: 5px;
  max-width: 200px;
}

.expoCard p:not(.lgP) {
  max-width: 200px;
  color: #00264280;
  margin: 0 auto 5px;
  font-size: 12px;
  line-height: 17px;
}

.expoCard p .expLabel {
  font-size: 10px;
  color: #002642;
}

.expoCard a {
  color: #00264280;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.increaseCard {
  min-height: 125px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(8, 86, 77, 0.05);
  border-radius: 4px;
  text-align: center;
  margin: 0 15px 40px;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.increaseCard p {
  margin-bottom: 10px;
}

.increaseCard p:nth-last-of-type(1) {
  margin-bottom: 0;
}

.increaseCard h6 {
  margin-bottom: 10px;
}

.advSlider {
  position: relative;
}
.advSlider .popUpSec {
  width: 238px;
  height: 70px;
  position: absolute;
  padding: 26px 20px;
  text-align: center;
  top: 0;
  left: 20px;
  background: #fff !important;
}
.advSlider .popUpSec {
  width: 238px;
  height: 70px;
  position: absolute;
  padding: 26px 10px;
  text-align: center;
  top: -60px;
  right: 0px;
  background: #fff !important;
  left: unset;
  border-radius: 10px;
  /* box-shadow: 2px 9px 13px -4px rgba(120,94,94,0.64);
-webkit-box-shadow: 2px 9px 13px -4px rgba(120,94,94,0.64);
-moz-box-shadow: 2px 9px 13px -4px rgba(120,94,94,0.64); */
  box-shadow: 2px 10px 13px -4px rgba(179, 175, 175, 0.64);
  -webkit-box-shadow: 2px 10px 13px -4px rgba(179, 175, 175, 0.64);
  -moz-box-shadow: 2px 10px 13px -4px rgba(179, 175, 175, 0.64);
}
.advSlider .popUpSec img {
  margin-right: 7px;
}
.advSlider .popUpSec p {
  font-size: 12px;
}
.couponSec p a {
  color: #15a3ac;
  margin: 0 10px;
}
.sndMsgBtnForMob {
  display: none !important;
}
.applyBtnWeb {
  display: none !important;
}
.notfTextMob {
  display: none;
}
.ongTabUploadBtn {
  display: none !important;
}
.primeButton {
  /* display: none; */
}
.advisorProfTop.adivsorTopOne .rating {
  display: none;
}
#favorite .uploadWorkSum {
  display: none;
}
.tableForMob {
  display: none;
}
.btnGroup .reportBtn {
  display: none !important;
}
.uploadWorkFlex.step2 {
  width: 100% !important;
}
.uploadWorkFlex.step2 .left {
  flex: 0 0 1;
  background-color: #fff;
  align-self: stretch;
  width: 100% !important;
  flex: unset;
}
.uploadWorkFlex.step2 .uploadContTabs > div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.uploadWorkFlex.step2 .uploadContTabs {
  align-items: baseline;
  margin-bottom: -15px;
}
.uploadWorkFlex.step2 .advisorTabs .nav-pills li:nth-of-type(1) {
  margin-right: 0px;
}
/* .uploadWorkFlex.step2 .discoverAdBox {
  width: 23.5%;
} */
.uploadWorkFlex.step2 .discoverAdBox .shortBtn {
  width: 94%;

  max-width: inherit;
}
.goCheckBtn {
  background: transparent;
  color: #15a3ac;
  border: 1px solid #15a3ac;
  border-radius: 23px;
  font-weight: 400;
  height: 30px;
  font-size: 14px;
}

.advisorTabs .nav-pills li:nth-of-type(1) {
}
.advisorTabs .nav-pills li a.active:before {
  display: none;
}
.advisorTabs .nav-pills li a.active {
  box-shadow: 0px 5px 10px 0px #08564d1a;
  color: #15a3ac !important;
  background: transparent;
}
.advisorTabs .nav-pills li a {
  border: 1px solid #f3f3f3;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  display: block;
  background: #eceeef;
}
.advisorTabs .nav-pills li {
}
.uploadWorkFlex.step2 .advisorTabs .nav-pills {
  /* margin-bottom: 0px; */
}
div#recommended {
  margin-bottom: 50px;
  margin-top: -45px;
}
.addFavText {
  font-size: 18px;
  margin: 0 3px;
  line-height: 25px;
}
.advtextForMob {
  display: none;
}
.guidModLiImgsec {
  display: flex;
  column-gap: 16px;
  align-items: center;
  margin: 7px 0px;
}
.gotItBtn {
  width: 159px;
}
.guideLineModMain .annotationFlex {
  border: none;
}
.guideLineModMain .content {
  padding-right: 35px;
  /* overflow-y: scroll; */
  flex: 1 1 auto;
  margin-bottom: 30px;
  max-height: 320px;
}
/*-------------responsiveness----------*/

.applyBtn {
  display: none;
}
.seeAllBtnMob {
  display: none !important;
}
.dsCheckBoxSec label {
  font-size: 16px;
  margin: 0 5px;
}

.moreInfoText {
  display: none;
}
.moreLessText {
  display: none;
}
.dsCheckBoxSec {
  display: flex;
}
/*@media (min-width: 992px){
.ng-tns-c117-0{position: relative;}
.p-sidebar{position: relative !important; opacity: 1;}

}*/

.ddFilterWrap {
  display: flex !important;
}

@media (max-width: 1400px) {
  .toastWrap {
    min-width: 600px;
    max-width: 600px;
    padding: 25px 20px;
  }

  .toastWrap img {
    margin-right: 10px;
    width: 25px;
  }

  .toastWrap .closeToast img {
    width: auto;
  }

  .toastWrap p.lgP {
    font-size: 14px;
    line-height: 20px;
  }

  .toastWrap .heightBtn {
    padding: 10px 25px;
    height: 45px;
  }

  .closeToast {
    top: 7px;
    right: 15px;
  }

  .toastWrap > div:nth-of-type(1) {
    margin-right: 10px;
  }

  .signWrapper .wrapper {
    max-width: 490px;
  }

  .signWrapper .wrapper {
    margin-left: 75px;
  }

  .signupTabs {
    margin: 0 0 0 75px;
  }

  .signSlider > img {
    width: 140px;
  }

  .myWorkFlex .workInfo {
    margin-right: 10px;
  }

  .helpBox {
    width: 30%;
  }

  .chatFlex .msgWrap {
    padding: 15px 15px;
  }

  .portfolioExamples .exampleBox {
    width: 28.5%;
  }

  .accountSettings .outerTabs.nav-pills > li a {
    padding: 21px 15px;
  }
}

@media (max-width: 1199px) {
  p.xlP {
    font-size: 17px;
  }

  .p-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2d2b2ba6;
    transition-duration: 0.2s;
  }

  .p-sidebar-mask,
  .p-sidebar-mask-leave.p-component-overlay {
    background-color: #2d2b2ba6 !important;
  }

  .p-sidebar {
    background-color: #fff;
    position: fixed;
  }

  .mobileMenu {
    display: inline-block;
  }

  .headAuth.desk {
    display: none;
  }

  .headAuth {
    display: block;
  }

  .headAuth .transBtn {
    display: block;
    width: max-content;
    margin: 0 auto 20px;
  }

  .headFlex > .mainMenu {
    display: none;
  }

  .headFlex .right .quickFeed {
    display: flex;
    background: #fafbfb;
    margin-right: 15px;
  }

  .quickFeed {
    margin-right: 0;
    margin-bottom: 10px;
    justify-content: center;
    display: flex;
    background: #fff;
    padding: 8px 10px;
    border-radius: 6px;
    margin-bottom: 0px;
  }

  .mobileMenuStyle ul.mainMenu li {
    display: block;
    margin: 0 25px 15px;
  }

  .mobileMenuStyle .p-sidebar-content .p-sidebar-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -40px;
    right: 10px;
    background-color: transparent;
    border: none;
  }

  .mobileMenuStyle .p-sidebar-content {
    margin-top: 50px;
    position: relative;
  }

  .mobileMenuStyle .p-sidebar-content .p-sidebar-close:before {
    background: url("./assets/sign-exit-grey.svg");
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
  }

  .mobileMenuStyle .p-sidebar-left {
    z-index: 1049 !important;
    width: 300px;
    /* background: #f2f4fc; */
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .p-sidebar-mask.p-component-overlay {
    z-index: 1048 !important;
  }

  .mobileMenuStyle .greenBtn {
    background: #15a3ac;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    padding: 15px 20px;
  }

  .mobileMenuStyle button.greenBtn:hover {
    background: #15a3ac !important;
  }

  .mobileMenuStyle .profileDropdown .dropdown-menu {
    position: relative;
    transform: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    border-radius: 4px;
    padding: 20px 10px;
  }

  .mobileMenuStyle .profileDropdown {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
    gap: 6%;
    /* background:#f2f4fc; */
    padding: 0rem;
  }
  .dropdown.profileDropdown.sideBarHead {
    padding: 1rem;
    background: #f2f4fc;
    flex-direction: column;
    align-items: center;
  }
  .p-sidebar {
    padding: 0 !important;
  }
  .sbAfterNotfSec {
    padding: 1rem;
  }

  ul.mainMenu li {
    display: block;
  }

  .userHeadBottom {
    display: none;
  }

  .test {
    display: none !important;
  }

  /*-------------*/
  .webProfileDropdown {
    display: none;
  }

  .headCoinsCount {
    margin: 0 0 10px;
    text-align: center;
  }

  .headLogged .right .headCoinsCount {
    display: none;
  }

  .headLogged .right > .button:nth-of-type(1) {
    margin-right: 20px;
    /* display: none; */
  }

  .headFlex .heightBtn {
    height: 42px;
    padding: 8px 15px;
    line-height: 22px;
  }

  .profileDropdown .button {
    display: block;
    /* margin: 0 auto; */
  }

  .headCoinsCount {
    margin: 0 0 10px;
    text-align: center;
  }

  ul.mainMenu {
    padding: 20px 0;
    box-shadow: 0px 3px 20px rgb(0 19 20 / 5%);
    margin: 0 0 20px;
    text-align: center;
    background: #fff;
    border-radius: 4px;
  }

  .mobileMenu ul.mainMenu li:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  .profileDropdown {
    margin: 0;
  }

  .profileDropdown a.dropdown-item {
    padding: 10px 20px;
  }

  .langToggleWrap {
    margin-bottom: 15px;
  }

  .notifyDropdown .dropdown-menu {
    right: -44.2px !important;
    left: auto !important;
    transform: translate(0, 0) !important;
  }
  .notifyDropdown .dropdown-menu {
    width: 295px;
  }

  /*----------------*/
  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 31px;
  }

  h3 {
    font-size: 27px;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 17px;
  }

  .heightBtn {
    padding: 14px 25px;
    height: 47px;
  }

  .wrapper {
    max-width: 992px;
  }

  .form-control,
  .form-control-select {
    height: 47px;
    padding: 12px 20px;
  }

  .ngSelect .ng-select-container {
    height: 47px !important;
    padding: 12px 20px;
  }

  textarea.form-control {
    height: 120px;
  }

  .button {
    font-size: 15px;
  }

  .smallBanner .content {
    left: 75px;
  }

  .popularService .accordWrap .card .btn {
    padding: 20px;
    font-size: 21px;
  }

  .popularService .accordWrap .cardBody {
    padding: 0 20px 20px;
  }

  .popServiceSlider {
    width: 450px;
  }

  .popServiceSlider .slick-dots {
    bottom: 60px;
  }

  .sidebar {
    width: 225px;
  }

  .feedbackSection > div {
    padding-top: 30px;
  }

  .discoverAdBox {
    padding: 25px 10px 30px;
    /* width: 31.3%; */
  }
  .uploadWorkSec .discoverAdBox {
    width: 31.3%;
  }

  .sidebarBox .checkboxWrap input {
    margin: 0 5px 0 0;
  }

  .pricePkgCheckbox p img {
    margin-right: 5px;
    width: 18px;
  }

  .signWrapper .left > img:nth-of-type(1) {
    right: -42px;
    width: 100px;
  }

  .signWrapper .wrapper {
    max-width: 400px;
  }

  p.orP:before,
  p.orP:after {
    width: 165px;
  }

  .signBox .btnWrap button .fa {
    font-size: 20px;
  }

  .signBox .btnWrap button {
    margin-right: 10px;
    height: 47px;
  }

  .signBox .transBtn {
    margin-left: 15px;
  }

  .signBox .btnWrap {
    margin-bottom: 20px;
  }

  .signSlider {
    margin: 240px 0 0 75px;
  }

  .signSlider {
    margin: 240px 0 0 90px;
    width: 240px;
  }

  .signSlider .carousel-item img {
    width: 44px;
  }

  .noAccLog {
    margin-top: 110px;
  }

  .noAccLog .button {
    width: 145px;
  }

  .signupTabs {
    padding: 0 50px 0 15px;
  }

  .dashboardWrap .sideNav {
    width: 200px;
    padding: 20px 15px;
  }

  .dashboardWrap > .tab-content {
    max-width: calc(100% - 200px);
  }

  .dashboardWrap .sideNav li a {
    padding: 12px 15px;
    font-size: 15px;
  }

  .dashboardWrap .sideNav li a img {
    width: 19px;
  }

  .accountSettings {
    padding: 20px 20px 40px;
  }

  .accountSettings .outerTabs.nav-pills > li a {
    padding: 15px 20px;
    font-size: 14px;
    display: block;
  }

  .myWorkFlex .workInfo {
    flex-wrap: wrap;
    align-items: flex-start;
    align-items: center;
    width: 100%;
  }

  @media screen and (min-width: 575px) {
    .hideMobileBorder {
      border: none !important;
    }

    .exampleShareDrop2.dropdown .btn {
      top: -6.8rem;
    }
  }

  @media screen and (max-width: 575px) {
    .uploadBtnGroup {
      flex-direction: column-reverse;
    }

    .dashboardTabContent {
      padding: 10px !important;
    }
    .dicoverAdFlex {
      column-gap: 6px;
    }
    .pageCard {
      padding: 8px !important;
    }
    .advisorFigures > div {
      position: relative;
      padding-right: 0px !important;
    }
    .advisorFigures h6 span {
      font-size: 14px;
    }
  }

  /* .myWorkFlex .workInfo p {
		padding-left: 20px;
	}
	.myWorkFlex .workInfo div p{padding-left: 0;}
	.myWorkFlex .workInfo:nth-of-type(1) p{padding-left: 0;} */
  .myWorkFlex .detailBtn {
    padding: 7px 10px;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .myWorkFlex > div .dropdown {
    margin-left: 10px;
    flex-shrink: 0;
  }

  .myWorkFlex.bottomWorkFlex > div:nth-of-type(1) {
    flex-direction: column;
    align-items: flex-start;
  }

  .myWorkFlex.bottomWorkFlex .workInfo {
    margin-bottom: 10px;
  }

  .myWorkFlex.bottomWorkFlex {
    align-items: flex-start;
  }

  .myWorkFlex .workInfo div {
    display: flex;
    align-items: center;
  }

  .shareWorkModalWrap .button {
    margin: 0 auto 35px;
  }

  .shareWorkModalWrap .modal-dialog {
    max-width: 485px;
  }

  .forgetCard {
    max-width: 585px;
    padding: 50px 50px;
    min-height: 340px;
  }

  .forgetCard .leftVector {
    left: -91px;
  }

  .forgetCard .rightVector {
    right: -170px;
  }

  .forgetCard .leftVector,
  .forgetCard .rightVector {
    width: 130px;
  }

  .languageWrap .box label {
    width: 180px;
  }

  .languageWrap .box label > span {
    padding: 10px 15px;
    font-size: 18px;
  }

  .tagsFlex p > img,
  .langModal ul li label img,
  .languageWrap .box label img.icon {
    width: 19px;
  }

  .preciousModalWrap .modal-dialog {
    max-width: 700px;
    width: 700px;
  }

  .preciousModalWrap.signupModalWrap p {
    font-size: 21px;
  }

  .notifyitem > div {
    padding: 5px 9%;
  }

  .notifyitem > div > div:nth-of-type(1) {
    width: 25%;
  }

  .notifyitem > div p:nth-of-type(1) {
    width: 57%;
  }

  p.dayTitle {
    padding: 0 9%;
  }

  .helpBox p {
    margin-bottom: 10px;
  }

  .helpBox img {
    margin-bottom: 15px;
  }

  .helpFlex {
    padding: 0 30px;
    margin-bottom: 25px;
  }

  .findAnsWrap {
    padding: 60px 0 70px;
  }

  .findAnsBox .vector1 {
    left: -35px;
    width: 80px;
  }

  .findAnsBox .vector2 {
    right: -70px;
    bottom: -60px;
    width: 120px;
  }

  .getStartWrap .card .btn {
    padding: 20px 20px;
    font-size: 17px;
  }

  .getStartWrap .card .cardBody div {
    padding: 0 20px 20px;
  }

  .dummyAdTitle {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .advisorProfDescription .boxWrap {
    padding: 10px 20px;
  }

  .advisorProfTabs .tab-content {
    padding: 15px;
  }

  .ourMissionSec .content {
    padding: 0 150px;
  }

  .advWrap .box:before {
    top: 0px;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
  }

  .workBox img.icon {
    width: 105px;
    height: 105px;
  }

  .workBox .vector2 {
    left: -125px;
    top: 12%;
  }

  .ourMissionSec {
    padding: 60px 0 50px;
  }

  .becomeAdvisorPage .ourMissionSec {
    margin-bottom: 70px;
  }

  .feedbackSection > div > img {
    right: -16%;
    width: 162px;
  }

  .homeBanner.aboutBanner .bannerCont {
    padding: 40px;
  }

  .ourMissionSec ul {
    padding: 0 0px;
  }

  .founderSec > img {
    width: 150px;
  }

  .toastWrap {
    padding: 15px 20px;
  }

  .uploadFileWrap > div:nth-of-type(1) {
    height: 47px;
  }

  .inputFileBtn {
    width: 100px;
    height: 47px;
  }

  .dashboardWrap {
    height: calc(100vh - 102px);
  }

  .dashboardWrap.userDashboard {
    height: calc(100vh - 54px);
  }

  .priceContentBox .inputWrap input {
    width: 375px;
  }

  .buyCoinsWrap .box label > span {
    font-size: 21px;
    line-height: 25px;
  }

  .buyCoinsWrap .box label {
    height: 90px;
  }

  p.orBuyP:before,
  p.orBuyP:after {
    width: 60px;
  }

  p.orBuyP:after {
    right: -60px;
  }

  p.orBuyP:before {
    left: -60px;
  }

  .coinsAmountBox input {
    width: 220px;
  }

  .paymentCardWrap img {
    max-width: 22%;
  }

  /* .homePage .exampleFlex .exampleBox:nth-of-type(3){display: none;} */
  /* .favouriteAdvisor .discoverAdBox {
    width: 23.5%;
  } */

  .advisorFigures {
    margin-bottom: 20px;
  }

  .exampleBox .content {
    padding: 0 10px 25px;
  }

  .exampleBoxTotal {
    padding: 6px 10px;
  }

  .exampleBox {
    width: 31%;
  }

  .filterDrops {
    margin-left: 30px;
  }

  .exampleFilters .button {
    padding: 14px 15px;
    font-size: 13px;
    height: 42px;
  }

  .exampleShareDrop.dropdown .dropdown-menu ul li a img {
    width: 20px;
  }

  .coinsSec > img {
    margin-left: -25px;
    width: 500px;
  }

  /* .header{margin-bottom:10px;} */
  .userSlideTop .left .infoBox {
    display: none;
  }

  .msgSecWrap .left {
    width: 280px;
  }

  .convoBox {
    padding: 10px;
  }

  .convoBox h6 {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .convoBox .dpWrap {
    margin-right: 15px;
  }

  .convoBottom .greenBtn {
    height: 39px;
    line-height: 39px;
  }

  .msgCounter {
    width: 20px;
    height: 20px;
    left: 22px;
  }

  .chatFlex .msgWrap {
    padding: 10px 15px;
    width: 300px;
  }

  .chatFlex .dpWrap {
    margin: 0 20px 25px;
  }

  .convoBottom {
    padding: 10px;
  }

  .noChatWrap img {
    margin-bottom: 15px;
    width: 98px;
  }

  .chatWrap {
    height: calc(100% - 180.8px);
  }

  .uploadWorkFlex .left {
    flex: 0 0 500px;
  }

  .tooltipInputWrap .inputWrap {
  }

  .uploadWorkSum {
    max-width: 420px;
    padding: 25px 10px 20px;
  }

  .uploadWorkSum {
    max-width: 420px;
    padding: 25px 10px 20px;
  }

  .advisorCell img {
    margin-right: 5px;
    width: 33px;
    height: 33px;
  }

  .uploadWorkSum .table td {
    font-size: 12px;
  }

  .uploadWorkWrap
    .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header-container {
    max-width: 500px;
  }

  /* .uploadWorkFlex .discoverAdBox {
    width: 46.4% !important;
  } */

  .archiveExamples {
    min-height: 376px;
  }

  .archiveExamples img {
    width: 100px;
  }

  .advisorProfTop .right .shortBtn {
    height: 40px;
    padding: 11px 15px;
    margin-left: 10px;
  }

  .advisorProfDesc .right .btnGroup .button {
    font-size: 16px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .oproviAdvisorSec .wrapper > img {
    left: -23px;
    width: 166px;
  }
  .profileUserInfo {
    display: none;
  }
  .logSwitchBtn {
    margin: 20px 0 40px;
    text-align: center;
    display: block !important;
  }
  .dragSubSec img.cloudIcon {
    top: 15px;
    width: 22px !important;
  }
  a.becAnAdvMobMenu {
    text-align: center;
    display: block;
    color: #15a3ac;
    margin: 15px 0px;
  }
  .notifMobSec {
    margin: 30px 0px 10px;
    /* display:inline-block; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .sbhUserSec {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-direction: row-reverse;
    width: 100%;
  }
  .sideBarHead .mobileMenuStyle .profileDropdown .dropdown-menu.show {
    padding: 10px 20px 30px !important;
  }

  .notifyDropdown {
    margin-right: 20px;
    /* display: none; */
  }
  .notifyDropdown h6 {
    display: none;
  }
  .notfTextMob {
    display: block;
  }
  .mobileMenuStyle .p-sidebar-content .notifyDropdown {
    display: block;
    position: relative;
  }

  .notifyDropdown .notifyCount {
    top: -10px;
    right: unset;
    left: 0px !important;
  }
  .notifMobSec h6 {
    display: block;
  }
  a#dropdownMenuButton1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 5%;
  }
  .notfTextMob {
    color: #002642;
    font-size: 14px;
    line-height: 17px;
    position: relative;
  }
  /* .notfTextMob .notifyCount{
		top: -10px;
    right: -18px;
	} */
  .uploadWorkFlex .dicoverAdFlex {
    max-height: fit-content;
  }
  .advisorTabs {
    margin-bottom: 85px;
  }
  .dpWrapTwo {
    width: 50px !important;
    height: 50px !important;
  }
  .uploadWorkSec .discoverAdBox .shortBtn {
    width: 99%;
    max-width: 96%;
  }
  .ng-tns-c126-0.ng-trigger.ng-trigger-panelState.p-sidebar.p-sidebar-active.p-sidebar-left {
    padding: 0;
  }
  .p-sidebar-content .sideBarHead .dropdown-menu {
    top: 20px !important;
    left: -90px !important;
    min-width: 280px;
  }
  .notifMobSec .notifyCount {
    left: 0px !important;
  }
  .wrapper.examplesPage.headerMargin.mainTabPages {
    max-width: 100% !important;
  }
  .convoBox .msgCounter {
    left: 4px !important;
    top: 15px;
  }
}

@media (max-width: 991px) {
  /* ************************ Upload work mobile responsiveness add by Naeem ************/
  .advisorTabs .nav-pills li a.active:before {
    bottom: -30px;
    left: 45%;
  }

  /* ***********************************************************************************/

  h1 {
    font-size: 31px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 15px;
  }

  .mb {
    margin-bottom: 20px;
  }

  textarea.form-control {
    height: 115px;
  }

  .heightBtn {
    padding: 10px 20px;
    height: 43px;
  }

  label.xlLabel {
    font-size: 16px;
  }

  label.lgLabel {
    font-size: 15px;
  }

  p.lgP {
    font-size: 15px;
    line-height: 21px;
  }

  p.xlP {
    font-size: 16px;
  }

  .homeBanner > img {
    min-height: 500px;
  }

  .homeAdvisorBanner.homeBanner > img {
    min-height: auto;
  }

  .logo img {
    width: 100px;
  }

  .wrapper {
    max-width: 768px;
  }

  .bannerCont {
    max-width: 600px;
    min-width: 600px;
    padding: 30px;
  }

  .homeBanner .bannerContWrap {
    left: 50px;
  }

  .popularService {
    padding: 50px 0 110px;
  }

  .popServiceFlex {
    flex-direction: column;
  }

  .popServiceSlider {
    width: 500px;
    order: 1;
    margin: 0 auto;
  }

  .popularService .accordWrap {
    margin-right: 0;
    order: 2;
    max-width: 500px;
    margin: 0 auto;
  }

  .exampleBox {
    margin: 20px 10px;
    width: 30.5%;
  }

  .advisorSec {
    padding: 70px 0;
  }

  .advisorSec > img {
    top: -65px;
    width: 150px;
  }

  /* .discoverAdFlexWrap .dicoverAdFlex .discoverAdBox {
    width: 47%;
  } */

  .dicoverAdFlex {
    grid-template-columns: repeat(2, 1fr);
  }
  .uploadWorkSec .discoverAdBox {
    width: 47%;
  }

  /* .discoverAdBox{width:30.7%;} */
  .oproviAdvisorBox .rating span {
    font-size: 19px;
  }

  .oproviAdvisorBox .rating img {
    width: 14px;
  }

  .oproviAdvisorSec {
    padding: 50px 0 70px;
  }

  .coinSystemSec {
    padding: 50px 0;
  }

  .getCoinsWrap {
    min-height: 220px;
  }

  .howItWorkSec > img {
    top: -65px;
    width: 125px;
  }

  .feedbackSection > div {
    width: 675px;
  }

  .feedbackSection > img {
    height: 350px;
    object-fit: cover;
  }

  .footerFlex .middle > div:nth-of-type(1) {
    display: none;
  }

  .feedbackSection > div > img {
    right: -13%;
    width: 150px;
  }

  .feedbackSection {
    overflow: hidden;
    margin-top: 50px;
  }

  .searchWrap {
    min-width: 280px;
  }

  .filterDrops {
    margin-left: 20px;
  }

  .smallBanner .content h3 {
    margin-bottom: 10px;
  }

  .exampleBoxTotal {
    padding: 6px 10px;
  }

  .signWrapper .wrapper {
    max-width: 380px;
    margin-left: 25px;
  }

  .signWrapper .right > img {
    width: 118px;
    left: -54px;
  }

  .signSlider {
    margin: 240px 0 0 40px;
  }

  .signSlider > img {
    width: 130px;
    right: -13%;
  }

  p.orP:before,
  p.orP:after {
    width: 150px;
  }

  .modal-dialog {
    max-width: 510px;
    width: 510px;
  }

  .modal-content {
    padding: 40px 20px 30px 40px;
  }

  .signupTabs {
    margin: 0 0 0 16px;
    padding: 0 16px;
  }

  .signupStepper .mat-step-label-active .mat-step-text-label {
    font-size: 13px;
    font-weight: 500;
  }
  .tagsFlex {
    flex-wrap: wrap;
  }
  .tagsFlex p {
    margin-bottom: 15px;
  }
  .signupStepper .mat-step-label-active .tabNo {
    font-size: 11px;
    border: 5px solid #fff;
    min-width: 18px;
  }

  .signupStepper .tabNo {
    /* width: 18px; */
    /* height: 18px; */
    /* margin-right: 5px; */
  }

  .mat-horizontal-stepper-header {
    height: 45px;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 10px;
  }

  .lgSignWrapper.signWrapper .right {
    flex: 0 0 115px;
  }

  .standardCard.pricePkgCard > label,
  .doProCard.pricePkgCard > label {
    padding: 9px 15px;
    top: 10px;
  }

  .signupStepper .signBox .transBtn {
    font-size: 15px;
  }

  .advisorEmptyFlex .box h6 {
    line-height: 20px;
  }

  .tabContentWrap {
    padding: 20px;
  }

  .rateAdDp {
    margin-bottom: 10px;
  }

  .forgetCard {
    max-width: 490px;
    padding: 40px 30px;
  }

  .forgetWrapper {
    overflow: hidden;
  }

  .finishWrap img {
    width: 140px;
  }

  .languageWrap .box label > span {
    font-size: 15px;
  }

  .preciousModalWrap .modal-dialog {
    width: 550px;
  }

  .signupModalWrap .preciousModal > img {
    width: 145px;
  }

  .preciousModalWrap.signupModalWrap p {
    font-size: 18px;
    line-height: 25px;
  }

  .notifyitem > div p:nth-of-type(1) {
    width: 55%;
  }

  .helpBox {
    width: 48%;
  }

  .findAnsBox {
    width: 400px;
  }

  .findAnsBox .vector2 {
    right: -45px;
    bottom: -40px;
    width: 105px;
  }

  .findAnsWrap {
    padding: 35px 0 50px;
  }

  .getStartWrap .card .btn {
    font-size: 15px;
  }

  .advisorProfTop .left .info .dpWrap {
    width: 50px;
    aspect-ratio: 1/1;
  }

  .advisorProfTop .left .rating img {
    width: 12px;
  }

  .advisorProfTimer h6 {
    font-size: 13px;
  }

  .advisorProfilePage .box {
    margin-left: 15px;
  }

  .commonAdProfile .advisorProfTop:nth-of-type(2) .left .info {
    margin-left: 15px;
  }

  .advisorProfDesc {
    flex-wrap: wrap;
  }

  .advisorProfDesc .right .btnGroup .button {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    height: 40px;
  }

  .advisorProfTop .favTextBtn {
    font-size: 16px;
    padding-left: 25px;
  }

  .favTextBtn:before {
    background-size: 14px 18px;
    width: 14px;
    height: 18px;
  }

  .privacyPage .privacyBox {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .contactFlex > img {
    width: 340px;
  }

  .contactFlex .right {
    padding: 40px 0px 100px 20px;
  }

  .howItWorkSec {
    padding: 50px 0;
  }

  .howItWorkSec h2 {
    margin-bottom: 30px;
  }

  .workBox .vector1 {
    right: -120px;
    top: 30%;
    width: 112px;
  }

  .workBox .vector2 {
    left: -110px;
    top: 19%;
    width: 105px;
  }

  .workBox {
    width: 415px;
    margin: 0 auto 55px;
  }

  .workBox img.icon {
    width: 90px;
    height: 90px;
  }

  .ourMissionSec .content {
    padding: 0 20px;
  }

  .becomeAdvisorPage .ourMissionSec > img {
    position: absolute;
    left: 40px;
    top: -100px;
    width: 150px;
  }

  .becomeAdvisorPage .feedbackSection > img {
    height: 230px;
  }

  .becomeAdvisorPage .ourMissionSec {
    margin-bottom: 50px;
  }

  .advWrap .box {
    padding-left: 50px;
  }

  .homeBanner.aboutBanner .bannerContWrap {
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .homeBanner.aboutBanner .bannerCont {
    padding: 30px;
  }

  .homeBanner.aboutBanner .bannerCont p {
    max-width: 100%;
  }

  .homeBanner.aboutBanner .bannerCont p:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  .ourMissionSec ul li {
    font-size: 15px;
  }

  .ourMissionSec ul li:before {
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
  }

  .ourMissionSec ul li {
    padding-left: 45px;
  }

  .toastWrap .heightBtn {
    padding: 10px 20px;
    height: 37px;
  }

  .tabContentBox,
  .tabContentWrap.accSettingExp .tabContentBox {
    padding-right: 0;
    width: 100%;
  }

  .tabContentFlex {
    flex-wrap: wrap;
  }

  .priceContentBox {
    width: 100%;
  }

  .priceContentBox .inputWrap input {
    width: 300px;
  }

  .searchWrap .button {
    height: 47px;
  }

  .discoverAdBox {
    padding: 20px 10px 35px;
  }
  .discoverAdBox .shortBtn {
    bottom: 10px;
  }

  .discoverAdBox h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .discoverAdBox h4 img {
    width: 20px;
    margin-right: 5px;
  }

  .expLabel img {
    width: 12px;
  }

  .discoverAdBoxTop .btn:before {
    background-size: 14px 18px;
    width: 14px;
    height: 18px;
  }

  /* .favouriteAdvisor .discoverAdBox {
    width: 31.3%;
  } */

  .filterDropBox .dropdown > .button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .filterDrops {
    margin-left: 10px;
  }

  .smallBanner .content {
    left: 30px;
  }

  .exampleSec {
    padding: 50px 0;
  }

  .homePage .exampleFlex .exampleBox:nth-last-of-type(1) {
    display: none;
  }

  .coinsBox img {
    margin-right: 15px;
    width: 55px;
  }

  .coinsSec .right > p {
    margin-bottom: 45px;
  }

  .coinsBox {
    margin-bottom: 25px;
  }

  .coinsSec > img {
    width: 390px;
  }

  .exampleLiked {
    padding: 2px 25px;
  }

  .noChatWrap p {
    text-align: center;
  }

  .msgSecWrap {
    flex-direction: column;
  }

  .msgSecWrap .left {
    width: 100%;
  }

  .uploadWorkFlex {
    flex-wrap: wrap;
  }

  .uploadWorkFlex .left,
  .uploadWorkFlex .right {
    flex: none;
    width: 100%;
    max-width: 100%;
  }

  .uploadWorkWrap
    .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header-container {
    margin: 0 auto 30px;
  }

  .uploadWorkFlex.step1 .left {
    order: 2;
  }

  .uploadWorkFlex.step1 .right {
    order: 1;
    margin-bottom: 30px;
  }

  .uploadSlider .carousel-item img,
  .tabBlock .tab-pane > img {
    margin: 0 auto;
    display: block;
  }

  .tabBlock .tab-pane > p {
    text-align: center;
  }

  .tooltipInputWrap {
    max-width: 100%;
    margin: 0 auto;
  }

  .tooltipInputWrap .inputWrap {
    min-width: 100%;
  }

  .multiUploadsWrap {
    max-width: 100%;
  }

  .uploadWorkFlex.step2 .right .uploadBtnGroup {
    display: block;
  }

  /* .uploadWorkFlex.step2 .left .uploadBtnGroup {
		display: none;
	} */

  .uploadWorkSum {
    max-width: 100%;
  }

  .uploadWorkFlex .right .uploadWorkSum {
    margin-left: 0;
  }

  .uploadWorkSum .table td:nth-of-type(2) {
    width: auto;
  }

  .addAdvisorTab .customSlider {
    max-width: 100%;
  }

  /* .uploadWorkFlex .discoverAdBox {
    width: 31.4%;
  } */

  .uploadSuccessWrap > img {
    width: 140px;
  }

  .portfolioExamples .exampleBox {
    width: 215px;
  }

  .uploadAdProTop {
    flex-wrap: wrap;
  }

  .uploadAdProTop.advisorProfTop .right {
    margin: 10px 0 0 auto;
  }

  .founderSec > img {
    width: 120px;
    left: 55px;
  }

  .uploadFileWrap > div:nth-of-type(1) {
    padding: 12px 20px;
  }

  .inputFileBtn .button {
    display: inline-flex;
    height: 47px;
  }

  /* .tooltipInputWrap{max-width: 100%;}
	.tooltipInputWrap .inputWrap {
		min-width: 90%;
	}
	.multiUploadsWrap {
		max-width: 100%;} */
  .uploadWorkSum .table {
    margin-top: 45px;
  }
  .accountSettings.portfolioTabs .searchWrap .form-control {
    font-size: 14px;
  }
  .accountSettings.portfolioTabs .exampleBox {
    width: 46%;
  }
  .filterWrap {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterWrap .searchWrap {
    margin-bottom: 15px;
    width: 100%;
  }
  .disAdvFilFavRow {
    min-width: 90px;
    margin-left: 30px;
  }
  .dsCheckBoxSec {
    display: flex;
  }
  .accountSettings .filterDropMain {
    width: 100%;
    justify-content: space-between;
  }
  .advisorTabs {
    margin-bottom: 30px;
  }

  /* .uploadWorkFlex.step2 .discoverAdBox {
    width: 31.4%;
  } */
  .uploadWorkFlex.step1 .left form.ng-untouched.ng-pristine.ng-invalid {
    max-width: 80%;
    margin: 0 auto;
  }
  .helpFlex {
    padding: 0 25px;
    margin-bottom: 20px;
  }
  .reportBtn {
    padding: 3px 15px;
  }
  .msgCounter {
    top: 32%;
    left: 25px;
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 767px) {
  .moneyModalWrap .modal-dialog {
    max-width: 100%;
    width: 100%;
  }

  .uploadAdProTop .left .info {
    display: none !important;
  }

  .advisorProfTop .info2 {
    display: flex;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    padding: 0 8px;
  }

  p.lgP {
    font-size: 14px;
    line-height: 21px;
  }

  .button {
    font-size: 15px;
  }

  .wrapper {
    max-width: 576px;
  }

  .large-wrapper {
    margin: 0 15px;
  }

  .modal-dialog {
    max-width: 460px;
    width: 460px;
  }

  .modal-content {
    padding: 30px 20px 30px 20px;
  }

  .bannerCont {
    max-width: 100%;
    min-width: 100%;
  }

  .homeBanner .bannerContWrap {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 90%;
  }

  .homeBanner > img {
    min-height: 405px;
  }

  .homeAdvisorBanner.homeBanner .bannerContWrap {
    top: 70px;
    transform: translate(-50%, 0px);
    width: 500px;
  }

  .homeAdvisorBanner.homeBanner > img {
    min-height: 320px;
  }

  .headFlex .heightBtn {
    height: 38px;
    padding: 10px 20px;
    line-height: 15px;
  }

  .popularService .wrapper > h2 {
    margin-bottom: 20px;
  }

  .popularService .accordWrap .card .btn {
    padding: 15px 20px;
    font-size: 19px;
  }

  .popularService .accordWrap .cardBody p {
    font-size: 14px;
    line-height: 21px;
  }

  .popularService .accordWrap .card .btn img {
    margin-right: 10px;
    width: 20px;
  }
  .popServiceFlex {
    flex-direction: column-reverse;
  }

  .popularService .accordWrap {
    /* margin-top: -90px; */
    margin-bottom: 30px;
  }
  .popularService {
    padding: 40px 0 60px;
  }

  .workBox img.icon {
    width: 80px;
    height: 80px;
  }

  .workBox {
    width: 400px;
    margin: 0 auto 60px;
  }

  .workBox {
    /* width: 100%; */
    margin: 0 auto 100px;
  }

  .workBox .vector {
    width: 100px;
  }

  .workBox .vector1 {
    right: -80px;
  }

  .workBox .vector2 {
    left: -80px;
  }

  .workBox img.mob {
    display: block;
    width: 65px;
  }

  .workBox img.desk {
    display: none;
  }

  .workBox .vector2 {
    top: 100%;
    left: 56px;
  }

  .workBox .vector1 {
    right: 60px;
    top: 100%;
  }

  .howItWorkSec .workBox:nth-of-type(2) {
    padding-left: 40px;
  }

  .howItWorkSec h2 {
    margin-bottom: 30px;
  }

  .exampleFilters .button {
    margin: 0 5px 10px;
    padding: 10px 15px;
    height: 42px;
  }

  .exampleBox {
    width: 46.1%;
  }

  .homePage .exampleFlex {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .homePage .exampleBox {
    width: 230px;
    flex-shrink: 0;
  }

  .homeAdvisorWrap .dicoverAdFlex {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .homeAdvisorWrap .discoverAdBox {
    min-width: 220px;
  }
  .discoverAdBox .shortBtn {
    max-width: 90%;
  }

  .oproviAdvisorBox {
    flex-shrink: 0;
    width: 340px;
    margin: 0 10px;
    padding: 20px;
  }

  .oproviAdvisorFlex {
    padding-bottom: 10px;
    overflow-x: auto;
    flex-wrap: nowrap;
    margin: 0 -10px;
  }

  .oproviAdvisorTop .dpWrap {
    width: 45px;
    height: 45px;
  }

  .oproviAdvisorTop .dpWrap + div {
    padding-left: 10px;
  }

  .oproviAdvisorTop h6 {
    line-height: 15px;
  }

  .oproviAdvisorTop h6 span {
    display: none;
  }

  /*-------------*/
  .signWrapper .right {
    display: none;
  }

  .signWrapper .left {
    width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .signWrapper .wrapper {
    margin: 0 auto;
    max-width: 450px;
  }

  .signWrapper .left > img:nth-of-type(1) {
    display: none;
  }

  .signBox .btnWrap {
    flex-direction: column;
  }

  .signBox .btnWrap button {
    width: 100%;
    margin: 0 0 10px;
  }

  .signBox .btnWrap button span {
    display: inline;
    color: #15a3ac;
    margin-left: 6px;
  }

  .signBox .btnWrap button .fa {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
  }

  .signBox .btnWrap button:nth-of-type(1) {
    width: 100%;
  }

  .signBox h2 {
    text-align: center;
  }

  .loginFormBtn {
    width: 100%;
    margin-bottom: 10px;
  }

  .loginFormBtn + .button {
    margin: 0 auto;
    display: block;
  }

  .forgetP {
    margin-bottom: 30px;
  }

  .signupHeader .exitBtn {
    display: inline-flex;
  }

  .signWrapper .signBox {
    margin-bottom: 50px;
  }

  .noAccLog {
    text-align: center;
  }

  .signWrapper .left > img:nth-of-type(2) {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .forgetCard .signBox h2,
  .forgetCard .signBox p,
  .signBox p.suggestP {
    text-align: left;
  }

  .signBox p {
    text-align: center;
  }

  .preciousModalWrap .modal-dialog {
    width: 490px;
  }

  .preciousModalWrap.signupModalWrap .modal-content {
    padding: 30px 40px 40px 40px;
  }

  .preciousModalWrap.signupModalWrap p {
    font-size: 16px;
    line-height: 22px;
    margin: 0 auto 20px;
  }

  .signupModalWrap .preciousModal h3 {
    margin-bottom: 15px;
  }

  .signupModalWrap .preciousModal > img {
    width: 120px;
  }

  .finishWrap {
    width: 550px;
  }

  .advisorSignup .signupTabs {
    margin: 0 0 0 0;
  }

  .advisorSignup.signWrapper .wrapper {
    max-width: 100%;
  }

  .advisorSignup .signBox {
    max-width: 100%;
  }

  .advisorSignup.signWrapper .left {
    padding: 20px 0;
  }

  .lgSignWrapper h6 {
    margin-bottom: 15px;
  }

  .footerFlex {
    padding: 30px 0;
  }

  .footerFlex .left .fooContact {
    display: block;
  }

  .footerFlex .right {
    display: none;
  }

  .footerFlex .left .logo + p {
    margin-bottom: 30px;
  }

  .footerFlex ul.socialIcons {
    margin-bottom: 20px;
  }

  .footerFlex .middle {
    flex-direction: column;
  }

  .footerFlex .middle > div:nth-of-type(3) {
    margin-top: 20px;
  }

  .footerFlex .left {
    width: 60%;
  }

  .fooUploadWrap {
    width: 100%;
  }

  .footerBottom ul li {
    margin-left: 30px;
  }

  .oproviAdvisorSec .wrapper > img {
    width: 135px;
  }

  .feedbackSection > div {
    flex-direction: column;
    width: 90%;
    text-align: center;
  }

  .feedbackSection > div > .button {
    margin-left: 0;
    margin-top: 20px;
    min-width: 280px;
  }

  .feedbackSection > div > img {
    display: none;
  }

  .coinsSec {
    flex-direction: column;
  }

  .getCoinsFlex {
    margin-bottom: 45px;
  }

  .howItWorkSec > img {
    width: 100px;
  }

  .homeBanner.aboutBanner .bannerCont {
    min-width: 515px;
    max-width: 515px;
  }

  .homeBanner.aboutBanner > img {
    min-height: 550px;
  }

  .filterWrap .filtersBtn {
    display: inline-block;
    color: #002642;
  }

  .sidebar {
    position: fixed;
    display: none;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgb(211 209 209 / 20%);
    top: 0;
    right: 0;
    min-width: 300px;
    padding: 15px;
    z-index: 9;
    margin-right: 0;
    height: 100vh;
    overflow-y: auto;
  }

  .sidebar.open {
    display: block;
    width: 100%;
  }

  .sidebarHead {
    display: block;
    position: relative;
    padding-bottom: 3px;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #d3d1d133;
  }

  .sidebarHead h5 {
    text-align: center;
  }

  .closeSideBtn {
    position: absolute;
    left: 0;
    top: 40%;
    transform: translate(0, -50%);
  }

  .sidebarWrap {
    height: calc(100% - 83px);
    overflow-y: auto;
  }

  .smallBanner {
    background: url("./assets/grey-banner-bg.svg") no-repeat;
    background-position: bottom;
    background-size: cover;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
  }

  .smallBanner .content {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
    width: 100%;
  }

  .smallBanner > img {
    display: none;
  }

  /* .uploadWorkFlex .discoverAdBox {
    width: 47.4%;
  } */

  /* .favouriteAdvisor .discoverAdBox {
    width: 47.3%;
  } */

  .smCatDrop {
    display: block;
  }

  .examplesPage .exampleFilters {
    display: none;
  }

  /* .filterWrap {
		flex-wrap: wrap;
		justify-content: center;
	} */

  .filterWrap .searchWrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .dashboardWrap .sideNav {
    display: none;
  }

  .dashboardWrap > .tab-content {
    max-width: 100%;
  }

  .advisorProfTop {
    flex-wrap: wrap;
  }

  .advisorProfTop .right {
    order: 1;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 100%;
  }

  .advisorProfTop .left {
    order: 2;
  }

  .advisorProfTop .right .info {
    display: inline-block;
  }

  .advisorProfTop .left .info {
    /* display: none; */
  }
  .advisorProfTop .left .info .rating {
    /* display: none; */

    display: none;
  }
  .advisorProfTop.adivsorTopOne {
    border: none;
    position: relative;
  }
  .advisorProfTop.adivsorTopOne .rating {
    /* position: absolute; */
    /* bottom: 10px; */
    /* display:inline-block; */
    display: flex;
    width: 140px;
    position: absolute;
    bottom: -25px;
    left: 10px;
  }
  .primeButton {
    display: block;
  }
  .advisorProfTop.advisorTopTwo .rating {
    display: none;
  }
  .pageCard {
    padding: 22px;
  }

  /* .advisorProfTop .left .box{flex-direction: column;} */
  .advisorProfTop .left {
    width: 100%;
    justify-content: space-between;
    margin: 20px 0px;
  }

  .advisorProfDesc .left {
    margin-bottom: 10px;
  }

  .pricingPlan .top,
  .pricingPlan .bottom {
    padding: 15px;
  }

  .pricingPlan .flex .left {
    width: 85px;
  }

  .contactFlex {
    flex-direction: column;
  }

  .contactFlex > img {
    width: 250px;
    margin: 0 auto;
  }

  .contactFlex .right {
    padding: 40px 0px 100px 0px;
  }

  .contactFlex .breadCrumb {
    margin-bottom: 20px;
  }
  .toastWrap {
    min-width: auto !important;
    /* right:0; */
  }
  .myWorksTopFlex .infoTooltip {
    top: 10px;
    right: 15px;
  }
  .myWorkFlex .detailBtn {
    padding: 7px 4px 7px 5px;
    margin-left: 2px;
  }
  .userSlideTop {
    padding: 20px 10px;
    /* flex-direction: column; */
  }
  .userSlideTop .right .shortBtn {
    width: 101px;
    margin-right: 14px;
  }
  .accountSettings .outerTabs.nav-pills {
    /* display: none; */
  }
  .mainTabPages .tabContentWrap {
    padding: 10px 5px;
  }
  .dicoverAdFlex {
    margin: 0 11px;
  }
  .disAdvFilFavRow {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .advisorFigures h6 span {
    font-size: 12px;
  }
  .advisorFigures > div:after {
    content: "|";

    font-size: 10px;

    bottom: 10px;
    right: -8px;
  }
  .advisorFigures {
    /* gap: 6%; */
  }
  .userSlideTop .left,
  .userSlideTop .right {
    /* width: 100%; */
    /* justify-content: center; */
  }
  .exampleUserSlider {
    position: relative;
  }
  .exampleViewPage .userSlideTop .right .bookmarkBtn {
    margin-right: 0px;
    position: absolute;
    top: 30px;
    right: 0;
    right: 48px;
  }
  .exampleViewPage .userSlideTop .right .bookmarkBtn:before {
    content: "";

    background-size: 14px 16px;
    background-repeat: no-repeat;
    width: 14px;
    height: 19px;
  }
  .exampleViewPage .userSlideTop .right .dropdown {
    margin-right: 0px;
    position: absolute;
    top: 25px;
    right: 0;
    right: 28px;
  }
  .exampleUserSlider .userSlideTop {
    flex-direction: column;
    align-items: start;
  }
  .exampleUserSlider .userSlideTop .right .shortBtn {
    width: 130px;
    margin: 15px 2px 10px;
  }
  .exampleViewPage .exampleAnnotation {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
  }
  .annotationFlex .paginationWrap {
    width: 100%;
  }
  .annotateComWrap {
    width: 100%;
  }
  .annotateComWrap > div {
    padding: 10px;
  }
  .exampleViewPage .dashboardTabContent {
    padding-bottom: 120px;
  }
  .annotationImg > div {
    width: 100%;
  }
  .exampleLiked {
    padding: 2px 5px;
  }
  .dashboardTabContent {
    /* padding: 20px; */
  }

  .exampleShareDrop2.dropdown .btn {
    top: -8.8rem;
  }

  .myWorksWrap h3 {
    margin: 20px 0 0 20px;
  }

  .myWorksWrap .myWorksInfo {
    padding: 20px;
  }

  .myWorksWrap .myWorksDesc {
    padding: 20px;
  }

  /* .notifyDropdown .dropdown-menu {
    right: -65px !important;
    left: auto !important;
    transform: translate(0, 0) !important;
}
.notifyDropdown .dropdown-menu {
   
    width: 270px;
  
} */

  .annotationFlex {
    flex-direction: column;
  }
  .uploadWorkFlex .dicoverAdFlex {
    max-height: fit-content;
    width: 100%;
    /* gap: 2%; */
    margin: 0;
    justify-content: flex-start;
  }

  /* .uploadWorkFlex.step2 .discoverAdBox {
    width: 47.5%;
  } */
  .advisorTabs .nav-pills li a {
    font-size: 13px;
    padding: 10px 8px;
  }
  .advisorTabs .nav-pills li a {
    padding: 10px 5px;

    width: 160px;
    text-align: center;
  }

  .uploadWorkFlex.step2 .advisorTabs .nav-pills {
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }
  .uploadWorkFlex.step2 .uploadContTabs {
    align-items: baseline;
    margin-bottom: 30px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .uploadWorkFlex.step2 .uploadContTabs > div {
    width: 100%;
    justify-content: flex-end;
  }

  /* ngb-tooltip-window#ngb-tooltip-0 {
    transform: none;
    right: 0px !important;
    left: unset;
    transform: translate(-150px, -50px) !important;
} */

  .uploadWorkFlex.step2 .uploadContTabs {
    align-items: baseline;
    margin-bottom: 30px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .uploadWorkFlex.step2 .uploadContTabs > div {
    width: 100%;
    justify-content: flex-end;
  }
  .applyBtn {
    display: block;
  }
  .helpFlex {
    padding: 0 20px;
  }

  .info.adProfRatingMob .rating {
    display: flex !important;
  }
  .advProfLeft {
    margin-bottom: 0px !important;
  }
  /* .priceInnerPkg .inputWrap .infoTooltip {
    right: -20px;
  } */

  /* .priceInnerPkg .inputWrap .infoTooltip #ngb-tooltip-1 {
    background: red;
} */

  .infoTooltip .tooltip-inner {
    position: absolute;
    right: 0px !important;
    width: 70%;
    top: 10px;
    padding: 14px;
  }
  .quickFeedWeb {
    display: none !important;
  }
  .quickFeedMob {
    display: flex !important;
  }
  .mobileMenu {
    margin-right: 5px;
  }
  .advisorProfilePage .box {
    margin-left: 5px;
  }
}

@media (max-width: 575px) {
  button.button.greenBtn.heightBtn.asSaveBtn {
    width: 100% !important;
  }
  p.xlP {
    font-size: 14px;
  }
  .advisorProfTop {
    padding-top: 0;
  }
  .breadCrumb h6 {
    font-size: 14px;
  }

  .large-wrapper {
    margin: 0 0px;
  }

  label.xlLabel {
    font-size: 15px;
  }

  .modal-dialog {
    max-width: 420px;
    width: 420px;
    margin: 20px auto;
  }

  .forgetCard {
    max-width: 355px;
  }

  .termsModal .content {
    padding-right: 0;
    max-height: none;
  }

  .preciousModalWrap .modal-dialog {
    width: 410px;
  }

  .preciousModalWrap.signupModalWrap.registerModalWrap .modal-content {
    padding: 30px 30px 45px 30px;
  }

  .finishWrap {
    width: 100%;
    padding: 0 15px;
  }

  .finishWrap img {
    width: 110px;
  }

  .finishWrap img,
  .finishWrap h3,
  .finishWrap p {
    margin-bottom: 20px;
  }

  .finishScreen {
    padding-bottom: 100px;
  }

  .finishScreen .vector {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
  }

  .signupStepper .mat-horizontal-stepper-header {
    flex-shrink: 0;
    height: 45px;
  }

  .signupStepper .mat-step-label {
    flex-shrink: 0;
  }

  .mat-horizontal-stepper-header-container {
    overflow-x: scroll;
  }

  .mat-horizontal-stepper-header-container::-webkit-scrollbar {
    display: none;
  }

  .pricePkgCard .cardBody {
    padding: 0 15px 20px;
  }

  .priceSlider .ngx-slider-limit {
    width: 48px;
    height: 48px;
  }

  /* .mobileMenu{margin-right: 0;} */
  .footerFlex .left {
    width: 54%;
  }

  .footerBottom .footerFlex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .footerBottom p {
    margin-bottom: 10px;
  }

  .popServiceSlider {
    width: 100%;
  }

  .oproviAdvisorSec .wrapper > img {
    display: none;
  }

  .getCoinsWrapper > p {
    display: none;
  }

  .getCoinsFlex {
    flex-direction: column;
  }

  .coinBoxWrap {
    margin-right: 0;
  }

  .coinSystemSec .innerWrapper > p:nth-of-type(1) {
    margin-bottom: 25px;
  }

  .homeAdvisorBanner.homeBanner .bannerContWrap {
    width: 345px;
  }

  .footerBottom p {
    order: 2;
    margin-bottom: 0;
  }

  .footerBottom ul {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a4a4a4;
    padding-bottom: 10px;
  }

  .howItWorkSec > img {
    top: -50px;
  }

  .homeBanner.aboutBanner .bannerCont {
    min-width: 100%;
    max-width: 100%;
  }

  .ourMissionSec ul li {
    width: 100%;
  }

  .founderSec > img {
    display: none;
  }

  .founderSec {
    padding: 40px 0 60px;
  }

  .wrapperCard {
    padding: 30px;
  }

  .halfFlex {
    flex-direction: column;
  }

  .halfFlex > div {
    width: 100%;
  }
  /* 
  .discoverAdFlexWrap .dicoverAdFlex .discoverAdBox {
    width: 46.7%;
  } */

  /* .uploadWorkFlex .discoverAdBox {
    width: 46.9%;
  } */

  /* .favouriteAdvisor .discoverAdBox {
    width: 46.7%;
  } */

  .exampleBox {
    /* width: 250px; */
    margin: 10px auto 20px;
    width: 90%;
  }

  .homePage .exampleBox {
    margin: 20px 10px;
  }

  .portfolioExamples {
    justify-content: center;
  }

  .portfolioExamples .exampleBox {
    width: 250px;
  }

  .recommendModalWrap .modal-dialog {
    width: 425px;
  }

  .recommendModal > img {
    width: 55px;
  }

  .advisorProfilePage .box {
    align-items: center;
    flex-direction: column;
  }

  .advisorProfilePage .box label {
    margin-bottom: 5px;
    max-width: 90px;
    color: #002642;
  }

  .breadCrumb {
    flex-wrap: wrap;
  }

  .advisorProfDesc .left {
    flex-direction: column;
    margin-bottom: 10px;
    /* display: none; */
  }

  .advisorProfDesc .left div:nth-of-type(1) {
    max-width: inherit;
    margin-right: 0;
  }

  .advisorProfTimer {
    width: 100%;
  }
  .myWorksTopFlex .box {
    padding: 25px 5px 10px;
  }
  .myWorksTopFlex .box h5,
  .myWorksTopFlex .box h6 {
    font-size: 15px;
  }
  .myWorksTopFlex .infoTooltip {
    top: 12px;
    right: 12px;
  }
  .accountSettings {
    padding: 20px 8px 10px;
  }
  .advisorEmpty {
    padding: 25px 5px 50px;
  }
  .advisorEmptyFlex .box {
    padding: 20px 10px;
    width: 100%;
    margin-bottom: 15px;
  }
  .advisorEmptyFlex {
    flex-direction: column;
  }
  .sndMsgBtnForMob {
    display: block !important;
    width: 100%;
    margin-bottom: 10px;
  }
  .sndMsgBtnForMob img {
    margin-right: 5px !important;
  }
  .sndMsgBtnForWeb {
    display: none !important;
  }
  .myWorkFlex > div {
    flex-direction: column;
    width: 100%;
  }
  .reviewContBtn {
    width: 100%;
    margin-bottom: 15px;
  }
  .applyBtnMob {
    width: 100%;
    margin: 5px 0px;
    text-align: center;
  }
  .myWorkFlex .detailBtn img {
    margin: 0 10px !important;
  }
  .myWorkBox {
    position: relative;
  }
  .myWorkBox .dropdown.advNewWorkDD {
    position: absolute;
    top: 20px;
    right: 22.67px;
  }
  .myWorkFlex {
    justify-content: flex-start;
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .newTabRight {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .userSlideTop {
    padding: 20px 5px;
    /* flex-direction: column; */
  }
  .userSlideTop .right .shortBtn {
    flex: 1 1 0%;
    font-size: 14px;
  }
  .userSlideTop .right {
    padding: 0 24px;
    /* gap: 24px; */
  }
  .popularService {
    padding: 30px 0px;
  }

  .mainTabPages .tabContentWrap {
    padding: 5px;
  }
  .discoverAdBox h5 {
    font-size: 14px;
    line-height: 15px;
  }
  .discoverAdBox h4 {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
  }
  .exampleBox .shortBtn {
    max-width: 91% !important;
    width: 94%;
    margin: 0 auto;
  }
  .paginationWrap .ngx-pagination {
    margin-bottom: 50px;
  }

  .portfolioTabs .outerTabs.nav-pills > li {
    width: 49.2% !important;
  }
  .portfolioTabs .outerTabs.nav-pills > li a {
    width: 100%;
  }
  .portfolioTabs .exampleBox {
    width: 100% !important;
  }
  .userSlideTop .left {
    padding: 0 24px;
  }
  .myWorkFlex .detailBtn {
    width: 100%;
  }
  .ongTabUploadBtn {
    display: block !important;
    line-height: 20px;
    width: 100%;
  }

  .myworksTabsFlex {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .userRightWork {
    margin: 10px 0px;
    flex-direction: column;
    row-gap: 20px;
  }
  .advisorProfDesc .btnGroup {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .advisorProfDesc .btnGroup button {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .pageCard {
    padding: 15px;
  }
  .advisorProfDesc .right,
  .advisorProfDesc .left {
    width: 100%;
  }
  button.button.heightBtn.whiteBtn {
    width: 100%;
  }
  .tooltipInputWrap .inputWrap {
    /* min-width: 100%; */
    max-width: 100%;
    min-width: auto;
    width: 100% !important;
  }
  .uploadBtnGroup .button {
    margin-right: 0px;
    width: 100%;
  }
  .stepOneNextBtn {
    width: 100%;
  }
  .uploadContTabs {
    flex-direction: column;
    align-items: flex-end;
  }
  .advisorTabs .nav-pills {
    margin-bottom: 40px;
    flex-wrap: nowrap;
    width: 100%;
    gap: 3%;
  }
  .advisorTabs .nav-pills li:nth-of-type(1) {
    margin-right: 30px;
  }
  .advisorTabs .nav-pills li a.active:before {
    bottom: -15px;
    left: 45%;
  }
  .advisorTabs .nav-pills li {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
  .uploadContTabs > div button {
    margin-bottom: 10px;
  }

  .advisorProfTabs {
    padding: 0 !important;
  }
  /* .uploadWorkFlex .discoverAdBox {
    width: 49% !important;
    margin: 0 auto 30px !important;
  } */
  .advisorFigures > div {
    position: relative;
    padding-right: 5px;
  }

  .right.upWStepTwoRight .uploadWorkSum {
    /* display: none; */
  }
  #favorite .uploadWorkSum {
    display: block !important;
  }
  div#favorite .uploadWorkSum {
    display: block !important;
  }
  .app-upload-work-summary {
    display: none;
  }
  .advisorTabs {
    margin-bottom: 30px;
  }
  .uploadWorkSum .table {
    margin-top: 49px;
  }
  .advisorProfTop .right .shortBtn {
    height: 40px;
    padding: 9px 15px;
    margin-left: 4px;
    width: 100%;
    display: block;
    margin-bottom: 16px;
  }
  .upAdBtnGroup {
    gap: 5%;
    flex-direction: column;
  }
  .upAdBtnGroup button {
    width: 100%;
    margin: 15px 0px;
  }
  .advisorProfTabs .tab-content {
    margin-bottom: 30px;
    padding: 10px;
  }

  .pricingPlan .bottom .flex {
    align-items: center;
    justify-content: space-evenly;
    gap: 2%;
    align-items: baseline;
  }
  .pricingPlan .bottom .right {
    min-width: 15% !important;
  }
  .summWorkTimeTh {
    display: none;
  }
  .tableForMob {
    display: block;
  }
  td.advisorCell {
    min-width: 115px;
  }
  .tableForMob .transBtnTh {
    min-width: 150px;
    text-align: end !important;
  }
  .bgForSumtable {
    background: #f0f0f0 !important;
  }
  .uploadWorkSum .table td {
    font-size: 14px;
  }
  tr.advDetailRow.countTh {
    max-width: 30px;
  }
  .wrapper {
    /* margin: 0 113px; */
    padding: 0 10px;
  }
  .tableForMob td,
  .tableForMob th {
    text-align: center;
    vertical-align: middle;
  }
  table.table.table-hover.tableForMob td,
  table.table.table-hover.tableForMob th {
    text-align: center;
    font-weight: 400;
  }
  th.nmbrPagesTh {
    font-size: 12px;
    font-weight: 600;
  }
  th.nmbrPagesTh span {
    font-size: 8px;
    font-weight: 400;
  }
  .coinTh {
    font-weight: 600 !important;
  }
  .coinTh span {
    font-weight: 400;
    font-size: 12px;
  }
  .reportBtn {
    display: none;
  }
  .btnGroup .reportBtn {
    display: block !important;
  }
  .advisorProfTop {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .disAdWrap > .greenBtn {
    width: 80%;
    margin: 15px auto !important;
    display: block;
  }
  .pricePkgBtnGroup {
    flex-direction: column;
    width: 98%;
    align-items: center;
  }
  .pricePkgBtnGroup button {
    margin: 0 auto 10px !important;
    width: 98% !important;
  }

  .userRightWork {
    margin: 10px 0px;
    flex-direction: column;
    row-gap: 20px;
  }
  .advisorProfDesc .btnGroup {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .advisorProfDesc .btnGroup button {
    width: 47% !important;
    margin-bottom: 20px;
  }
  .advisorProfDesc .btnGroup .reportBtn {
    width: 100% !important;
  }
  .pageCard {
    padding: 15px;
  }
  .advisorProfDesc .right,
  .advisorProfDesc .left {
    width: 100%;
  }

  button.button.heightBtn.whiteBtn {
    width: 100%;
  }
  .modalWrap button.button.heightBtn.whiteBtn {
    width: 70%;
  }
  .tooltipInputWrap .inputWrap {
    /* min-width: 100%; */
    max-width: 100%;
    min-width: auto;
    width: 100% !important;
  }
  .uploadBtnGroup .button {
    margin-right: 0px;
    width: 100%;
  }
  button.button.heightBtn.greenBtn.goCheckBtn {
    width: 30%;
    min-width: 165px;
  }
  .inputWrap.uploadBtnGroup {
    margin-bottom: 10px;
  }
  .advisorTabs .nav-pills li a {
    font-size: 12px;
  }

  .stepOneNextBtn {
    width: 100%;
  }
  .uploadContTabs {
    flex-direction: column;
    align-items: flex-end;
  }
  .advisorTabs .nav-pills {
    margin-bottom: 17px !important;
    flex-wrap: nowrap;
    width: 50%;
    gap: 0%;
  }
  .advisorTabs .nav-pills li:nth-of-type(1) {
    margin-right: 30px;
  }
  .advisorTabs .nav-pills li a.active:before {
    bottom: -15px;
    left: 45%;
  }
  .advisorTabs .nav-pills li {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
  h6.addedAdvText {
    min-width: 125px;
  }
  .uploadWorkFlex.step2 .uploadContTabs > div {
    justify-content: space-between;
  }
  .uploadContTabs > div button {
    margin-bottom: 10px;
  }
  /* .uploadWorkFlex .discoverAdBox {
    width: 49% !important;
    margin: 0 auto 30px !important;
  } */
  .advisorFigures > div {
    position: relative;
    padding-right: 5px;
  }

  .right.upWStepTwoRight .uploadWorkSum {
    /* display: none; */
  }
  #favorite .uploadWorkSum {
    display: block !important;
  }
  div#favorite .uploadWorkSum {
    display: block !important;
  }
  .app-upload-work-summary {
    display: none;
  }
  .advisorTabs {
    margin-bottom: 30px;
  }
  .uploadWorkSum .table {
    margin-top: 49px;
  }
  .advisorProfTop .right .shortBtn {
    height: 40px;
    padding: 9px 15px;
    margin-left: 0px !important;
    width: 100%;
    display: block;
  }
  .advisorProfTop .right .shortBtn img {
    transform: scale(0.8);
  }
  .upAdBtnGroup {
    gap: 1rem !important;
    flex-direction: column-reverse;
  }

  .upAdBtnGroup button {
    width: 100%;
    margin: 0 !important;
  }
  .advisorProfTabs .tab-content {
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 8px;
  }
  .pricingPlan .bottom .flex {
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  .pricingPlan label.yearLabel {
    margin: 0px 2px 0 0;
    float: right;
  }
  .pricingPlan .bottom .right {
    min-width: 15% !important;
  }
  .summWorkTimeTh {
    display: none;
  }
  .tableForMob {
    display: block;
    margin-top: 10px !important;
  }
  .tableForMob th.advisorCell {
    /* width: 254px; */
    display: flex;
    align-items: center;
    column-gap: 1px;
    /* width:118px; */
    min-width: 130px;
    word-break: break-all;
  }
  .tableForMob .advisorCell img {
    margin-right: 5px;
    min-width: 33px;
    height: 33px;
  }
  .tableForMob th {
    width: 25%;
  }
  td.advisorCell {
    min-width: 115px;
  }
  .tableForMob .transBtnTh {
    /* min-width:150px; */
    min-width: 70px;
    text-align: end !important;
  }
  .bgForSumtable {
    background: #f0f0f0 !important;
  }
  .uploadWorkSum .table td {
    font-size: 14px;
  }
  tr.advDetailRow.countTh {
    max-width: 30px;
  }
  .wrapper {
    /* margin: 0 113px; */
    padding: 0 10px;
  }
  .tableForMob td,
  .tableForMob th {
    text-align: center;
    vertical-align: middle;
  }
  table.table.table-hover.tableForMob td,
  table.table.table-hover.tableForMob th {
    text-align: center;
    font-weight: 400;
  }
  th.nmbrPagesTh {
    font-size: 12px;
    font-weight: 600;
  }
  th.nmbrPagesTh span {
    font-size: 8px;
    font-weight: 400;
  }
  .coinTh {
    font-weight: 600 !important;
  }
  .coinTh span {
    font-weight: 400;
    font-size: 12px;
  }
  .reportBtn {
    display: none;
  }
  .btnGroup .reportBtn {
    display: block !important;
    width: 100% !important;
  }
  .advisorProfTop {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .disAdWrap > .greenBtn {
    width: 80%;
    margin: 15px auto !important;
    display: block;
  }
  .pricePkgBtnGroup {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
  .pricePkgBtnGroup button {
    margin: 0 auto 10px !important;
    width: 100% !important;
  }
  .uploadAdProTop.advisorProfTop .right {
    margin: 10px 0 15px auto;
    gap: 8px;
  }
  .uploadAdProTop.advisorProfTop .right .button {
    flex: 1 1 0%;
  }
  .bannerCont .greenBtn {
    margin-right: 20px;
    width: 100%;
    margin-bottom: 5px;
  }
  .notLoggedModal .btn-group {
    margin-bottom: 30px;
    flex-direction: column;
    row-gap: 10px;
  }
  .myworksTabsFlex .right {
    width: 100%;
    text-align: center;
  }
  .accountSettings .outerTabs.nav-pills {
    flex-wrap: wrap;
    width: 100%;
  }
  .accountSettings .outerTabs.nav-pills > li {
    min-width: 32.3% !important;
    flex: 1 1 0%;
  }
  .accountSettings .outerTabs.nav-pills > li a {
    font-size: 13px;
    width: 100%;
    margin-bottom: 5px;
  }
  .addFavText {
    display: none;
  }
  .advtextForMob {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }
  .tableforWeb {
    display: none;
  }
  .card_form_input label {
    min-width: 100px;
  }
  .homeAdvisorWrap .discoverAdBox {
    min-width: 48%;
  }
  .homeAdvisorWrap .seeAllBtn {
    display: none;
  }
  button.button.transBtn.seeAllBtn.seeAllBtnMob {
    margin: 25px auto !important;
    display: block !important;
  }
  .tabContentBox button {
    width: 100%;
  }
  .saAdvMobBtn {
    display: block !important;
    margin: 20px auto !important;
  }
  .oproviAdvisorBox .rating {
    justify-content: flex-start;
    margin-top: auto;
  }
  .modalWrap.termsModal.notLoggedModal {
    text-align: center;
    padding-top: 10px;
  }
  .modalWrap.termsModal.notLoggedModal button {
    margin: 0 auto;
  }
  i#togglePassword {
    top: 15px;
  }
  .customRadio input[type="radio"]:after {
    content: "";
    background-size: 13px 15px;
  }
  .rModBotmBtnSec {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .rModBotmBtnSec button {
    width: 100%;
    margin: 0 !important;
  }
  .coinsSec > img {
    width: 100%;
    margin: 0;
    min-width: 360px;
  }
  .coinsSec h1 {
    margin-bottom: 20px;
  }
  .myWorksTopFlex {
    row-gap: 20px;
  }
  .findAnsBox .vector2 {
    right: 0px;
    width: 80px;
  }
  .findAnsBox {
    width: 320px;
  }
  .helpFlex {
    padding: 0 15px;
  }
  .helpBox {
    width: 100%;
    margin-bottom: 10px;
  }
  .helpModBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
  .helpModBottom button {
    width: 100%;
    margin: 0 !important;
  }
  .getStartWrap {
    padding: 0 10px 20px;
  }
  .getStartWrap .card .btn {
    font-size: 14px;
    padding: 10px 30px 10px 10px;
    text-align: start;
  }
  .getStartWrap .card .cardBody div {
    padding: 0 10px 20px;
  }
  .coinsTabs li a {
    font-size: 13px;

    margin-bottom: 5px;
  }
  .coinsTabs {
    margin-bottom: 15px;
    /* overflow-x: scroll; */
    width: 100%;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  .coinsTabs li {
    margin-bottom: 0px;
    display: inline-block;
    /* margin-right: 8px; */
    /* min-width: 95px; */
  }
  .headFlex .right .quickFeed {
    margin-right: 5px;
    margin-right: 7px !important;
    padding: 5px 8px !important;
    column-gap: 10px;
  }
  .myWorkSlider .carousel-container {
    display: flex;
  }
  .myWorksInfo > div:nth-of-type(1) {
    flex-direction: column;
    row-gap: 20px;
  }
  .pricingPlan .flex .left {
    width: 45%;
  }

  .pricingPlan .bottom .right {
    width: 50% !important;
    flex: 0;
    min-width: 115px !important;
  }
  .smallBanner .content {
    padding: 0 40px;
  }
  .smallBanner .content p {
    display: none;
  }
  .tabContentWrap {
    padding: 20px 10px;
  }
  .coinsTabs {
    /* overflow-x: scroll; */
  }
  .advisorProfTop .left .info .rating {
    /* display:block; */
  }
  .advisorProfTop .left {
    margin: 10px 0px;
  }
  .advisorProfDesc {
    flex-direction: column-reverse;
  }
  .advisorProfDesc .left p {
    margin-bottom: 0;
  }
  .exposureFlex {
    margin: 0;
    flex-direction: column;
  }
  .expoCard {
    width: 100%;
    margin: 0 0 20px;
    max-width: 100%;
  }
  .reduceFeedBackCard {
    display: none;
  }

  .increaseCard {
    width: 100%;
    margin: 0 0 20px;
  }
  .exampleLiked {
    padding: 2px 5px;
    flex-direction: column;
    align-items: baseline;
    padding: 15px;
    row-gap: 10px;
  }
  .exampleLiked p {
    display: block;
  }
  .oproviAdvisorBox {
    display: flex;
    flex-direction: column;
  }
  .accountSettings .tabContentWrap .filterWrap {
    display: none;
  }
  .accountSettings .outerTabs.nav-pills {
    /* flex-direction: row-reverse; */
  }
  .quickFeed a {
    margin-left: 10px;
  }
  .infoTooltip .tooltip-inner {
    right: 5px !important;
    width: 50%;
    padding: 8px;
  }
  .headFlex {
    padding: 10px 0;
  }
  .moreInfoText {
    display: flex;
    justify-content: center;
    margin: 30px 0 5px;
    font-size: 18px;
    color: #15a3ac;
    font-weight: 600;
    padding: 11px 0;
  }
  .moreLessText {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    font-size: 18px;
    color: #15a3ac;
    font-weight: 600;
  }
  .advisorProfilePage .pageCard {
    padding-bottom: 5px;
    margin-bottom: 40px;
    box-shadow: 0px 10px 20px rgba(37, 37, 92, 0.05);
    border-radius: 20px;
  }
  .advisorProfilePage .pageCard .wrapper-about {
    padding: 20px 12px !important;
  }
  .advisorProfDescription {
    margin-bottom: 30px;
  }
  .chatFlex .msgWrap {
    width: 100%;
  }
  .accountSettings.portfolioTabs .exampleBox {
    width: 47%;
  }
  .convoBottom .greenBtn {
    overflow: hidden;
    z-index: 9999999;
    position: relative;
    padding: 4px 6px;
    max-height: 35px;
    margin-left: 20px;
  }
}
@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 19px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  ul {
    padding-left: 22px;
  }

  .form-control,
  .form-control-select {
    height: 40px;
  }

  .large-wrapper {
    margin: 0;
  }

  .logo img {
    width: 70px;
  }

  .modal-dialog {
    max-width: 290px;
    width: 290px;
    margin: 20px auto;
  }

  .form-control,
  .form-control-select {
    padding: 12px 10px;
  }

  .form-control.icon-from-control {
    padding-left: 40px;
  }

  .bannerCont {
    padding: 15px;
    text-align: center;
  }

  .mobileMenu button img {
    width: 25px;
  }

  .signHeader {
    margin: 20px 0 50px;
  }

  .inputWrap .icon {
    left: 15px;
    width: 17px;
  }

  .signWrapper .left {
    padding: 20px 0;
  }

  .noAccLog .button {
    width: 100%;
    margin: 0 0 10px;
  }

  .noAccLog {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .forgetCard {
    box-shadow: none;
    padding: 40px 0;
  }

  .forgetCard .signBox p {
    text-align: center;
  }

  .forgetCard .button {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .forgetWrapper .signupHeader {
    display: flex;
    padding: 20px 0;
  }

  .forgetHeader {
    display: none;
  }

  .forgetCard .leftVector,
  .forgetCard .rightVector {
    display: none;
  }

  .forgetWrapper {
    background: #fff;
  }

  .forgetCard .signBox .transBtn {
    margin: 20px auto 0;
  }

  .forgetCard .signBox h2,
  .forgetCard .signBox h3 {
    text-align: center;
  }

  .forgetCard label {
    text-align: left;
  }

  .forgetSucImg {
    margin: 0 auto 20px;
    display: block;
  }

  .forgetCard .greenBtn {
    width: 100%;
  }

  .termsModal .btn-group {
    flex-wrap: wrap;
  }

  .termsModal .btn-group .button {
    width: 100% !important;
  }

  .termsModal .btn-group .whiteBtn {
    order: 2;
  }

  .termsModal .btn-group .greenBtn {
    order: 1;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .preciousModalWrap .modal-dialog {
    width: 290px;
  }

  .preciousModalWrap.signupModalWrap .modal-content {
    padding: 30px 15px 95px 15px;
  }

  .preciousModalWrap.signupModalWrap p {
    font-size: 14px;
    line-height: 19px;
  }

  .preciousModal .button {
    width: 100%;
  }

  .preciousModalWrap.signupModalWrap.registerModalWrap .modal-content {
    padding: 30px 15px 45px 15px;
  }

  .finishWrap .button {
    width: 100%;
  }

  .finishWrap img {
    width: 85px;
  }

  .languageWrap {
    flex-wrap: wrap;
  }

  .languageWrap .box {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .languageWrap .box label {
    width: 100%;
  }

  .pricePkgBtnGroup .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .pricePkgCard .accordBtn a p span {
    display: none;
  }

  .pricePkgCard .accordBtn .infoTooltip {
    display: none;
  }

  .standardCard.pricePkgCard,
  .pricePkgCard .accordBtn {
    padding: 15px;
  }

  .fooUploadWrap p {
    font-size: 14px;
  }

  .fooUploadWrap .btn {
    font-size: 15px;
  }

  .fooUploadWrap {
    padding: 10px;
  }

  .footerFlex {
    flex-direction: column;
  }

  .footerFlex .left {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .fooUploadWrap > img {
    margin: 0 auto 5px;
  }

  .footerFlex .middle {
    width: 100%;
    padding: 0 15px;
  }

  .footerBottom p {
    order: 2;
  }

  .footerBottom ul {
    order: 1;
    border-bottom: 1px solid #b1b0b0;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .footerBottom ul li {
    width: 100%;
    margin-left: 0;
    text-align: center;
    margin-bottom: 8px;
  }

  .workBox {
    width: 290px;
  }

  .howItWorkSec .workBox:nth-of-type(2) {
    padding-left: 0;
  }

  .workBox img.icon {
    margin: 0 10px;
  }

  .homeBanner > img {
    min-height: 500px;
  }

  .bannerCont .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .exampleFilters .button {
    margin: 0 5px 5px;
    padding: 10px 10px;
    height: 37px;
  }

  .exampleSec .innerWrapper > p {
    margin-bottom: 20px;
  }

  .advisorSec > img {
    display: none;
  }

  .advisorSec {
    padding: 40px 0;
  }

  .oproviAdvisorSec {
    padding: 35px 0 40px;
  }

  .oproviAdvisorBox {
    width: 220px;
    margin: 0 5px;
    padding: 20px 10px;
    align-self: stretch;
  }

  .oproviAdvisorSec h2 {
    margin-bottom: 20px;
  }

  .getCoinsWrap {
    background: url("./assets/get-coins-mobile.svg") no-repeat;
    background-position: bottom;
    background-size: contain;
    background-color: #fff;
    min-height: 335px;
  }

  .getCoinsWrap .content {
    top: auto;
    transform: translate(-50%, 0);
    bottom: 25px;
  }

  .getCoinsWrap .content p {
    margin-bottom: 15px;
  }

  .getCoinsWrap .button {
    width: 90%;
  }

  .feedbackSection > img {
    height: 430px;
  }

  .homeAdvisorBanner.homeBanner .bannerContWrap {
    width: 290px;
  }

  .advWrap .box {
    width: 100%;
  }

  .howItWorkSec > img {
    width: 75px;
    right: 15px;
  }

  .homeBanner.aboutBanner > img {
    min-height: 620px;
  }

  .uploadFileWrap {
    flex-direction: column;
  }

  .uploadFileWrap > div:nth-of-type(1) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .uploadFileWrap {
    flex-direction: column;
  }

  .uploadFileWrap > div:nth-of-type(1) {
    width: 100%;
    height: 40px;
  }

  .inputFileBtn {
    width: 100%;
    height: 35px;
  }

  .inputFileBtn .button {
    height: 35px;
    width: 100%;
  }

  .submitResumeBottom {
    flex-direction: column;
  }

  .submitResumeBottom > div {
    margin-bottom: 10px;
  }

  .submitResumeBottom .button {
    width: 100%;
  }

  .mainTabPages .tabContentWrap {
    padding: 10px 5px;
  }

  /* .discoverAdFlexWrap .dicoverAdFlex .discoverAdBox {
		width: 100%;
		max-width: 200px;
	} */
  .discoverAdFlexWrap .discoverAdBox .dpWrap {
    width: 40px;
    height: 40px;
    position: relative;
  }

  .dpWrap img {
    width: 40px;
    height: 40px;
  }
  .discoverAdFlexWrap .discoverAdBox .dpWrap .badgeIcon {
    width: 13px;
    height: 13px;
    right: 0px;
    top: 35px;
  }
  .discoverAdBox .dpWrap .badgeIcon {
    right: 1px;
    width: 13px;
    height: 13px;
    top: 35px;
  }
  /* .discoverAdBox .dpWrap .badgeIcon {
    
    right: 5px;
    bottom: 22px;

} */
  .discoverAdBox .dpWrap span.online {
    width: 10px;

    right: 8px;
    height: 10px;
  }
  .discoverAdFlexWrap .discoverAdBox .dpWrap span.online {
    width: 10px;
    right: 10px;
    height: 10px;
  }

  .discoverAdBoxTop p {
    font-size: 9px;
    margin: 0 !important;
    display: flex;
    column-gap: 4px;
    align-items: center;
  }
  .expLabel img {
    width: 10px;
    margin: 0 !important;
  }
  .discoverAdBox {
    padding: 20px 5px 35px;
  }
  .discoverAdFlexWrap .dicoverAdFlex,
  .disAdWrap {
    width: 100%;
    margin: 0;
  }
  .discoverAdFlexWrap .dicoverAdFlex .discoverAdBox {
    /* width: 49.2%; */
    max-width: auto;
    /* margin: 0 auto 30px; */
  }
  .uploadWorkSec .discoverAdBox {
    width: 100%;
    max-width: 200px;
  }
  input[type="checkbox"].favCheck {
    background-size: 16px 22px;
  }

  input[type="checkbox"].favCheck2 {
    background-size: 10px 11px;
  }
  .discoverAdBox h4 img {
    width: 14px;
    margin-right: 4px;
  }
  .discoverAdFlexWrap .advisorFigures > div {
    padding-right: 0px;
  }
  .discoverAdBox .shortBtn {
    width: 90%;
  }

  .searchWrap .button {
    height: 40px;
  }

  .recommendModalWrap .modal-dialog {
    width: 300px;
  }

  .advisorProfTop .right .adOffWrap {
    margin-right: 10px;
  }

  .pageCard {
    padding: 10px;
  }

  .advisorProfTop .right {
    flex-wrap: wrap;
  }

  .advisorProfTop .favTextBtn {
    margin-top: 20px;
  }

  .advisorProfilePage .box p {
    font-size: 12px;
    line-height: 16px;
    padding: 5px;
  }

  .advisorProfDesc .left {
    flex-wrap: wrap;
    /* margin-bottom: 35px; */
  }

  .advisorProfDesc .left div:nth-of-type(1) {
    max-width: 100%;
    margin-right: 0;
    /* width: 100%; */
  }

  .advisorProfDesc .right .btnGroup .button {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    height: 36px;
  }

  .advisorProfDesc .right .btnGroup .button:nth-of-type(1) {
    margin-right: 3px;
  }

  .tabTop {
    width: 100%;
    overflow-x: auto;
    display: flex;
  }

  .pricingPlan .flex {
    flex-wrap: wrap;
  }

  .pricingPlan label.yearLabel {
    margin: 0;
  }

  /* .adOffWrap .adviceTooltip {
    display: none;
  } */

  .advisorProfTop .expLabel {
    margin-right: 0;
    display: flex;
    gap: 10%;
    min-width: 80px;
    text-align: center;
    justify-content: center;
  }

  .advisorProfTop .favTextBtn {
    margin-top: 10px;
    margin-left: auto;
  }

  .myWorkFlex .detailBtn {
    margin-left: 5px;
    font-size: 13px;
  }
  .button {
    font-size: 12px;
  }
  .uploadWorkFlex.step1 .left form.ng-untouched.ng-pristine.ng-invalid {
    max-width: 100%;
    margin: 0 auto;
  }
  h3.ddBannerH3 img {
    width: 20px;
  }
  .pricingPlan .pkgLabelWrapper {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .pricingPlan h6 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 23px;
    padding-top: 15px;
  }
  .large-wrapper {
    padding: 0 10px;
  }
  .quickFeed {
    padding: 8px 2px;
  }
}
@media (max-width: 463px) {
  .dicoverAdFlex {
    justify-content: center;
  }

  .searchWrap {
    min-width: 100%;
  }

  .filterWrap {
    flex-wrap: wrap;
  }

  .filterWrap .filtersBtn {
    margin-top: 5px;
  }

  .accountSettings .outerTabs.nav-pills > li a {
    padding: 15px 13px;
  }

  /* .uploadWorkFlex .discoverAdBox {
    width: 215px;
  } */

  /* .favouriteAdvisor .discoverAdBox {
    width: 215px;
  } */

  .filterWrap > div:nth-of-type(2) {
    flex-wrap: wrap;
  }

  .filterWrap .filterDrops {
    width: 100%;
    margin-left: 0;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .filterWrap .filterDropBox {
    width: 100%;
  }

  .filterDropBox .dropdown {
    flex: 1 1 auto;
  }

  .filterDropBox .dropdown > .button {
    width: 100%;
  }

  .myWorksTopFlex {
    flex-direction: column;
  }

  .myWorksTopFlex .box {
    margin-bottom: 10px;
  }

  .myWorksTopFlex .box {
    width: 100%;
  }

  .accountSettings .outerTabs.nav-pills > li a {
    padding: 12px 10px;
    display: block;
    text-align: center;
  }

  .myworksTabsFlex .right {
    /* margin-left:auto; */
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .myWorkFlex.bottomWorkFlex {
    flex-direction: column;
  }

  .myWorkFlex .detailBtn {
    margin-left: 0;
  }
  .accountSettings .outerTabs.nav-pills {
    width: 100%;
  }

  .accountSettings .outerTabs.nav-pills > li {
    /* width: 48.3%; */
  }

  table.table.table-hover.tableForMob {
    overflow-x: auto;
  }
}

/* naeem's Css */
.a9s-annotationlayer
  .a9s-formatter-el
  foreignObject
  .a9s-shape-label-wrapper
  .a9s-shape-label {
  background-color: #15a3ac !important;
  border-radius: 53% !important;
  font-size: 14px !important;
  color: white !important;
  padding: 2px 8px !important;
  /* z-index: 99999; */
  text-align: center;
  width: 26px;
  /* height: 28px; */
  aspect-ratio: 1/1;
}

.a9s-annotationlayer .a9s-formatter-el foreignObject .a9s-shape-label-wrapper {
  position: static !important;
  transform: none !important;
  padding-bottom: none !important;
}

.a9s-annotationlayer
  .a9s-formatter-el
  foreignObject
  .a9s-shape-label-wrapper
  .a9s-shape-label {
  display: block !important;
  position: fixed;
  left: -3px;
  top: -28px;
}

@media screen and (max-width: 575px) {
  .myWorkFlex .mobileNewLabel {
    position: absolute;
    right: 20px;
    top: 24px;
  }
  .myWorkFlex .newLabel2 {
    display: none;
  }
}

@media screen and (min-width: 575px) {
  .myWorkFlex .mobileNewLabel {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .discoverAdBox .adOffWrap > p {
    margin-right: 4px !important;
  }
}

.tooltip.fade {
  background-color: transparent !important;
}

.tooltip.fade > div {
  background-color: white !important;
  backdrop-filter: blur(15px) !important;
}

/* .annotateComWrap h6 {
  padding: 20px;
  border-bottom: 1px solid #cacaca;
} */

.paypal-button-number-2 {
  background-color: #002642;
}
.location-list {
  padding-left: 20px;
  padding-top: 10px;
}
.location-list span {
  box-shadow: 0px 5px 20px 0px #08564d1a;
  padding: 10px;
  border-radius: 20px;
  color: #15a3ac;
  font-weight: 600;
  display: block;
  width: fit-content;
  margin-right: 10px;
  background: #fff;
}
.location-list span.selectedItem {
  background: #15a3ac;
  color: #fff;
}
.__mb--minus .customSlider {
  margin-bottom: 0px;
}

.ck-editor__editable {
  height: auto;
  min-height: 150px; /* Approximate height for 3 rows of text */
}
